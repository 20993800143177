import { FC, createContext, useContext } from "react";
import { GraphHttpServiceContext } from "./GraphHttpService";
import IUser from "../../Models/Graph/IUser";
import IPrincipal from "../../Models/Graph/IPrincipal";
import IList from "../../Models/Graph/IList";
import IApplication from "../../Models/Graph/IApplication";
import IGroup from "../../Models/Graph/IGroup";

export interface IPrincipalService {
  SearchPrincipal(searchTerm: string): Promise<IPrincipal[] | null>;
  SearchPrincipals(objectIds: string[]): Promise<IPrincipal[] | null>;
}

export const PrincipalServiceContext = createContext<
  IPrincipalService | undefined
>(undefined);

const PrincipalService: FC = ({ children }: any) => {
  const graphHttpService = useContext(GraphHttpServiceContext);

  const principalService: IPrincipalService = {
    async SearchPrincipals(objectIds: string[]) {
      const users = await graphHttpService!.Get<IList<IUser>>(
        `users?$filter=${objectIds.map((id) => `id eq '${id}'`).join(" or ")}`
      );
      const applications = await graphHttpService!.Get<IList<IApplication>>(
        `servicePrincipals?$filter=${objectIds
          .map((id) => `id eq '${id}'`)
          .join(" or ")}`
      );
      const groups = await graphHttpService!.Get<IList<IGroup>>(
        `groups?$filter=${objectIds.map((id) => `id eq '${id}'`).join(" or ")}`
      );

      const array: IPrincipal[] = [
        (users && [
          ...users!.value.map<IPrincipal>((user) => {
            return {
              id: user.id,
              displayName: user.displayName,
              subTitle: user.mail,
            };
          }),
        ]) ||
          [],
        (applications && [
          ...applications!.value.map<IPrincipal>((app) => {
            return {
              id: app.id,
              displayName: app.displayName,
              subTitle: app.appId,
            };
          }),
        ]) ||
          [],
        (groups && [
          ...groups!.value.map<IPrincipal>((group) => {
            return {
              id: group.id,
              displayName: group.displayName,
              subTitle: group.id,
            };
          }),
        ]) ||
          [],
      ]
        .flat()
        .filter((x) => x !== null);
      return array;
    },
    async SearchPrincipal(searchTerm: string) {
      if (!searchTerm) return [];

      const users = await graphHttpService!.Get<IList<IUser>>(
        `users?$filter=startsWith(givenName, '${searchTerm}') OR startsWith(surName, '${searchTerm}') OR startsWith(mail,'${searchTerm}') OR startsWith(displayName,'${searchTerm}')`
      );

      const applications = await graphHttpService!.Get<IList<IApplication>>(
        `servicePrincipals?$filter=startsWith(displayName,'${searchTerm}')`
      );

      const groups = await graphHttpService!.Get<IList<IGroup>>(
        `groups?$filter=startsWith(displayName,'${searchTerm}')`
      );

      return [
        ...users!.value.map((user) => {
          return {
            id: user.id,
            displayName: user.displayName,
            subTitle: user.mail,
          };
        }),
        ...applications!.value.map((app) => {
          return {
            id: app.id,
            displayName: app.displayName,
            subTitle: app.appId,
          };
        }),
        ...groups!.value.map((group) => {
          return {
            id: group.id,
            displayName: group.displayName,
            subTitle: group.id,
          };
        }),
      ];
    },
  };

  return (
    <PrincipalServiceContext.Provider value={principalService}>
      {children}
    </PrincipalServiceContext.Provider>
  );
};

export default PrincipalService;
