import { Link, Stack } from "@fluentui/react";
import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ConfigurationService from "../../../Services/ConfigurationService";
import "./Footer.scss";
import IAppCatalog from "../../../Models/API/IAppCatalog";

interface IFooterProps {
  catalog: IAppCatalog;
}

const Footer: FC<IFooterProps> = ({ catalog }) => {
  const navigation = useNavigate();
  const location = useLocation();

  const contactSupport = (): void => {
    const mail = catalog.OwnerEmail;
    const subject = `${ConfigurationService.Default.Configuration.Contact.ContactSupportSubject} - ${catalog.DisplayName}`;
    window.open(`mailto:${mail}?subject=${subject}`);
  };

  return (
    <Stack horizontal tokens={{ padding: 16 }}>
      <Stack.Item grow>
        <Link
          target="_blank"
          onClick={contactSupport}
          className="ms-fontColor-themePrimary"
        >
          {catalog.OwnerName}
        </Link>
      </Stack.Item>
      <Stack.Item align="end">
        <Link
          style={{ textDecoration: "none !important" }}
          onClick={() => navigation("/versions", { replace: true })}
          className={`ms-fontColor-themePrimary ${
            location.pathname === "/versions" ? "active" : ""
          }`}
        >
          Latest Release:{" "}
          {ConfigurationService.Default.Configuration.AppVersionDate} v
          {ConfigurationService.Default.Configuration.AppVersion}
        </Link>
      </Stack.Item>
    </Stack>
  );
};

export { Footer };
