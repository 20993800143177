import { FC, createContext, useContext } from "react";
import { ApiHttpServiceContext } from "./ApiHttpService";
import { CacheServiceContext } from "./CacheService";
import IAgentApp from "../../Models/API/IAgentApp";
import IAgentAppPermission from "../../Models/API/IAgentAppPermission";
import {
  AgentAppFunctionParameters,
  AgentAppFunctionProperty,
  IAgentAppFunction,
} from "../../Models/API/IAgentAppFunction";
import IUserPermission, {
  PermissionType,
} from "../../Models/API/IUserPermission";

export interface INewAgentApp {
  DisplayName?: string;
  Description?: string;
  SystemPrompt?: string;
  AppCatalogId?: number;
}

export interface INewAgentAppFunction {
  DisplayName?: string;
  Description?: string;
  SystemPrompt?: string;
  ReturnValue?: string;
  ActionUrl?: string;
  Parameters?: AgentAppFunctionParameters;
  AppCatalogId?: number;
}

export interface IAgentAppService {
  // CRUD
  GetAll(): Promise<IAgentApp[] | null>;
  Get(agentAppId: number): Promise<IAgentApp | null>;
  Create(agentApp: INewAgentApp): Promise<IAgentApp | null>;
  Update(agentAppId: number, agentApp: INewAgentApp): Promise<IAgentApp | null>;
  Delete(agentAppId: number): Promise<void | null>;
  PublishAgentApp(agentAppId: number, publish: boolean): Promise<void>;

  // Functions
  GetAgentFunctions(agentAppId: number): Promise<IAgentAppFunction[] | null>;
  CreateFunction(
    agentAppId: number,
    agentFunction: INewAgentAppFunction
  ): Promise<IAgentAppFunction | null>;
  UpdateFunction(
    agentAppId: number,
    agentFunction: IAgentAppFunction
  ): Promise<IAgentAppFunction | null>;
  DeleteFunction(
    agentAppId: number,
    agentAppFunctionId: number
  ): Promise<void | null>;

  // Permissions
  OverrideCatalogPermissions(
    agentAppId: number,
    overrideCatalogPermissions: boolean
  ): Promise<void>;
  CreatePermission(
    agentAppId: number,
    principalId: string,
    type: PermissionType
  ): Promise<IAgentAppPermission | null>;
  GetPermissions(agentAppId: number): Promise<IAgentAppPermission[] | null>;
  UpdatePermission(
    catalogId: number,
    principalId: string,
    type: PermissionType
  ): Promise<IAgentAppPermission | null>;
  CheckUserPermissions(
    principalId: string,
    agentAppId: number
  ): Promise<IUserPermission[] | null>;
  DeletePermission(
    catalogId: number,
    principalId: string
  ): Promise<void | null>;
}

export const agentAppServiceContext = createContext<
  IAgentAppService | undefined
>(undefined);

const AgentAppService: FC = ({ children }: any) => {
  const apiHttpService = useContext(ApiHttpServiceContext);
  const cacheService = useContext(CacheServiceContext);

  const controller: string = "agentApps";

  const agentAppService: IAgentAppService = {
    async GetAll(): Promise<IAgentApp[] | null> {
      return await apiHttpService!.Get<IAgentApp[]>(`${controller}`);
    },
    async Get(agentAppId: number) {
      return await apiHttpService!.Get<IAgentApp>(
        `${controller}/${agentAppId}`
      );
    },
    async Delete(agentAppId: number) {
      return await apiHttpService!.Delete<void>(`${controller}/${agentAppId}`);
    },
    async Create(agentApp: INewAgentApp) {
      return await apiHttpService!.Post<IAgentApp>(`${controller}`, agentApp);
    },
    async Update(agentAppId: number, agentApp: INewAgentApp) {
      cacheService!.ClearCache(`${controller}/${agentApp}`);
      return await apiHttpService!.Put<IAgentApp>(
        `${controller}/${agentAppId}`,
        agentApp
      );
    },
    async PublishAgentApp(agentAppId: number, publish: boolean) {
      await apiHttpService!.Post<void>(
        `${controller}/${agentAppId}/publish?publish=${publish}`
      );
    },
    async GetAgentFunctions(agentAppId: number) {
      const fun = await apiHttpService!.Get<IAgentAppFunction[]>(
        `${controller}/${agentAppId}/functions`
      );

      if (fun) {
        return fun?.map((x): IAgentAppFunction => {
          return {
            Id: x.Id,
            InternalName: x.InternalName,
            DisplayName: x.DisplayName,
            Description: x.Description,
            ReturnValue: x.ReturnValue,
            ActionUrl: x.ActionUrl,
            Parameters: {
              Type: x.Parameters.Type,
              Properties: new Map<string, AgentAppFunctionProperty>(
                Object.entries(x.Parameters.Properties)
              ),
              Required: x.Parameters.Required,
            },
            Created: x.Created,
            Updated: x.Updated,
          };
        });
      }

      return null;
    },
    async CreateFunction(
      agentAppId: number,
      agentFunction: INewAgentAppFunction
    ) {
      let propertiesToSave: any = {};

      if (agentFunction!.Parameters) {
        for (const [key, value] of agentFunction!.Parameters.Properties) {
          propertiesToSave[key] = value;
        }
      }

      const fun = {
        ...agentFunction,
        Parameters: {
          ...agentFunction.Parameters,
          Properties: propertiesToSave,
        },
      };

      return await apiHttpService!.Post<IAgentAppFunction>(
        `${controller}/${agentAppId}/functions`,
        fun
      );
    },
    async UpdateFunction(agentAppId: number, agentFunction: IAgentAppFunction) {
      let propertiesToSave: any = {};

      if (agentFunction!.Parameters) {
        for (const [key, value] of agentFunction!.Parameters.Properties) {
          propertiesToSave[key] = value;
        }
      }

      const fun = {
        ...agentFunction,
        Parameters: {
          ...agentFunction.Parameters,
          Properties: propertiesToSave,
        },
      };

      return await apiHttpService!.Put<IAgentAppFunction>(
        `${controller}/${agentAppId}/functions/${agentFunction.Id}`,
        fun
      );
    },
    async DeleteFunction(agentAppId: number, agentAppFunctionId: number) {
      return await apiHttpService!.Delete<void>(
        `${controller}/${agentAppId}/functions/${agentAppFunctionId}`
      );
    },
    async OverrideCatalogPermissions(
      agentAppId: number,
      overrideCatalogPermissions: boolean
    ) {
      await apiHttpService!.Post<void>(
        `${controller}/${agentAppId}/overrideCatalogPermissions?overrideCatalogPermissions=${overrideCatalogPermissions}`
      );
    },
    async CreatePermission(
      agentAppId: number,
      principalId: string,
      type: PermissionType
    ) {
      return await apiHttpService!.Post<IAgentAppPermission>(
        `${controller}/${agentAppId}/permissions`,
        {
          PrincipalId: principalId,
          PermissionType: type,
        }
      );
    },
    async GetPermissions(agentAppId: number) {
      return await apiHttpService!.Get<IAgentAppPermission[]>(
        `${controller}/${agentAppId}/permissions`
      );
    },
    async DeletePermission(agentAppId: number, principalId: string) {
      await apiHttpService!.Delete<IAgentAppPermission>(
        `${controller}/${agentAppId}/permissions/${principalId}`
      );
    },
    async CheckUserPermissions(principalId: string, agentAppId: number) {
      return await apiHttpService!.Get<IUserPermission[]>(
        `${controller}/${agentAppId}/permissions/${principalId}`
      );
    },
    async UpdatePermission(
      agentAppId: number,
      principalId: string,
      type: PermissionType
    ) {
      return await apiHttpService!.Put<IAgentAppPermission>(
        `${controller}/${agentAppId}/permissions`,
        {
          PrincipalId: principalId,
          PermissionType: type,
        }
      );
    },
  };

  return (
    <agentAppServiceContext.Provider value={agentAppService}>
      {children}
    </agentAppServiceContext.Provider>
  );
};

export default AgentAppService;
