import {
  Checkbox,
  DefaultButton,
  Dialog,
  DialogFooter,
  Dropdown,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
  TextField,
} from "@fluentui/react";
import { FC, useState } from "react";
import MetadataField from "../MetadataField/MetadataField";
import IMetadataField, {
  MetadataFieldTypes,
} from "../../../Models/API/IMetadataField";

type AddMetadataFieldProps = {
  hidden: boolean;
  onFieldAdd: (field: IMetadataField) => Promise<void>;
  onCancel: () => void;
  canAddField: (field: IMetadataField) => boolean;
};

const defaultNewField: IMetadataField = {
  DisplayName: "",
  Description: "",
  Required: false,
  Type: "String",
  DefaultValue: "",
};

const AddMetadataField: FC<AddMetadataFieldProps> = ({
  hidden,
  onFieldAdd,
  onCancel,
  canAddField,
}: AddMetadataFieldProps) => {
  const [newField, setNewField] = useState<IMetadataField>(defaultNewField);
  const [addingField, setAddingField] = useState<boolean>(false);

  const cancel = () => {
    setNewField(defaultNewField);
    onCancel();
  };

  const add = () => {
    if (!addingField) {
      setAddingField(true);
      onFieldAdd(newField).then(() => {
        setNewField(defaultNewField);
        setAddingField(false);
      });
    }
  };

  return (
    <Dialog
      hidden={hidden}
      onDismiss={cancel}
      dialogContentProps={{
        title: "New Field",
        subText: "Add a new metadata field to your library",
      }}
      modalProps={{
        isBlocking: true,
      }}
    >
      <Stack tokens={{ childrenGap: 20 }}>
        <Stack.Item>
          <Dropdown
            label="Field Type"
            selectedKey={newField.Type}
            // eslint-disable-next-line react/jsx-no-bind
            onChange={(evt, option, index) => {
              setNewField({
                ...newField,
                DefaultValue: "",
                Type: option!.key as any,
              });
            }}
            placeholder="Select an option"
            options={MetadataFieldTypes}
          />
        </Stack.Item>
        <Stack.Item>
          <TextField
            required={true}
            label="Display Name"
            value={newField.DisplayName}
            onChange={(event, newValue) =>
              setNewField({
                ...newField,
                DisplayName: newValue!,
              })
            }
          ></TextField>
          {!canAddField(newField) && (
            <Text style={{ marginTop: 5 }} variant={"xSmall"} block>
              The field name is already in use
            </Text>
          )}
          <Text variant="xSmall">Minimum 3 characters</Text>
        </Stack.Item>
        <Stack.Item>
          <TextField
            label="Description"
            multiline
            rows={3}
            resizable={false}
            value={newField.Description}
            onChange={(event, newValue) =>
              setNewField({
                ...newField,
                Description: newValue!,
              })
            }
          />
        </Stack.Item>
        <Stack.Item>
          <MetadataField
            label="Default Value"
            value={newField.DefaultValue}
            setValue={(value) => {
              setNewField({
                ...newField!,
                DefaultValue: value!,
              });
            }}
            field={newField!}
          ></MetadataField>
        </Stack.Item>
        <Stack.Item>
          <Checkbox
            label="Required"
            checked={newField.Required}
            onChange={(event, checked) =>
              setNewField({
                ...newField,
                Required: checked!,
              })
            }
          />
        </Stack.Item>
      </Stack>
      <DialogFooter>
        <PrimaryButton
          onClick={add}
          text={addingField ? "" : "Add Field"}
          disabled={
            !(
              newField.DisplayName !== "" &&
              newField.DisplayName.length >= 3 &&
              canAddField(newField)
            )
          }
        >
          {addingField && <Spinner size={SpinnerSize.small} />}
        </PrimaryButton>
        <DefaultButton onClick={cancel} text="Cancel" />
      </DialogFooter>
    </Dialog>
  );
};

export default AddMetadataField;
