import { FC, createContext } from "react";

export type ToastMessageType = "info" | "success" | "error";

export interface IToastMessage {
  message: string;
  type: ToastMessageType;
}

export interface IToastService {
  AddMessage(message: IToastMessage): void;
  AddSubscriber(delegate: Function): void;
  RemoveSubscriber(delegate: Function): void;
}

export const ToastServiceContext = createContext<IToastService | undefined>(
  undefined
);

const ToastService: FC = ({ children }: any) => {
  let delegates: Array<Function> = new Array<Function>();

  const notifySubscribers = (message: IToastMessage): void => {
    delegates.forEach((d) => d(message));
  };

  const toastService: IToastService = {
    AddMessage(message: IToastMessage): void {
      notifySubscribers(message);
    },
    AddSubscriber(delegate: Function): void {
      delegates.push(delegate);
    },
    RemoveSubscriber(delegate: Function): void {
      delegates = delegates.filter((d) => d !== delegate);
    },
  };

  return (
    <ToastServiceContext.Provider value={toastService}>
      {children}
    </ToastServiceContext.Provider>
  );
};

export default ToastService;
