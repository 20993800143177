import { FC, createContext, useContext } from "react";
import AuthenticationService from "../AuthenticationService";
import { AxiosProgressEvent, CancelToken } from "axios";
import { HttpServiceContext } from "../API/HttpService";
import { CacheServiceContext } from "../API/CacheService";

const GRAPH_URI = "https://graph.microsoft.com";
const GRAPH_VERSION = "v1.0";

export interface IGraphHttpService {
  Get<T>(
    path: string,
    headers?: any | undefined,
    useCache?: boolean
  ): Promise<T | null>;
  Download<T>(
    path: string,
    headers?: any | undefined,
    useCache?: boolean
  ): Promise<T | null>;
  Post<T>(
    path: string,
    data?: any | undefined,
    headers?: any | undefined,
    cancelToken?: CancelToken | undefined,
    onUploadProgress?:
      | ((progressEvent: AxiosProgressEvent) => void)
      | undefined,
    throwError?: boolean
  ): Promise<T | null>;
  Put<T>(
    path: string,
    data?: any | undefined,
    headers?: any | undefined
  ): Promise<T | null>;
  Delete<T>(path: string, headers?: any | undefined): Promise<T | null>;
}

export const GraphHttpServiceContext = createContext<
  IGraphHttpService | undefined
>(undefined);

const GraphHttpService: FC = ({ children }: any) => {
  const httpService = useContext(HttpServiceContext);
  const cacheService = useContext(CacheServiceContext);

  const graphHttpService: IGraphHttpService = {
    async Get<T>(
      path: string,
      headers?: any | undefined,
      useCache: boolean = false
    ) {
      if (headers === undefined) {
        headers = {};
      }
      headers[
        "Authorization"
      ] = `Bearer ${await AuthenticationService.Default.AccessToken()}`;
      if (useCache) {
        return await cacheService!.GetCache<T>(path, async () => {
          try {
            return await httpService!.Get<T>(
              `${GRAPH_URI}/${GRAPH_VERSION}/${path}`,
              headers,
              true
            );
          } catch {
            return null;
          }
        });
      } else {
        try {
          return await httpService!.Get<T>(
            `${GRAPH_URI}/${GRAPH_VERSION}/${path}`,
            headers,
            true
          );
        } catch {
          return null;
        }
      }
    },
    async Download<T>(
      path: string,
      headers?: any | undefined,
      useCache: boolean = false
    ) {
      if (headers === undefined) {
        headers = {};
      }
      headers[
        "Authorization"
      ] = `Bearer ${await AuthenticationService.Default.AccessToken()}`;
      if (useCache) {
        return await cacheService!.GetCache<T>(path, async () => {
          return await httpService!.Download<T>(
            `${GRAPH_URI}/${GRAPH_VERSION}/${path}`,
            headers
          );
        });
      } else {
        return await httpService!.Download<T>(
          `${GRAPH_URI}/${GRAPH_VERSION}/${path}`,
          headers
        );
      }
    },
    async Post<T>(
      path: string,
      data?: any | undefined,
      headers?: any | undefined,
      cancelToken?: CancelToken | undefined,
      onUploadProgress:
        | ((progressEvent: AxiosProgressEvent) => void)
        | undefined = undefined,
      throwError: boolean = false
    ) {
      if (headers === undefined) {
        headers = {};
      }
      headers[
        "Authorization"
      ] = `Bearer ${await AuthenticationService.Default.AccessToken()}`;
      return await httpService!.Post<T>(
        `${GRAPH_URI}/${GRAPH_VERSION}/${path}`,
        data,
        headers,
        cancelToken,
        onUploadProgress,
        throwError
      );
    },
    async Put<T>(
      path: string,
      data?: any | undefined,
      headers?: any | undefined
    ) {
      if (headers === undefined) {
        headers = {};
      }
      headers[
        "Authorization"
      ] = `Bearer ${await AuthenticationService.Default.AccessToken()}`;
      return await httpService!.Put<T>(
        `${GRAPH_URI}/${GRAPH_VERSION}/${path}`,
        data,
        headers
      );
    },
    async Delete<T>(path: string, headers?: any | undefined) {
      if (headers === undefined) {
        headers = {};
      }
      headers[
        "Authorization"
      ] = `Bearer ${await AuthenticationService.Default.AccessToken()}`;
      return await httpService!.Delete<T>(
        `${GRAPH_URI}/${GRAPH_VERSION}/${path}`,
        headers
      );
    },
  };

  return (
    <GraphHttpServiceContext.Provider value={graphHttpService}>
      {children}
    </GraphHttpServiceContext.Provider>
  );
};

export default GraphHttpService;
