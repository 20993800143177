import { FC } from "react";
import ApiHttpService from "../../Services/API/ApiHttpService";
import CacheService from "../../Services/API/CacheService";
import ChatService from "../../Services/API/ChatService";
import ConversationSettingsService from "../../Services/API/ConversationSettingsService";
import DocumentService from "../../Services/API/DocumentService";
import HttpService from "../../Services/API/HttpService";
import LibraryService from "../../Services/API/LibraryService";
import SettingsService from "../../Services/API/SettingsService";
import ToastService from "../../Services/ToastService";
import GraphHttpService from "../../Services/MSGraph/GraphHttpService";
import PrincipalService from "../../Services/MSGraph/PrincipalService";
import CatalogService from "../../Services/API/CatalogService";
import ChatAppService from "../../Services/API/ChatAppService";
import AppCatalogService from "../../Services/AppCatalogService";
import AgentAppService from "../../Services/API/AgentAppService";
import AutomateAppService from "../../Services/API/AutomateAppService";
import UserService from "../../Services/API/UserService";

const Services: FC = ({ children }) => {
  return (
    <>
      <ToastService>
        <CacheService>
          <HttpService>
            <ApiHttpService>
              <GraphHttpService>
                <SettingsService>
                  <ChatService>
                    <LibraryService>
                      <CatalogService>
                        <ChatAppService>
                          <AgentAppService>
                            <AutomateAppService>
                              <DocumentService>
                                <PrincipalService>
                                  <ConversationSettingsService>
                                    <AppCatalogService>
                                      <UserService>{children}</UserService>
                                    </AppCatalogService>
                                  </ConversationSettingsService>
                                </PrincipalService>
                              </DocumentService>
                            </AutomateAppService>
                          </AgentAppService>
                        </ChatAppService>
                      </CatalogService>
                    </LibraryService>
                  </ChatService>
                </SettingsService>
              </GraphHttpService>
            </ApiHttpService>
          </HttpService>
        </CacheService>
      </ToastService>
    </>
  );
};

export default Services;
