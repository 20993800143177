import { FC, useContext, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import IAgentApp from "../../../../../../Models/API/IAgentApp";
import { agentAppServiceContext } from "../../../../../../Services/API/AgentAppService";
import PermissionManagement from "../../../../../../Components/PermissionManagement/PermissionManagement";
import IPermission from "../../../../../../Models/API/IPermission";
import { trackPageView } from "../../../../../../Services/AppInsights";
import ConfigurationService from "../../../../../../Services/ConfigurationService";

const AgentAppPermissions: FC = () => {
  const { agentApp } = useOutletContext<{
    agentApp: IAgentApp;
  }>();
  const agentAppsService = useContext(agentAppServiceContext);

  // Track page view
  useEffect(() => {
    document.title = `${ConfigurationService.Default.Configuration.PageTitle} - ${agentApp.DisplayName} Permissions`;
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _addPermission = async (objectId: string, permissionType: string) => {
    return await agentAppsService!.CreatePermission(
      agentApp.Id,
      objectId,
      permissionType as any
    );
  };

  const _deletePermission = async (objectId: string) => {
    await agentAppsService!.DeletePermission(agentApp.Id, objectId);
  };

  const _reloadPermissions = async () => {
    var perms = await agentAppsService!.GetPermissions(agentApp.Id);
    return perms as IPermission[];
  };

  const _checkPermissions = async (objectId: string) => {
    var perms = await agentAppsService!.CheckUserPermissions(
      objectId,
      agentApp.Id
    );
    return perms ?? [];
  };

  return (
    <PermissionManagement
      resourceType={"AgentApp"}
      resourceId={agentApp.Id}
      permissions={{
        Read: {
          DisplayName: "Read",
          Description: "Users can interact with the application",
        },
        Write: {
          DisplayName: "Write",
          Description:
            "Users can edit the application settings and manage functions",
        },
        FullControl: {
          DisplayName: "Full Control",
          Description: "Users can change the permissions of the application",
        },
        Owner: {
          DisplayName: "Owner",
          Description: "Users can add other owners and delete the application",
        },
      }}
      isOwner={agentApp.PermissionType === "Owner"}
      addPermission={_addPermission}
      deletePermission={_deletePermission}
      reloadPermissions={_reloadPermissions}
      checkPermissions={_checkPermissions}
    />
  );
};

export default AgentAppPermissions;
