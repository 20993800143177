import {
  IBasePickerSuggestionsProps,
  IPersonaProps,
  ISuggestionItemProps,
  NormalPeoplePicker,
  PeoplePickerItemSuggestion,
  ValidationState,
} from "@fluentui/react";
import React, { FC, useContext, useEffect, useState } from "react";
import { PrincipalServiceContext } from "../../Services/MSGraph/PrincipalService";

const suggestionProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: "Suggested Principals",
  mostRecentlyUsedHeaderText: "Suggested Contacts",
  noResultsFoundText: "No results found",
  loadingText: "Loading",
  showRemoveButtons: false,
  suggestionsAvailableAlertText: "People Picker Suggestions available",
  suggestionsContainerAriaLabel: "Suggested contacts",
};

function getTextFromItem(persona: IPersonaProps): string {
  return persona.text as string;
}

const validateInput = (input: string): ValidationState => {
  if (input.indexOf("@") !== -1) {
    return ValidationState.valid;
  } else if (input.length > 1) {
    return ValidationState.warning;
  } else {
    return ValidationState.invalid;
  }
};

export interface IPeoplePicker {
  onChange: (items?: IPersonaProps[]) => void;
  excludeIds?: string[];
  initialIds?: string[] | undefined;
  singleChoice?: boolean;
}

const PeoplePicker: FC<IPeoplePicker> = ({
  onChange,
  initialIds,
  excludeIds,
  singleChoice,
}: IPeoplePicker) => {
  const principalService = useContext(PrincipalServiceContext);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const picker = React.useRef(null);
  const [selected, setSelected] = useState<IPersonaProps[]>([]);

  useEffect(() => {
    const execute = async () => {
      if (initialIds && initialIds.length > 0) {
        var principals = await principalService?.SearchPrincipals(initialIds);
        if (principals && principals.length > 0) {
          setSelected(
            principals!.map((g) => {
              return {
                text: g.displayName,
                id: g.id,
                secondaryText: g.subTitle,
              };
            })
          );
        } else {
          setSelected([]);
        }
      }
    };
    execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterPromise = (
    personasToReturn: IPersonaProps[]
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    return convertResultsToPromise(personasToReturn);
  };

  const convertResultsToPromise = (
    results: IPersonaProps[]
  ): Promise<IPersonaProps[]> => {
    return new Promise<IPersonaProps[]>(async (resolve, reject) => {
      const users = await principalService!.SearchPrincipal(searchTerm);
      if (users) {
        resolve(
          users!
            .filter(
              (u) => excludeIds === undefined || excludeIds.indexOf(u.id) === -1
            ) // Exclude ids from props
            .filter((u) => selected?.map((s) => s.id).indexOf(u.id) === -1) // Exclude ids already selected
            .map((u) => {
              const prop: IPersonaProps = {
                text: u.displayName,
                id: u.id,
                secondaryText: u.subTitle,
              };
              return prop;
            })
        );
      } else {
        resolve([]);
      }
    });
  };

  const onInputChange = (input: string): string => {
    setSearchTerm(input);
    return input;
  };

  const onFilterChanged = async (
    filterText: string,
    currentPersonas: IPersonaProps[] | undefined,
    limitResults?: number
  ): Promise<IPersonaProps[]> => {
    return filterPromise(currentPersonas!);
  };

  const onRenderSuggestionsItem = (
    personaProps: IPersonaProps,
    suggestionsProps: ISuggestionItemProps<IPersonaProps>
  ) => (
    <PeoplePickerItemSuggestion
      personaProps={personaProps}
      suggestionsProps={suggestionsProps}
      styles={{ personaWrapper: { width: "100%" } }}
    />
  );

  const pickerChanged = (items?: IPersonaProps[]): void => {
    setSelected(items!);
    onChange(items);
  };

  return (
    <>
      <NormalPeoplePicker
        onChange={pickerChanged}
        onRenderSuggestionsItem={onRenderSuggestionsItem}
        onResolveSuggestions={onFilterChanged}
        getTextFromItem={getTextFromItem}
        pickerSuggestionsProps={suggestionProps}
        pickerCalloutProps={{
          calloutWidth: 300,
        }}
        itemLimit={singleChoice ? 1 : undefined}
        selectedItems={selected}
        className={"ms-PeoplePicker"}
        key={"normal"}
        onValidateInput={validateInput}
        selectionAriaLabel={"Selected contacts"}
        removeButtonAriaLabel={"Remove"}
        inputProps={{
          onBlur: (ev: React.FocusEvent<HTMLInputElement>) => {},
          onFocus: (ev: React.FocusEvent<HTMLInputElement>) => {},
          "aria-label": "People Picker",
        }}
        componentRef={picker}
        onInputChange={onInputChange}
        resolveDelay={300}
      />
    </>
  );
};

export default PeoplePicker;
