import {
  PrimaryButton,
  Slider,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
} from "@fluentui/react";
import { FC, useContext, useEffect, useState } from "react";
import { SettingsServiceContext } from "../../../Services/API/SettingsService";
import ISettings from "../../../Models/API/ISettings";
import { DialogServiceContext } from "../../../Services/Dialogs/DialogService";
import { trackPageView } from "../../../Services/AppInsights";

const TrainingSettings: FC = () => {
  const settingsService = useContext(SettingsServiceContext);
  const dialogService = useContext(DialogServiceContext);
  const [settings, setSettings] = useState<ISettings | null>(null);
  const [saving, setSaving] = useState<boolean>(false);
  const [isMounted, setIsMounted] = useState<boolean>(true);

  // Track page view
  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const execute = async () => {
      var reply = await settingsService!.Get();
      if (isMounted) setSettings(reply);
    };
    execute();
    return () => {
      setIsMounted(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveSettings = async () => {
    setSaving(true);
    await settingsService?.Save(settings!);
    dialogService?.showSuccessDialog(
      "Settings saved",
      "All settings have been saved."
    );
    setSaving(false);
  };

  return (
    (settings && (
      <>
        <Stack horizontal wrap tokens={{ childrenGap: 20 }}>
          <Stack.Item style={{ maxWidth: 300, width: 300 }}>
            <Slider
              label="Minimum tokens per chunk"
              max={200}
              min={10}
              step={10}
              value={settings!.min_size_chunk}
              showValue
              onChange={(val) =>
                setSettings({ ...settings, min_size_chunk: val })
              }
            />
            <Text variant={"xSmall"} block>
              The minimum number of tokens a chunk must have to be eligible for
              upload.
            </Text>
          </Stack.Item>
          <Stack.Item style={{ maxWidth: 300, width: 300 }}>
            <Slider
              label="Maximum tokens per chunk"
              max={1000}
              step={10}
              min={settings!.min_size_chunk}
              value={settings!.max_size_chunk}
              showValue
              onChange={(val) =>
                setSettings({ ...settings, max_size_chunk: val })
              }
            />
            <Text variant={"xSmall"} block>
              The maximum number of tokens per chunk.
            </Text>
          </Stack.Item>
          <Stack.Item style={{ maxWidth: 300, width: 300 }}>
            <Slider
              label="Maximum tokens per sentence"
              max={500}
              step={10}
              min={0}
              value={settings.max_sentence_tokens}
              showValue
              onChange={(val) =>
                setSettings({ ...settings, max_sentence_tokens: val })
              }
            />
            <Text variant={"xSmall"} block>
              The maximum number of tokens that the sentence from the previous
              chunk may have to be prepended to the following chunk.
            </Text>
          </Stack.Item>
        </Stack>
        <Stack style={{ marginTop: 20 }}>
          <Stack.Item>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <PrimaryButton
                onClick={saveSettings}
                text={saving ? "Saving..." : "Save"}
                allowDisabledFocus
              />
            </Stack>
          </Stack.Item>
        </Stack>
      </>
    )) || <Spinner size={SpinnerSize.large} />
  );
};

export default TrainingSettings;
