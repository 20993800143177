import { FC, useContext, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { chatAppServiceContext } from "../../../../../../Services/API/ChatAppService";
import IChatApp from "../../../../../../Models/API/IChatApp";
import IPermission from "../../../../../../Models/API/IPermission";
import PermissionManagement from "../../../../../../Components/PermissionManagement/PermissionManagement";
import { trackPageView } from "../../../../../../Services/AppInsights";
import ConfigurationService from "../../../../../../Services/ConfigurationService";

const ChatAppPermissions: FC = () => {
  const { chatApp } = useOutletContext<{
    chatApp: IChatApp;
    setChatApp: (value: IChatApp) => void;
  }>();
  const chatAppsService = useContext(chatAppServiceContext);

  // Track page view
  useEffect(() => {
    document.title = `${ConfigurationService.Default.Configuration.PageTitle} - ${chatApp.DisplayName} Permissions`;
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _addPermission = async (objectId: string, permissionType: string) => {
    return await chatAppsService!.CreatePermission(
      chatApp.Id,
      objectId,
      permissionType as any
    );
  };

  const _deletePermission = async (objectId: string) => {
    await chatAppsService!.DeletePermission(chatApp.Id, objectId);
  };

  const _reloadPermissions = async () => {
    var perms = await chatAppsService!.GetPermissions(chatApp.Id);
    return perms as IPermission[];
  };

  const _checkPermissions = async (objectId: string) => {
    var perms = await chatAppsService!.CheckUserPermissions(
      objectId,
      chatApp.Id
    );
    return perms ?? [];
  };

  return (
    <PermissionManagement
      resourceType={"ChatApp"}
      resourceId={chatApp.Id}
      permissions={{
        Read: {
          DisplayName: "Read",
          Description: "Users can interact with the application",
        },
        Write: {
          DisplayName: "Write",
          Description:
            "Users can edit the application settings, assign agents and create libraries",
        },
        FullControl: {
          DisplayName: "Full Control",
          Description: "Users can change the permissions of the application",
        },
        Owner: {
          DisplayName: "Owner",
          Description: "Users can add other owners and delete the application",
        },
      }}
      isOwner={chatApp.PermissionType === "Owner"}
      addPermission={_addPermission}
      deletePermission={_deletePermission}
      reloadPermissions={_reloadPermissions}
      checkPermissions={_checkPermissions}
    />
  );
};

export default ChatAppPermissions;
