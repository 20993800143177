import { FC, createContext, useContext } from "react";
import { ApiHttpServiceContext } from "./ApiHttpService";
import ISettings from "../../Models/API/ISettings";
import { CacheServiceContext } from "./CacheService";

export interface ISettingsService {
  Save(settings: ISettings): Promise<ISettings | null>;
  Get(): Promise<ISettings | null>;
}

export const SettingsServiceContext = createContext<
  ISettingsService | undefined
>(undefined);

const SettingsService: FC = ({ children }: any) => {
  const cacheService = useContext(CacheServiceContext);
  const apiHttpService = useContext(ApiHttpServiceContext);

  const settingsService: ISettingsService = {
    async Save(settings: ISettings) {
      var reply = await apiHttpService!.Put<ISettings>("settings", settings);
      cacheService!.ClearCache("settings");
      return reply;
    },
    async Get() {
      return await apiHttpService!.Get<ISettings>("settings");
    },
  };

  return (
    <SettingsServiceContext.Provider value={settingsService}>
      {children}
    </SettingsServiceContext.Provider>
  );
};

export default SettingsService;
