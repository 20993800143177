import { FC, useEffect, useContext, useState } from "react";
import ConfigurationService from "../../Services/ConfigurationService";
import { Spinner, SpinnerSize, Stack } from "@fluentui/react";
import "./Versions.scss";
import { SettingsServiceContext } from "../../Services/API/SettingsService";
import ReactMarkdown from "react-markdown";
import { trackPageView } from "../../Services/AppInsights";

const Versions: FC = () => {
  const settingsProvider = useContext(SettingsServiceContext);

  const [releases, setReleases] = useState<string>();

  // Track page view
  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.title = `${ConfigurationService.Default.Configuration.PageTitle} - Versions`;
    const execute = async () => {
      var settings = await settingsProvider!.Get();
      setReleases(settings!.chat_jti_releases);
    };
    execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack
      className="gpt-versions"
      verticalAlign="start"
      grow
      style={{ minHeight: 0 }}
      tokens={{ childrenGap: 20 }}
    >
      <Stack.Item style={{ overflowY: "scroll" }}>
        {(releases && (
          <ReactMarkdown linkTarget={"_blank"}>{releases!}</ReactMarkdown>
        )) || <Spinner size={SpinnerSize.large} />}
      </Stack.Item>
    </Stack>
  );
};

export default Versions;
