export enum AppType {
  ChatApp = 'ChatApp',
  AgentApp = 'AgentApp',
  AutomateApp = 'AutomateApp',
}

export const appTypeMap = new Map<string, string>();
appTypeMap.set(AppType.ChatApp, 'Chat');
appTypeMap.set(AppType.AgentApp, 'Agent');
appTypeMap.set(AppType.AutomateApp, 'Automate');

export const pathMap = new Map<string, string>();
pathMap.set('chat', AppType.ChatApp);
pathMap.set('agent', AppType.AgentApp);
pathMap.set('automate', AppType.AutomateApp);
