import { Dialog, DialogFooter, PrimaryButton } from "@fluentui/react";
import axios from "axios";
import { FC, createContext, useEffect, useState } from "react";

export interface IVersionManagerService {}

export const VersionManagerServiceContext =
  createContext<IVersionManagerService>({});

const VersionManagerService: FC = ({ children }: any) => {
  const versionManagerService: IVersionManagerService = {};
  const [version, setVersion] = useState<string>();
  const [timeout, setTimeout] = useState<NodeJS.Timeout>();
  const [newVersion, setNewVersion] = useState<string | null>(null);

  useEffect(() => {
    const execute = async () => {
      var manifest = await axios.get<any>("/manifest.json");
      setVersion(manifest.data.version);
    };
    execute();
    return () => {
      clearInterval(timeout);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (version) {
      setTimeout(setInterval(() => {
        axios.get<any>("/manifest.json").then((manifest) => {
          if (manifest.data.version !== version) {
            setNewVersion(manifest.data.version);
          }
        });
      }, 300000)); // Every 5 minutes check if new version is available
    }
  }, [version]);

  return (
    <VersionManagerServiceContext.Provider value={versionManagerService}>
      <Dialog
        hidden={newVersion == null}
        dialogContentProps={{
          title: "New version available",
          subText: `A new version of the playground is available (${newVersion}).`,
          showCloseButton: false,
        }}
        modalProps={{
          isBlocking: true,
        }}
      >
        <DialogFooter>
          <PrimaryButton
            onClick={() => window.location.reload()}
            text="Update"
          />
        </DialogFooter>
      </Dialog>
      {children}
    </VersionManagerServiceContext.Provider>
  );
};

export default VersionManagerService;
