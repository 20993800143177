import { FC, useContext, useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import IAppCatalog from "../../../../../Models/API/IAppCatalog";
import {
  Stack,
  TextField,
  DefaultButton,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Text,
} from "@fluentui/react";
import {
  INewAgentApp,
  agentAppServiceContext,
} from "../../../../../Services/API/AgentAppService";
import { trackPageView } from "../../../../../Services/AppInsights";

const defaultNewAgentApp: INewAgentApp = {
  DisplayName: "",
  Description: "",
  SystemPrompt: "",
};

export interface INewAgentAppProps {}

const NewAgentApp: FC<INewAgentAppProps> = () => {
  const navigate = useNavigate();
  const agentAppService = useContext(agentAppServiceContext);

  const { catalog } = useOutletContext<{ catalog: IAppCatalog }>();

  const [newAgentApp, setNewAgentApp] =
    useState<INewAgentApp>(defaultNewAgentApp);
  const [creatingChatApp, setCreatingChatApp] = useState<boolean>(false);

  // Track page view
  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createAgentApp = async (): Promise<void> => {
    if (!creatingChatApp) {
      newAgentApp.AppCatalogId = catalog.Id;
      setCreatingChatApp(true);
      const newApp = await agentAppService?.Create(newAgentApp);
      if (newApp != null) {
        navigate(`../${newApp!.Id.toString()}`);
      } else {
        setCreatingChatApp(false);
      }
    }
  };

  return (
    <>
      <Stack tokens={{ childrenGap: 20 }}>
        <Stack.Item>
          <TextField
            required={true}
            label="Display Name"
            value={newAgentApp.DisplayName}
            onChange={(event, newValue) =>
              setNewAgentApp({
                ...newAgentApp,
                DisplayName: newValue,
              })
            }
          />
          <Text variant="xSmall">Minimum 3 characters</Text>
        </Stack.Item>
        <Stack.Item>
          <TextField
            required={true}
            label="Description"
            multiline
            rows={3}
            resizable={false}
            value={newAgentApp.Description}
            onChange={(event, newValue) =>
              setNewAgentApp({
                ...newAgentApp,
                Description: newValue,
              })
            }
          />
          <Text variant="xSmall">
            Minimum 20 characters and maximum 1000 characters
          </Text>
        </Stack.Item>
        <Stack.Item>
          <TextField
            label="System prompt"
            multiline
            rows={4}
            resizable={true}
            value={newAgentApp.SystemPrompt}
            onChange={(event, newValue) =>
              setNewAgentApp({
                ...newAgentApp,
                SystemPrompt: newValue,
              })
            }
          />
        </Stack.Item>

        <Stack.Item>
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <DefaultButton
              onClick={() => navigate(`/catalogseditor/${catalog.Id}`)}
              text="Cancel"
            />
            <PrimaryButton
              style={{ minWidth: 80 }}
              onClick={createAgentApp}
              disabled={
                !newAgentApp.DisplayName ||
                newAgentApp.DisplayName.length < 3 ||
                !newAgentApp.Description ||
                newAgentApp.Description.length < 20 ||
                newAgentApp.Description.length > 1000
              }
              text={creatingChatApp ? "" : "Create"}
            >
              {creatingChatApp && <Spinner size={SpinnerSize.small} />}
            </PrimaryButton>
          </Stack>
        </Stack.Item>
      </Stack>
    </>
  );
};

export default NewAgentApp;
