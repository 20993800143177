import {
  Callout,
  ColorPicker,
  DefaultButton,
  IColor,
  getColorFromString,
} from "@fluentui/react";
import React, { useEffect } from "react";
import { FC } from "react";
import { useId } from "@fluentui/react-hooks";

const white = getColorFromString("#ffffff")!;

export interface IColorPicker {
  color: string;
  showPreview: boolean;
  setColor: (color: string) => void;
}

const AppColorPicker: FC<IColorPicker> = ({ color, showPreview, setColor }) => {
  const [innerColor, setInnerColor] = React.useState<IColor>(white);
  const [showPicker, setShowPicker] = React.useState(false);

  const buttonId = useId("callout-button");

  const updateColor = React.useCallback((ev: any, colorObj: IColor) => {
    setInnerColor(colorObj);
  }, []);

  const onDismiss = () => {
    setColor(innerColor.str);
    setShowPicker(false);
  };

  useEffect(() => {
    const iColor = getColorFromString(color);
    setInnerColor(iColor ? iColor : white);
  }, [color]);

  useEffect(() => {
    const iColor = getColorFromString(color);
    setInnerColor(iColor ? iColor : white);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DefaultButton
        text=""
        style={{ backgroundColor: innerColor.str }}
        onClick={() => setShowPicker(true)}
        allowDisabledFocus
        id={buttonId}
      />
      {showPicker ? (
        <Callout
          target={`#${buttonId}`}
          gapSpace={0}
          setInitialFocus={true}
          onDismiss={onDismiss}
          style={{ backgroundColor: innerColor.str }}
        >
          <ColorPicker
            color={innerColor}
            alphaType="none"
            onChange={updateColor}
            showPreview={false}
          />
        </Callout>
      ) : (
        <></>
      )}
    </>
  );
};

export default AppColorPicker;
