import React from "react";
import AuthenticationService from "../../Services/AuthenticationService";
import { Navigate } from "react-router-dom";

interface IRoleProps {
  children?: React.ReactNode;
  allowedRoles?: Array<string> | undefined;
  hide?: boolean;
}

export default class Role extends React.Component<IRoleProps> {

  render(): React.ReactNode {
    if (
      this.props.allowedRoles !== undefined &&
      !AuthenticationService.Default.HasAnyRole(this.props.allowedRoles)
    ) {
      return (!this.props.hide && <Navigate to="/accessdenied"></Navigate>) || <></>;
    } else {
      return <>{this.props.children}</>;
    }
  }

  override componentDidMount(): void {}
}
