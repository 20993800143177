import { FC, useContext, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import IAutomateApp, {
  IAutomateAppInputParameter,
} from "../../../../../../Models/API/IAutomateApp";

import { automateAppServiceContext } from "../../../../../../Services/API/AutomateAppService";
import AutomateAppParameters from "../../../../../../Components/AutomateAppParameters/AutomateAppParameters";
import { IColumn } from "@fluentui/react";
import { trackPageView } from "../../../../../../Services/AppInsights";
import ConfigurationService from "../../../../../../Services/ConfigurationService";

const columns: IColumn[] = [
  {
    key: "DisplayName",
    name: "Display Name",
    minWidth: 200,
    isResizable: true,
  },
  {
    key: "Type",
    name: "Type",
    minWidth: 200,
    isResizable: true,
  },
  {
    key: "Required",
    name: "Required",
    minWidth: 200,
    isResizable: true,
  },
];

export interface IAutomateInputParamsProps {}

const AutomateInputParams: FC<IAutomateInputParamsProps> = () => {
  const { automateApp, setAutomateApp } = useOutletContext<{
    automateApp: IAutomateApp;
    setAutomateApp: (value: IAutomateApp) => void;
  }>();
  const automateAppsService = useContext(automateAppServiceContext);

  // Track page view
  useEffect(() => {
    document.title = `${ConfigurationService.Default.Configuration.PageTitle} - ${automateApp.DisplayName} Input Parameters`;
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateAutomateAppParameters = async (
    parameters: IAutomateAppInputParameter[]
  ) => {
    const refreshedAutomateApp = await automateAppsService?.Update(
      automateApp.Id,
      {
        ...automateApp,
        InputParameters: parameters,
      }
    );
    if (refreshedAutomateApp) {
      setAutomateApp(refreshedAutomateApp);
    }
  };

  return (
    <>
      <AutomateAppParameters
        parameters={
          automateApp.InputParameters ? automateApp.InputParameters : []
        }
        setParameters={updateAutomateAppParameters}
        columns={columns}
      />
    </>
  );
};

export default AutomateInputParams;
