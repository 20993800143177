import { FC, createContext, useContext } from "react";
import AuthenticationService from "../AuthenticationService";
import { HttpServiceContext } from "./HttpService";
import ConfigurationService from "../ConfigurationService";
import { CacheServiceContext } from "./CacheService";
import { AxiosProgressEvent, CancelToken } from "axios";

export interface IApiHttpService {
  Get<T>(
    path: string,
    headers?: any | undefined,
    useCache?: boolean,
    throwError?: boolean
  ): Promise<T | null>;
  Download<T>(
    path: string,
    headers?: any | undefined,
    useCache?: boolean
  ): Promise<T | null>;
  Post<T>(
    path: string,
    data?: any | undefined,
    headers?: any | undefined,
    cancelToken?: CancelToken | undefined,
    onUploadProgress?:
      | ((progressEvent: AxiosProgressEvent) => void)
      | undefined,
    throwError?: boolean
  ): Promise<T | null>;
  PostAndGetStream(
    path: string,
    onDataReceived: (data: any) => void,
    onCompleted: () => void,
    onError: (error:any) => void,
    data?: any | undefined,
    headers?: any | undefined,
    cancelToken?: CancelToken | undefined,
    throwError?: boolean
  ): void;
  Put<T>(
    path: string,
    data?: any | undefined,
    headers?: any | undefined
  ): Promise<T | null>;
  Delete<T>(
    path: string,
    headers?: any | undefined,
    throwError?: boolean
  ): Promise<T | null>;
}

export const ApiHttpServiceContext = createContext<IApiHttpService | undefined>(
  undefined
);

const ApiHttpService: FC = ({ children }: any) => {
  const httpService = useContext(HttpServiceContext);
  const cacheService = useContext(CacheServiceContext);

  const apiHttpService: IApiHttpService = {
    async Get<T>(
      path: string,
      headers?: any | undefined,
      useCache: boolean = false,
      throwError?: boolean
    ) {
      if (headers === undefined || headers === null) {
        headers = {};
      }
      headers[
        "Authorization"
      ] = `Bearer ${await AuthenticationService.Default.APIAccessToken()}`;
      if (useCache) {
        return await cacheService!.GetCache<T>(path, async () => {
          return await httpService!.Get<T>(
            `${ConfigurationService.Default.Configuration.API?.BaseUri}/${path}`,
            headers,
            throwError
          );
        });
      } else {
        return await httpService!.Get<T>(
          `${ConfigurationService.Default.Configuration.API?.BaseUri}/${path}`,
          headers,
          throwError
        );
      }
    },
    async Download<T>(
      path: string,
      headers?: any | undefined,
      useCache: boolean = false
    ) {
      if (headers === undefined) {
        headers = {};
      }
      headers[
        "Authorization"
      ] = `Bearer ${await AuthenticationService.Default.APIAccessToken()}`;
      if (useCache) {
        return await cacheService!.GetCache<T>(path, async () => {
          return await httpService!.Download<T>(
            `${ConfigurationService.Default.Configuration.API?.BaseUri}/${path}`,
            headers
          );
        });
      } else {
        return await httpService!.Download<T>(
          `${ConfigurationService.Default.Configuration.API?.BaseUri}/${path}`,
          headers
        );
      }
    },
    async Post<T>(
      path: string,
      data?: any | undefined,
      headers?: any | undefined,
      cancelToken?: CancelToken | undefined,
      onUploadProgress:
        | ((progressEvent: AxiosProgressEvent) => void)
        | undefined = undefined,
      throwError: boolean = false
    ) {
      if (headers === undefined) {
        headers = {};
      }
      headers[
        "Authorization"
      ] = `Bearer ${await AuthenticationService.Default.APIAccessToken()}`;
      return await httpService!.Post<T>(
        `${ConfigurationService.Default.Configuration.API?.BaseUri}/${path}`,
        data,
        headers,
        cancelToken,
        onUploadProgress,
        throwError
      );
    },
    async PostAndGetStream(
      path: string,
      onDataReceived: (data: any) => void,
      onCompleted: () => void,
      onError: (error:any) => void,
      data?: any | undefined,
      headers?: any | undefined,
      cancelToken?: CancelToken | undefined,
      throwError?: boolean
    ) {
      if (headers === undefined) {
        headers = {};
      }
      headers[
        "Authorization"
      ] = `Bearer ${await AuthenticationService.Default.APIAccessToken()}`;
      await httpService!.PostAndGetStream(
        `${ConfigurationService.Default.Configuration.API?.BaseUri}/${path}`,
        onDataReceived,
        onCompleted,
        onError,
        data,
        headers,
        cancelToken,
        throwError
      );
    },
    async Put<T>(
      path: string,
      data?: any | undefined,
      headers?: any | undefined
    ) {
      if (headers === undefined) {
        headers = {};
      }
      headers[
        "Authorization"
      ] = `Bearer ${await AuthenticationService.Default.APIAccessToken()}`;
      return await httpService!.Put<T>(
        `${ConfigurationService.Default.Configuration.API?.BaseUri}/${path}`,
        data,
        headers
      );
    },
    async Delete<T>(
      path: string,
      headers?: any | undefined,
      throwError?: boolean
    ) {
      if (headers === undefined) {
        headers = {};
      }
      headers[
        "Authorization"
      ] = `Bearer ${await AuthenticationService.Default.APIAccessToken()}`;
      return await httpService!.Delete<T>(
        `${ConfigurationService.Default.Configuration.API?.BaseUri}/${path}`,
        headers,
        throwError
      );
    },
  };

  return (
    <ApiHttpServiceContext.Provider value={apiHttpService}>
      {children}
    </ApiHttpServiceContext.Provider>
  );
};

export default ApiHttpService;
