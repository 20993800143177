import { FC, createContext, useContext } from "react";
import axios, { AxiosProgressEvent, CancelToken } from "axios";
import { ToastServiceContext } from "../ToastService";

export interface IHttpService {
  Get<T>(
    path: string,
    headers?: any | undefined,
    throwError?: boolean
  ): Promise<T | null>;
  Download<T>(path: string, headers?: any | undefined): Promise<T | null>;
  Post<T>(
    path: string,
    data: any | undefined,
    headers: any | undefined,
    cancelToken?: CancelToken | undefined,
    onUploadProgress?:
      | ((progressEvent: AxiosProgressEvent) => void)
      | undefined,
    throwError?: boolean
  ): Promise<T | null>;
  PostAndGetStream(
    path: string,
    onDataReceived: (data: any) => void,
    onCompleted: () => void,
    onError: (error: any) => void,
    data: any | undefined,
    headers: any | undefined,
    cancelToken?: CancelToken | undefined,
    throwError?: boolean
  ): void;
  Put<T>(
    path: string,
    data: any | undefined,
    headers: any | undefined
  ): Promise<T | null>;
  Delete<T>(
    path: string,
    headers: any | undefined,
    throwError?: boolean
  ): Promise<T | null>;
}

export const HttpServiceContext = createContext<IHttpService | undefined>(
  undefined
);

const HttpService: FC = ({ children }: any) => {
  const toastService = useContext(ToastServiceContext);

  const handleError = (error: any): void => {
    var errorResponse = error.response?.data as any;
    if (errorResponse) {
      let messages = new Array<string>();
      if (errorResponse.errors) {
        var keys = Object.keys(errorResponse.errors);
        for (var i = 0; i < keys.length; i++) {
          messages.push(errorResponse.errors[keys[i]]);
        }
      } else {
        messages.push(errorResponse.title);
      }
      for (var a = 0; a < messages.length; a++) {
        toastService?.AddMessage({
          message: messages[a],
          type: "error",
        });
      }
    } else {
      toastService?.AddMessage({
        message: error.message,
        type: "error",
      });
    }
  };

  const httpService: IHttpService = {
    async Get<T>(
      path: string,
      headers: any | undefined = undefined,
      throwError?: boolean
    ) {
      try {
        var result = await axios.get<T>(path, {
          headers,
        });
        return result.data;
      } catch (error: any) {
        if (throwError) throw error;
        handleError(error);
        return null;
      }
    },
    async Download<T>(path: string, headers: any | undefined = undefined) {
      try {
        var result = await axios.get<T>(path, {
          headers: { ...headers },
          responseType: "blob",
        });
        return result.data;
      } catch (error: any) {
        handleError(error);
        return null;
      }
    },
    async Post<T>(
      path: string,
      data: any,
      headers: any,
      cancelToken: CancelToken | undefined = undefined,
      onUploadProgress:
        | ((progressEvent: AxiosProgressEvent) => void)
        | undefined = undefined,
      throwError: boolean = false
    ) {
      try {
        var result = await axios.post<T>(path, data, {
          headers,
          onUploadProgress,
          cancelToken,
        });
        return result.data;
      } catch (error: any) {
        if (throwError) throw error;
        handleError(error);
        return null;
      }
    },
    async PostAndGetStream(
      path: string,
      onDataReceived: (data: any) => void,
      onCompleted: () => void,
      onError: (error: any) => void,
      data: any | undefined,
      headers: any | undefined,
      cancelToken?: CancelToken | undefined,
      throwError?: boolean
    ) {
      try {
        headers["Content-Type"] = "application/json";
        fetch(path, {
          body: JSON.stringify(data),
          headers,
          method: "POST",
        })
          .then((response) => {
            const stream = response.body;
            const reader = stream!.getReader();
            const readChunk = () => {
              reader
                .read()
                .then(({ value, done }) => {
                  if (done) {
                    onCompleted();
                    return;
                  }
                  let chunkString = new TextDecoder().decode(value);
                  // Split chunks by double new line
                  const chunks = chunkString.split("\n\n");
                  for (var i = 0; i < chunks.length; i++) {
                    // Substring(6) to remove "data: " from the start of the string
                    onDataReceived(chunks[i].substring(6));
                  }
                  readChunk();
                })
                .catch((error) => {
                  handleError(error);
                });
            };
            readChunk();
          })
          .catch((error) => {
            onError(error);
            handleError(error);
          });
      } catch (error: any) {
        if (throwError) throw error;
        handleError(error);
      }
    },
    async Put<T>(path: string, data: any, headers: any) {
      try {
        var result = await axios.put<T>(path, data, {
          headers,
        });
        return result.data;
      } catch (error: any) {
        handleError(error);
        return null;
      }
    },
    async Delete<T>(path: string, headers: any, throwError?: boolean) {
      try {
        var result = await axios.delete<T>(path, {
          headers,
        });
        return result.data;
      } catch (error: any) {
        if (throwError) throw error;
        handleError(error);
        return null;
      }
    },
  };

  return (
    <HttpServiceContext.Provider value={httpService}>
      {children}
    </HttpServiceContext.Provider>
  );
};

export default HttpService;
