import { Label, Text, Checkbox, IStackProps, Stack } from '@fluentui/react';
import { FC, useEffect } from 'react';
import { IAutomateAppParameter } from '../../../../Models/API/IAutomateApp';
import { useId } from '@fluentui/react-hooks';

const columnProps: Partial<IStackProps> = {
  tokens: { childrenGap: 5 },
};

const itemProps: Partial<IStackProps> = {
  styles: { root: { width: 200 } },
};

export interface IBooleanTypeProps {
  param: IAutomateAppParameter;
  setData: (param: IAutomateAppParameter, value: boolean) => void;
}

const BooleanType: FC<IBooleanTypeProps> = ({ param, setData }) => {
  const type = useId('type');

  useEffect(() => {
    setData(param, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack {...columnProps}>
      <Label required={param.Required} htmlFor={type}>
        {param.DisplayName}
      </Label>
      <Stack.Item {...itemProps}>
        <Checkbox
          label={param.DisplayName}
          id={type}
          onChange={(evnt, checked) => setData(param, checked!)}
        />
      </Stack.Item>
      <Text variant={'xSmall'} block>
        {param.Description}
      </Text>
    </Stack>
  );
};

export default BooleanType;
