import { CommandBarButton, Text, IIconProps } from "@fluentui/react";
import { FC } from "react";
import styles from "./FunctionsFormProperties.module.scss";
import { Property } from "./FunctionsForm";
import FunctionFormProperty from "./FunctionFormProperty";

const addIcon: IIconProps = { iconName: "CircleAddition" };

const defaultNewProperty: Property = {
  internalId: 0,
  name: "",
  type: "string",
  description: "",
  required: false,
};

export interface IFunctionFormPropertiesProps {
  properties: Property[];
  setProperties(properties: Property[]): void;
  setPropertiesDirty(dirty: boolean): void;
}

const FunctionFormProperties: FC<IFunctionFormPropertiesProps> = ({
  properties,
  setProperties,
  setPropertiesDirty,
}) => {
  const deleteProperty = (property: Property) => {
    setPropertiesDirty(true);
    setProperties(
      properties.filter((x) => x.internalId !== property.internalId)
    );
  };

  const addProperty = () => {
    setPropertiesDirty(true);
    setProperties([
      ...properties,
      {
        ...defaultNewProperty,
        internalId:
          properties && properties.length
            ? properties[properties.length - 1].internalId + 1
            : 1,
      },
    ]);
  };

  const updateProperty = (property: Property) => {
    const foundProp = properties.find(
      (x) => x.internalId === property.internalId
    );

    if (foundProp) {
      var props = properties.filter(
        (x) => x.internalId !== foundProp.internalId
      );
      props.push(property);
      props.sort((a, b) => a.internalId - b.internalId);
      setProperties(props);
      setPropertiesDirty(true);
    }
  };

  return (
    <>
      <article className={styles.properties}>
        <header>
          <Text variant={"large"}>Properties</Text>
        </header>
        <main>
          {properties && properties.length ? (
            <>
              {properties.map((property, index) => (
                <div key={index} className={styles.row}>
                  <FunctionFormProperty
                    property={property}
                    deleteProperty={deleteProperty}
                    setProperty={updateProperty}
                  />
                </div>
              ))}
            </>
          ) : (
            <>
              <p className={styles.empty}>
                No Properties for the current function
              </p>
            </>
          )}
        </main>
      </article>
      <CommandBarButton
        iconProps={addIcon}
        text="Add Property"
        onClick={addProperty}
      />
    </>
  );
};

export default FunctionFormProperties;
