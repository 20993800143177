import { IDropdownOption } from "@fluentui/react";

export default interface IMetadataField {
  DisplayName: string;
  InternalName?: string;
  Description: string;
  Required: boolean;
  Type: IMetadataFieldType;
  DefaultValue?: IMetadataFieldValueType;
}

export interface IUpdateMetadataField {
  DisplayName: string;
  InternalName: string;
  Description: string;
  Required: boolean;
  DefaultValue?: IMetadataFieldValueType;
}

export type IMetadataFieldType =
  | "String"
  | "Integer"
  | "Double"
  | "Boolean"
  | "DateTime"
  | "StringList";

export type IMetadataFieldValueType =
  | string
  | number
  | boolean
  | Date
  | string[]
  | null
  | undefined;

export const MetadataFieldTypes: IDropdownOption[] = [
  { key: "String", text: "String" },
  { key: "Integer", text: "Integer" },
  { key: "Double", text: "Double" },
  { key: "Boolean", text: "Boolean" },
  { key: "DateTime", text: "DateTime" },
  { key: "StringList", text: "List" },
];
