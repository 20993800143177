import {
  Dialog,
  DialogFooter,
  DialogType,
  FontIcon,
  PrimaryButton,
  Stack,
  Text,
} from "@fluentui/react";
import { FC, createContext, useState } from "react";

export interface IDialogService {
  showWarningDialog(title: string, message: string): void;
  showSuccessDialog(title: string, message: string): void;
}

export const DialogServiceContext = createContext<IDialogService | undefined>(
  undefined
);

const DialogService: FC = ({ children }: any) => {
  const dialogService: IDialogService = {
    showWarningDialog(title: string, message: string) {
      setShowDialog(true);
      setDialogTitle(title);
      setDialogMessage(message);
      setIsBlocking(true);
      setShowCloseButton(true);
      setDialogType(DialogType.normal);
      setIsWarning(true);
    },
    showSuccessDialog(title: string, message: string) {
      setShowDialog(true);
      setDialogTitle(title);
      setDialogMessage(message);
      setIsBlocking(true);
      setShowCloseButton(true);
      setDialogType(DialogType.normal);
      setIsWarning(false);
    },
  };
  const [showDialog, setShowDialog] = useState<boolean>();
  const [isBlocking, setIsBlocking] = useState<boolean>();
  const [showCloseButton, setShowCloseButton] = useState<boolean>();
  const [dialogType, setDialogType] = useState<DialogType>();
  const [isWarning, setIsWarning] = useState<boolean>();
  const [dialogTitle, setDialogTitle] = useState<string>();
  const [dialogMessage, setDialogMessage] = useState<string>();

  return (
    <DialogServiceContext.Provider value={dialogService}>
      {children}
      <Dialog
        hidden={!showDialog}
        onDismiss={() => setShowDialog(false)}
        modalProps={{
          isBlocking: isBlocking,
          styles: { main: { maxWidth: 450 } },
        }}
        dialogContentProps={{
          showCloseButton: showCloseButton,
          type: dialogType,
          title: (
            <>
              <Stack
                horizontal
                verticalAlign="center"
                tokens={{ childrenGap: 5 }}
              >
                {isWarning && (
                  <FontIcon aria-label="Warning" iconName="Warning" />
                )}
                <Text variant="xLarge">{dialogTitle}</Text>
              </Stack>
            </>
          ),
        }}
      >
        <Text>{dialogMessage}</Text>
        <DialogFooter>
          <PrimaryButton onClick={() => setShowDialog(false)} text="Close" />
        </DialogFooter>
      </Dialog>
    </DialogServiceContext.Provider>
  );
};

export default DialogService;
