import { Label, TextField, Text, IStackProps, Stack } from '@fluentui/react';
import { FC } from 'react';
import { IAutomateAppParameter } from '../../../../Models/API/IAutomateApp';
import { useId } from '@fluentui/react-hooks';

const columnProps: Partial<IStackProps> = {
  tokens: { childrenGap: 5 },
};

export interface IStringTypeProps {
  param: IAutomateAppParameter;
  setData: (param: IAutomateAppParameter, value: string) => void;
}

const StringType: FC<IStringTypeProps> = ({ param, setData }) => {
  const type = useId('type');
  return (
    <Stack {...columnProps}>
      <Label required={param.Required} htmlFor={type}>
        {param.DisplayName}
      </Label>
      <TextField
        multiline
        autoAdjustHeight
        rows={2}
        resizable
        onChange={(evt, value) => setData(param, value!)}
      ></TextField>
      <Text variant={'xSmall'} block>
        {param.Description}
      </Text>
    </Stack>
  );
};

export default StringType;
