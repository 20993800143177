import { FC, useContext, useEffect, useState } from "react";
import { DialogServiceContext } from "../../../Services/Dialogs/DialogService";
import { SettingsServiceContext } from "../../../Services/API/SettingsService";
import ISettings from "../../../Models/API/ISettings";
import {
  ComboBox,
  IComboBoxOption,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
  TextField,
} from "@fluentui/react";
import { chatAppServiceContext } from "../../../Services/API/ChatAppService";
import IChatApp from "../../../Models/API/IChatApp";
import { trackPageView } from "../../../Services/AppInsights";

const ChatJtiSettings: FC = () => {
  const settingsService = useContext(SettingsServiceContext);
  const dialogService = useContext(DialogServiceContext);
  const chatAppService = useContext(chatAppServiceContext);
  const [settings, setSettings] = useState<ISettings | null>(null);
  const [saving, setSaving] = useState<boolean>(false);
  const [isMounted, setIsMounted] = useState<boolean>(true);
  const [chatApps, setChatApps] = useState<IChatApp[] | null>();

  // Track page view
  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const execute = async () => {
      const apps = await chatAppService?.GetAll();
      if (isMounted) setChatApps(apps);
      var reply = await settingsService!.Get();
      if (isMounted) setSettings(reply);
    };
    execute();
    return () => {
      setIsMounted(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveSettings = async () => {
    setSaving(true);
    await settingsService?.Save(settings!);
    dialogService?.showSuccessDialog(
      "Settings saved",
      "All settings have been saved."
    );
    setSaving(false);
  };

  const options = (): IComboBoxOption[] => {
    let options = chatApps!.map((app) => {
      return {
        key: app.Id,
        text: app.DisplayName,
      };
    });

    options = [
      {
        key: 0,
        text: "None",
      },
      ...options,
    ];

    return options;
  };

  return (
    (settings && (
      <>
        <Stack tokens={{ childrenGap: 20 }}>
          <Stack.Item style={{ maxWidth: 300, width: 300 }}>
            <ComboBox
              defaultSelectedKey={settings.chat_jti_app_id ?? 0}
              label="Chat JTI Application"
              options={options()}
              onChange={(evt, option) => {
                setSettings({
                  ...settings,
                  chat_jti_app_id: parseInt(option!.key.toString()),
                });
              }}
              styles={{
                root: { maxWidth: 300 },
              }}
            />
          </Stack.Item>
          <Stack.Item style={{ maxWidth: 300 }}>
            <TextField
              value={settings.chat_jti_app_title}
              onChange={(event) =>
                setSettings({
                  ...settings,
                  chat_jti_app_title: event.currentTarget.value,
                })
              }
              label="Chat JTI Application - Title"
            />
          </Stack.Item>
          <Stack.Item style={{ maxWidth: 600 }}>
            <TextField
              value={settings.chat_jti_app_description}
              onChange={(event) =>
                setSettings({
                  ...settings,
                  chat_jti_app_description: event.currentTarget.value,
                })
              }
              label="Chat JTI Application - Description"
              multiline
              rows={4}
            />
          </Stack.Item>
          <Stack.Item style={{ maxWidth: 300, width: 300 }}>
            <ComboBox
              defaultSelectedKey={settings.chat_jti_agent_app_id ?? 0}
              label="Chat JTI Agent Application"
              options={options()}
              onChange={(evt, option) => {
                setSettings({
                  ...settings,
                  chat_jti_agent_app_id: parseInt(option!.key.toString()),
                });
              }}
              styles={{
                root: { maxWidth: 300 },
              }}
            />
          </Stack.Item>
          <Stack.Item style={{ maxWidth: 300 }}>
            <TextField
              value={settings.chat_jti_agent_app_title}
              onChange={(event) =>
                setSettings({
                  ...settings,
                  chat_jti_agent_app_title: event.currentTarget.value,
                })
              }
              label="Chat JTI Agent Application - Title"
            />
          </Stack.Item>
          <Stack.Item style={{ maxWidth: 600 }}>
            <TextField
              value={settings.chat_jti_agent_app_description}
              onChange={(event) =>
                setSettings({
                  ...settings,
                  chat_jti_agent_app_description: event.currentTarget.value,
                })
              }
              label="Chat JTI Agent Application - Description"
              multiline
              rows={4}
            />
          </Stack.Item>
          <Stack.Item style={{ maxWidth: 600 }}>
            <TextField
              value={settings.chat_jti_disclaimer}
              onChange={(event) =>
                setSettings({
                  ...settings,
                  chat_jti_disclaimer: event.currentTarget.value,
                })
              }
              label="Chat JTI Disclaimer"
              multiline
              rows={10}
            />
          </Stack.Item>
          <Stack.Item style={{ maxWidth: 600 }}>
            <TextField
              value={settings.chat_jti_footer}
              onChange={(event) =>
                setSettings({
                  ...settings,
                  chat_jti_footer: event.currentTarget.value,
                })
              }
              label="Chat JTI Footer"
              multiline
              rows={5}
            />
          </Stack.Item>
          <Stack.Item style={{ maxWidth: 600 }}>
            <TextField
              value={settings.chat_jti_releases}
              onChange={(event) =>
                setSettings({
                  ...settings,
                  chat_jti_releases: event.currentTarget.value,
                })
              }
              label="Chat JTI Releases"
              multiline
              rows={15}
            />
          </Stack.Item>
          <Stack.Item style={{ maxWidth: 600 }}>
            <TextField
              value={settings.no_access_message}
              onChange={(event) =>
                setSettings({
                  ...settings,
                  no_access_message: event.currentTarget.value,
                })
              }
              label="No Access Message"
              multiline
              rows={15}
            />
          </Stack.Item>
        </Stack>
        <Stack style={{ marginTop: 20 }}>
          <Stack.Item>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <PrimaryButton
                text={saving ? "Saving..." : "Save"}
                readOnly={saving}
                allowDisabledFocus
                onClick={saveSettings}
              />
            </Stack>
          </Stack.Item>
        </Stack>
      </>
    )) || <Spinner size={SpinnerSize.large} />
  );
};

export default ChatJtiSettings;
