import { FC, useEffect, useState } from "react";
import "./ApiReference.scss";
import swagger from "./swagger.json";
import {
  INavLink,
  INavLinkGroup,
  INavStyles,
  Nav,
  Stack,
  StackItem,
} from "@fluentui/react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import ConfigurationService from "../../Services/ConfigurationService";

const navStyles: Partial<INavStyles> = {
  root: {
    width: 200,
    boxSizing: "border-box",
    overflowY: "auto",
    overflowX: "auto",
  },
  groupContent: {
    marginBottom: 0,
  },
};

const ApiReference: FC = () => {
  const [navLinkGroups, setNavLinkGroups] = useState<INavLinkGroup[]>([]);
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    document.title = `${ConfigurationService.Default.Configuration.PageTitle} - API Reference`;
    loadAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.path]);

  const loadAsync = () => {
    buildMenu();
  };

  const pluralName = (name: string) => {
    if (name.indexOf("{"))
      return `${name[0].toUpperCase()}${name.substring(1)}`;
    else return `By ${parseVariableName(name)}`;
  };

  const parseVariableName = (name: string) => {
    let ret = name.replace("{", "").replace("}", "");
    ret = ret.replace(/([A-Z])/g, " $1");
    return `${ret[0].toUpperCase()}${ret.substring(1)}`;
  };

  const buildMenu = () => {
    const paths = Object.keys(swagger.paths);
    paths.sort((a, b) => a.length - b.length);
    var links: INavLink[] = new Array<INavLink>();
    for (var a = 0; a < paths.length; a++) {
      const path = paths[a];
      const parts = path.replace("/api/", "").split("/");
      let currentNode = links;
      for (var b = 0; b < parts.length; b++) {
        let part = parts[b];
        let node = currentNode.find((x) => x.key === `g-${part}`);
        if (!node) {
          node = {
            name: pluralName(part),
            key: `g-${part}`,
            url: "",
            links: new Array<INavLink>()
          };
          currentNode.push(node);
        }
        currentNode = node.links!;

        // Last part
        if (b === parts.length - 1) {
          const pathObject = (swagger as any).paths[path];
          var httpMethods = Object.keys(pathObject);
          for (var c = 0; c < httpMethods.length; c++) {
            const httpMethod = httpMethods[c];
            const key = `${path
              .substring(1)
              .replaceAll("/", "-")}-${httpMethod}`;
            node = {
              name: `${httpMethod.toUpperCase()} ${pluralName(part)}`,
              key: key,
              url: "",
              onClick: () => {
                navigate(
                  {
                    pathname: key,
                  },
                  {
                    relative: "path",
                  }
                );
              },
              links: new Array<INavLink>(),
            };
            currentNode.push(node);
          }
        }
      }
    }
    recursiveSort(links);
    links.map((link) => expandLink(link));
    setNavLinkGroups([{ links: links }]);
  };

  const expandLink = (link: INavLink): boolean => {
    if (link.links) {
      link.isExpanded =
        link.links.filter((child) => expandLink(child)).length > 0;
    }
    return link.isExpanded || link.key === params.path;
  };

  const recursiveSort = (links: INavLink[] | undefined) => {
    if (links) {
      links.sort((a, b) =>
        a.links!.length > 0 && b.links!.length > 0
          ? a.name!.localeCompare(b.name!)
          : a.links!.length - b.links!.length
      );
      for (var i = 0; i < links.length; i++) {
        recursiveSort(links[i].links);
      }
    }
  };

  return (
    navLinkGroups && (
      <Stack
        verticalFill
        horizontal
        tokens={{ childrenGap: 20 }}
        style={{ minHeight: 0, overflowY: "auto", padding: "0 20px" }}
      >
        <StackItem
          verticalFill
          shrink
          style={{
            overflowY: "auto",
            overflowX: "auto",
            minWidth: 200,
          }}
        >
          <Nav
            selectedKey={params.path ? params.path : "none"}
            styles={navStyles}
            groups={navLinkGroups}
          />
        </StackItem>
        <StackItem verticalFill grow shrink style={{ overflowY: "auto" }}>
          <Outlet></Outlet>
        </StackItem>
      </Stack>
    )
  );
};

export default ApiReference;
