import { FC, useEffect, useState } from "react";
import {
  AgentAppFunctionParameters,
  AgentAppFunctionProperty,
  IAgentAppFunction,
  IAgentPropertyType,
} from "../../../../../../Models/API/IAgentAppFunction";
import { INewAgentAppFunction } from "../../../../../../Services/API/AgentAppService";
import { Stack, Text, TextField } from "@fluentui/react";
import FunctionFormProperties from "./FunctionsFormProperties";

export interface IFunctionsFormProps {
  agentFunction: IAgentAppFunction | INewAgentAppFunction;
  setAgentFunction(
    agentFunction: IAgentAppFunction | INewAgentAppFunction
  ): void;
}

export interface Property {
  internalId: number;
  name: string;
  type: IAgentPropertyType;
  description: string;
  required: boolean;
}

const FunctionForm: FC<IFunctionsFormProps> = ({
  agentFunction,
  setAgentFunction,
}) => {
  const [properties, setProperties] = useState<Property[]>([]);
  const [propertiesDirty, setPropertiesDirty] = useState<boolean>(false);

  useEffect(() => {
    if (agentFunction && agentFunction.Parameters) {
      const functionProperties = agentFunction.Parameters.Properties;
      const requiredProperties = agentFunction.Parameters.Required;
      const props: Property[] = [];
      if (functionProperties) {
        let counter = 0;
        for (const [key, value] of functionProperties) {
          props.push({
            internalId: ++counter,
            name: key,
            description: value.Description,
            type: value.Type,
            required: requiredProperties.includes(key),
          });
        }
      }
      setProperties(props);
    }
  }, [agentFunction]);

  const mapProperties = (): IAgentAppFunction | INewAgentAppFunction => {
    const agentProperties = new Map<string, AgentAppFunctionProperty>();

    properties.forEach((x) => {
      agentProperties.set(x.name, {
        Type: x.type,
        Description: x.description,
      });
    });

    const requiredProperties = properties
      .filter((x) => x.required)
      .map((x) => x.name);

    const parameters: AgentAppFunctionParameters = {
      ...agentFunction.Parameters!,
      Properties: agentProperties,
      Required: requiredProperties,
    };

    return { ...agentFunction, Parameters: parameters };
  };

  useEffect(() => {
    if (propertiesDirty) {
      setPropertiesDirty(false);
      setAgentFunction(mapProperties());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [properties]);

  return (
    <>
      {agentFunction !== undefined && (
        <Stack style={{ marginTop: 20 }} tokens={{ childrenGap: 20 }}>
          <Stack.Item>
            <TextField
              required={true}
              label="Display Name"
              value={agentFunction!.DisplayName}
              onChange={(evt, newValue) => {
                setAgentFunction({ ...agentFunction!, DisplayName: newValue! });
              }}
            />
            <Text style={{ marginTop: 4 }} variant="xSmall" block>
              Minimum 5 characters.
            </Text>
            <Text variant="xSmall" block>
              Can only contain letters, numbers and spaces.
            </Text>
          </Stack.Item>
          <Stack.Item>
            <TextField
              required={true}
              label="Description"
              multiline
              rows={3}
              resizable={false}
              value={agentFunction!.Description}
              onChange={(evt, newValue) => {
                setAgentFunction({ ...agentFunction!, Description: newValue! });
              }}
            />
            <Text variant="xSmall">Minimum 20 characters</Text>
          </Stack.Item>
          <Stack.Item>
            <TextField
              label="Return value"
              value={agentFunction!.ReturnValue}
              onChange={(evt, newValue) => {
                setAgentFunction({ ...agentFunction!, ReturnValue: newValue! });
              }}
            ></TextField>
          </Stack.Item>
          <Stack.Item>
            <TextField
              label="Action Url"
              value={agentFunction!.ActionUrl}
              onChange={(evt, newValue) => {
                setAgentFunction({ ...agentFunction!, ActionUrl: newValue! });
              }}
            ></TextField>
          </Stack.Item>
          <Stack.Item>
            <FunctionFormProperties
              properties={properties}
              setProperties={setProperties}
              setPropertiesDirty={setPropertiesDirty}
            />
          </Stack.Item>
        </Stack>
      )}
    </>
  );
};

export default FunctionForm;
