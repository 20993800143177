import { Stack, Text } from "@fluentui/react";
import React, { useContext, useEffect, useRef } from "react";
import greenLogo from "../../Assets/Images/jti_logo_green.svg";
import Typing from "../Typing/Typing";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import "./Conversation.scss";
import { IChatMessage, MessageFeedback } from "../../Models/API/IConversation";
import CodeComponent from "../Code/CodeComponent";
import remarkGfm from "remark-gfm";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCopy,
  faThumbsDown,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";
import { ChatServiceContext } from "../../Services/API/ChatService";
import classNames from "classnames";
import ReactTimeAgo from "react-time-ago";
import AuthenticationService from "../../Services/AuthenticationService";
import UserMessageAttachments from "../UserMessageAttachments/UserMessageAttachments";

export interface IConversationProps {
  messages: IChatMessage[];
  setMessages: (messages: IChatMessage[]) => void;
  typing: boolean;
}

const Conversation: React.FC<IConversationProps> = ({
  messages,
  setMessages,
  typing,
}) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const chatService = useContext(ChatServiceContext);
  const [ignoreMessagesUpdate, setIgnoreMessagesUpdate] =
    React.useState<boolean>(false);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (!ignoreMessagesUpdate) {
      scrollToBottom();
    } else {
      setIgnoreMessagesUpdate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  const copyToClipboardAction = (value: string) => {
    navigator.clipboard.writeText(value);
  };

  const giveFeedback = async (
    message: IChatMessage,
    feedback: MessageFeedback
  ) => {
    if (message.Feedback !== feedback) {
      message.Feedback = feedback;
      setIgnoreMessagesUpdate(true);
      setMessages(
        messages.map((msg) =>
          msg.Id === message.Id ? { ...msg, feedback: feedback } : msg
        )
      );
      await chatService!.GiveMessageFeedback(message.Id, feedback);
    }
  };

  return (
    <>
      <Stack
        className="conversation"
        grow
        tokens={{ childrenGap: 20 }}
        verticalFill
        style={{ minHeight: 0 }}
      >
        <Stack.Item grow shrink style={{ overflowY: "scroll" }}>
          <Stack
            grow
            tokens={{ childrenGap: 15 }}
            verticalAlign="end"
            style={{ minHeight: "100%", padding: "16px" }}
          >
            {messages.map((msg, index) => {
              if (msg.Role === "user") {
                return (
                  <React.Fragment key={index}>
                    <Stack.Item className="msg user-msg">
                      <Stack tokens={{ childrenGap: 5 }}>
                        <Stack.Item>
                          <Stack
                            horizontal
                            horizontalAlign="end"
                            verticalAlign="center"
                            tokens={{ childrenGap: 10 }}
                          >
                            <Stack.Item grow>
                              <Text
                                variant="small"
                                style={{
                                  fontWeight: 700,
                                  textTransform: "uppercase",
                                }}
                              >
                                {AuthenticationService.Default.Account?.name}
                              </Text>
                            </Stack.Item>
                            <Stack.Item align="end">
                              <button
                                type="button"
                                title="Copy message"
                                onClick={() =>
                                  copyToClipboardAction(msg.Content)
                                }
                              >
                                <FontAwesomeIcon icon={faCopy} />
                              </button>
                            </Stack.Item>
                          </Stack>
                        </Stack.Item>
                        <Stack.Item>
                          <div style={{ whiteSpace: "pre-wrap" }}>
                            {msg.Content}
                          </div>
                        </Stack.Item>
                        {msg.Attachments && msg.Attachments.length > 0 && (
                          <Stack.Item>
                            <UserMessageAttachments
                              attachments={msg.Attachments}
                            />
                          </Stack.Item>
                        )}
                      </Stack>
                    </Stack.Item>
                    <div
                      className="message-time"
                      style={{ textAlign: "right" }}
                    >
                      <ReactTimeAgo date={msg.DateTime} locale="en-US" />
                    </div>
                  </React.Fragment>
                );
              } else {
                return (
                  <React.Fragment key={index}>
                    <Stack.Item className="msg bot-msg">
                      <div>
                        <Stack>
                          <Stack.Item grow>
                            <Stack
                              horizontal
                              horizontalAlign="end"
                              verticalAlign="center"
                              tokens={{ childrenGap: 10 }}
                            >
                              <Stack.Item grow>
                                <img
                                  alt="JTI Logo"
                                  width={20}
                                  title="JTI"
                                  src={greenLogo}
                                />
                              </Stack.Item>
                              <Stack.Item shrink>
                                <button
                                  type="button"
                                  title="Thumbs Down"
                                  className={classNames(
                                    msg.Feedback === "Negative" && "active"
                                  )}
                                  onClick={() => giveFeedback(msg, "Negative")}
                                >
                                  <FontAwesomeIcon icon={faThumbsDown} />
                                </button>
                              </Stack.Item>
                              <Stack.Item shrink>
                                <button
                                  type="button"
                                  title="Thumbs Up"
                                  className={classNames(
                                    msg.Feedback === "Positive" && "active"
                                  )}
                                  onClick={() => giveFeedback(msg, "Positive")}
                                >
                                  <FontAwesomeIcon icon={faThumbsUp} />
                                </button>
                              </Stack.Item>
                              <Stack.Item shrink>
                                <button
                                  type="button"
                                  title="Copy answer"
                                  onClick={() =>
                                    copyToClipboardAction(msg.Content)
                                  }
                                >
                                  <FontAwesomeIcon icon={faCopy} />
                                </button>
                              </Stack.Item>
                            </Stack>
                          </Stack.Item>
                          <Stack.Item className="text">
                            <Text>
                              <ReactMarkdown
                                remarkPlugins={[remarkGfm]}
                                linkTarget={"_blank"}
                                components={{
                                  a(props) {
                                    if (props.children[0] === "↩") return <></>;
                                    return (
                                      <Link
                                        className={props.className}
                                        target={
                                          props.href?.startsWith("http")
                                            ? "_blank"
                                            : "_self"
                                        }
                                        to={props.href!}
                                      >
                                        {props.children[0]}
                                      </Link>
                                    );
                                  },
                                  pre(props) {
                                    const { children } = props;
                                    return <pre>{children}</pre>;
                                  },
                                  code(props) {
                                    return (
                                      <CodeComponent {...props}></CodeComponent>
                                    );
                                  },
                                }}
                                children={msg.Content}
                              ></ReactMarkdown>
                            </Text>
                          </Stack.Item>
                        </Stack>
                      </div>
                    </Stack.Item>
                    <div className="message-time">
                      <ReactTimeAgo date={msg.DateTime} locale="en-US" />
                    </div>
                  </React.Fragment>
                );
              }
            })}
            <div className="chatScrollElement" ref={messagesEndRef}></div>
            <Stack.Item hidden={!typing}>
              <Typing />
            </Stack.Item>
          </Stack>
        </Stack.Item>
      </Stack>
    </>
  );
};

export default Conversation;
