import { FC, useContext, useEffect, useState } from "react";
import { catalogServiceContext } from "../../Services/API/CatalogService";
import { Spinner, SpinnerSize } from "@fluentui/react";
import greenLogo from "../../Assets/Images/jti_logo_green.svg";

export interface ICatalogLogoProps {
  catalogId: number;
  width?: number;
  height?: number;
  skipLoad?: boolean;
}

const CatalogLogo: FC<ICatalogLogoProps> = ({
  catalogId,
  width,
  height,
  skipLoad,
}) => {
  const catalogService = useContext(catalogServiceContext);
  const [logoUrl, setLogoUrl] = useState<string>();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [isMounted, setIsMounted] = useState<boolean>(true);

  useEffect(() => {
    setLoaded(false);
    if (skipLoad) {
      setLogoUrl(greenLogo);
      setLoaded(true);
    } else if (isMounted) {
      const logo = catalogService!.GetLogo(catalogId);
      logo.then(
        (data) => {
          setLogoUrl(`data:image/png;base64,${data}`);
          setLoaded(true);
        },
        (error) => {
          // Ignore error. The logo was just not set
          setLogoUrl(greenLogo);
          setLoaded(true);
        }
      );
    }
    return () => {
      setIsMounted(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    (loaded && (
      <img alt="Catalog logo" width={width} height={height} src={logoUrl} />
    )) || <Spinner size={SpinnerSize.small} />
  );
};

export default CatalogLogo;
