import {
  BaseSlots,
  createTheme,
  getColorFromString,
  isDark,
  ThemeGenerator,
  themeRulesStandardCreator,
} from '@fluentui/react';

const generateTheme = ({
  primaryColor,
  textColor,
  backgroundColor,
}: {
  primaryColor: string;
  textColor: string;
  backgroundColor: string;
}) => {
  const themeRules = themeRulesStandardCreator();
  const colors = {
    primaryColor: getColorFromString(primaryColor)!,
    textColor: getColorFromString(textColor)!,
    backgroundColor: getColorFromString(backgroundColor)!,
  };

  const isCustomization = true;
  const overwriteCustomColor = true;

  const currentIsDark = isDark(themeRules[BaseSlots[BaseSlots.backgroundColor]].color!);

  ThemeGenerator.insureSlots(
    themeRules,
    isDark(themeRules[BaseSlots[BaseSlots.backgroundColor]].color!)
  );

  ThemeGenerator.setSlot(
    themeRules[BaseSlots[BaseSlots.primaryColor]],
    colors.primaryColor,
    currentIsDark,
    isCustomization,
    overwriteCustomColor
  );

  ThemeGenerator.setSlot(
    themeRules[BaseSlots[BaseSlots.backgroundColor]],
    colors.backgroundColor,
    undefined,
    isCustomization,
    overwriteCustomColor
  );

  ThemeGenerator.setSlot(
    themeRules[BaseSlots[BaseSlots.foregroundColor]],
    colors.textColor,
    currentIsDark,
    isCustomization,
    overwriteCustomColor
  );

  const themeAsJson: {
    [key: string]: string;
  } = ThemeGenerator.getThemeAsJson(themeRules);

  const finalTheme = createTheme({
    ...{ palette: themeAsJson },
    isInverted: isDark(themeRules[BaseSlots[BaseSlots.backgroundColor]].color!),
  });

  return finalTheme;
};

export default generateTheme;
