import { FC } from "react";
import swagger from "./../swagger.json";
import { Link, Stack, StackItem, Text } from "@fluentui/react";

const Default: FC = () => {
  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <StackItem>
        <Text variant="superLarge">API Reference</Text>
      </StackItem>
      <StackItem>
        <Text variant="xxLarge">{swagger.info.title}</Text>
      </StackItem>
      <StackItem>
        <Text variant="large">Version {swagger.info.version}</Text>
      </StackItem>
      <StackItem>
        <Link href={`mailto:${swagger.info.contact.email}`}>
          {swagger.info.contact.name}
        </Link>
      </StackItem>
    </Stack>
  );
};

export default Default;
