import { FC, useContext, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import IChatApp from "../../../../../../Models/API/IChatApp";
import { SettingsServiceContext } from "../../../../../../Services/API/SettingsService";
import { ISettings } from "@fluentui/react";
import { trackPageView } from "../../../../../../Services/AppInsights";
import ConfigurationService from "../../../../../../Services/ConfigurationService";
import ApplicationChatDebug from "../../../../../../Components/ApplicationChatDebug/ApplicationChatDebug";

export interface ITestChatAppProps {}

const ChatAppTest: FC<ITestChatAppProps> = () => {
  const settingsService = useContext(SettingsServiceContext);
  const { chatApp } = useOutletContext<{
    chatApp: IChatApp;
  }>();
  const [settings, setSettings] = useState<ISettings | null>();

  // Track page view
  useEffect(() => {
    document.title = `${ConfigurationService.Default.Configuration.PageTitle} - ${chatApp.DisplayName}`;
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const execute = async () => {
      const settings = await settingsService!.Get();
      setSettings(settings);
    };
    execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {settings && (
        <ApplicationChatDebug
          maxTokens={settings?.max_prompt_tokens!}
          chatApp={chatApp}
        />
      )}
    </>
  );
};

export default ChatAppTest;
