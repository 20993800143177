import { FC, createContext, useContext } from "react";
import { ApiHttpServiceContext } from "./ApiHttpService";
import { CacheServiceContext } from "./CacheService";
import IAutomateApp from "../../Models/API/IAutomateApp";
import IAutomateAppPermission from "../../Models/API/IAutomateAppPermission";
import AuthenticationService from "../AuthenticationService";
import IUserPermission, {
  PermissionType,
} from "../../Models/API/IUserPermission";

export interface INewAutomateApp {
  DisplayName?: string;
  Description?: string;
  ActionUrl?: string;
  AppCatalogId?: number;
}

export interface IAutomateAppService {
  // CRUD
  GetAll(): Promise<IAutomateApp[] | null>;
  Get(automateAppId: number): Promise<IAutomateApp | null>;
  Create(agentApp: INewAutomateApp): Promise<IAutomateApp | null>;
  Update(
    automateAppId: number,
    agentApp: IAutomateApp
  ): Promise<IAutomateApp | null>;
  Delete(automateAppId: number): Promise<void | null>;
  PublishAutomateApp(automateAppId: number, publish: boolean): Promise<void>;
  Run(
    automateAppId: number,
    formData: FormData
  ): Promise<AutomateRunReturn | null>;
  // Permissions
  OverrideCatalogPermissions(
    automateAppId: number,
    overrideCatalogPermissions: boolean
  ): Promise<void>;
  CreatePermission(
    automateAppId: number,
    principalId: string,
    type: PermissionType
  ): Promise<IAutomateAppPermission | null>;
  GetPermissions(
    automateAppId: number
  ): Promise<IAutomateAppPermission[] | null>;
  UpdatePermission(
    catalogId: number,
    principalId: string,
    type: PermissionType
  ): Promise<IAutomateAppPermission | null>;
  CheckUserPermissions(
    principalId: string,
    automateAppId: number
  ): Promise<IUserPermission[] | null>;
  DeletePermission(
    catalogId: number,
    principalId: string
  ): Promise<void | null>;
}

export interface AutomateRunReturn {
  [name: string]: any[];
}

export const automateAppServiceContext = createContext<
  IAutomateAppService | undefined
>(undefined);

const AutomateAppService: FC = ({ children }: any) => {
  const apiHttpService = useContext(ApiHttpServiceContext);
  const cacheService = useContext(CacheServiceContext);

  const controller: string = "automateApps";

  const automateAppService: IAutomateAppService = {
    async GetAll(): Promise<IAutomateApp[] | null> {
      return await apiHttpService!.Get<IAutomateApp[]>(`${controller}`);
    },
    async Get(automateAppId: number) {
      return await apiHttpService!.Get<IAutomateApp>(
        `${controller}/${automateAppId}`
      );
    },
    async Delete(automateAppId: number) {
      return await apiHttpService!.Delete<void>(
        `${controller}/${automateAppId}`
      );
    },
    async Create(agentApp: INewAutomateApp) {
      return await apiHttpService!.Post<IAutomateApp>(
        `${controller}`,
        agentApp
      );
    },
    async Update(automateAppId: number, agentApp: IAutomateApp) {
      cacheService!.ClearCache(`${controller}/${agentApp}`);
      return await apiHttpService!.Put<IAutomateApp>(
        `${controller}/${automateAppId}`,
        agentApp
      );
    },
    async PublishAutomateApp(automateAppId: number, publish: boolean) {
      await apiHttpService!.Post<void>(
        `${controller}/${automateAppId}/publish?publish=${publish}`
      );
    },
    async Run(automateAppId: number, formData: FormData) {
      try {
        const flowAccessToken =
          await AuthenticationService.Default.FlowAccessToken(true);
        formData.append("flow_access_token", flowAccessToken!);
      } catch {
        // Do nothing even if it fails
      }
      return await apiHttpService!.Post<AutomateRunReturn>(
        `${controller}/${automateAppId}/run`,
        formData,
        {},
        undefined,
        undefined,
        true
      );
    },
    async OverrideCatalogPermissions(
      automateAppId: number,
      overrideCatalogPermissions: boolean
    ) {
      await apiHttpService!.Post<void>(
        `${controller}/${automateAppId}/overrideCatalogPermissions?overrideCatalogPermissions=${overrideCatalogPermissions}`
      );
    },
    async CreatePermission(
      automateAppId: number,
      principalId: string,
      type: PermissionType
    ) {
      return await apiHttpService!.Post<IAutomateAppPermission>(
        `${controller}/${automateAppId}/permissions`,
        {
          PrincipalId: principalId,
          PermissionType: type,
        }
      );
    },
    async GetPermissions(automateAppId: number) {
      return await apiHttpService!.Get<IAutomateAppPermission[]>(
        `${controller}/${automateAppId}/permissions`
      );
    },
    async DeletePermission(automateAppId: number, principalId: string) {
      await apiHttpService!.Delete<IAutomateAppPermission>(
        `${controller}/${automateAppId}/permissions/${principalId}`
      );
    },
    async CheckUserPermissions(principalId: string, automateAppId: number) {
      return await apiHttpService!.Get<IUserPermission[]>(
        `${controller}/${automateAppId}/permissions/${principalId}`
      );
    },
    async UpdatePermission(
      automateAppId: number,
      principalId: string,
      type: PermissionType
    ) {
      return await apiHttpService!.Put<IAutomateAppPermission>(
        `${controller}/${automateAppId}/permissions`,
        {
          PrincipalId: principalId,
          PermissionType: type,
        }
      );
    },
  };

  return (
    <automateAppServiceContext.Provider value={automateAppService}>
      {children}
    </automateAppServiceContext.Provider>
  );
};

export default AutomateAppService;
