import {
  Callout,
  FontWeights,
  Icon,
  Link,
  Stack,
  StackItem,
  Text,
  mergeStyleSets,
} from '@fluentui/react';
import { FC, useState } from 'react';
import './GroupToggle.scss';
import { useId } from '@fluentui/react-hooks';
import ReactMarkdown from 'react-markdown';

export interface IGroupToggleOption {
  key: string;
  title: string;
  iconName: string;
  description?: string;
  locked?: boolean;
}

export interface IGroupToggleProps {
  activeKey: string;
  options: IGroupToggleOption[];
  onChange: (key: string) => void;
}

const GroupToggle: FC<IGroupToggleProps> = ({ options, activeKey, onChange }) => {
  const [isCalloutVisible, setIsCalloutVisible] = useState<Boolean>(false);
  const [hoveredKey, setHoveredKey] = useState<string>();
  const stackId = useId('group-toggle-stack');

  return (
    <>
      <Stack id={stackId} horizontal className='group-toggle' tokens={{ childrenGap: 4 }}>
        {options.map(option => {
          return (
            <StackItem key={option.key}>
              <Link
                onClick={() => !option.locked && onChange(option.key)}
                onMouseOver={() => {
                  setHoveredKey(option.key);
                  setIsCalloutVisible(true);
                }}
                onMouseLeave={() => setIsCalloutVisible(false)}
                className={activeKey === option.key ? 'active' : ''}
              >
                <Stack horizontal tokens={{ childrenGap: 6 }} verticalAlign='center'>
                  <Icon iconName={option.iconName} />
                  <Text>{option.title}</Text>
                  {option.locked && <Icon iconName='LockSolid' />}
                </Stack>
              </Link>
            </StackItem>
          );
        })}
      </Stack>
      {isCalloutVisible && options.filter(o => o.key === hoveredKey)[0].description && (
        <Callout
          className={styles.callout}
          role='dialog'
          gapSpace={0}
          target={`#${stackId}`}
          setInitialFocus
        >
          <Text as='h1' block variant='xLarge' className={styles.title}>
            {options.filter(o => o.key === hoveredKey)[0].title}
          </Text>
          <Text block variant='small'>
            <ReactMarkdown linkTarget={'_blank'}>
              {options.filter(o => o.key === hoveredKey)[0].description!}
            </ReactMarkdown>
          </Text>
        </Callout>
      )}
    </>
  );
};

const styles = mergeStyleSets({
  button: {
    width: 130,
  },
  callout: {
    width: 320,
    maxWidth: '90%',
    padding: '20px 24px',
    backgroundColor: '#000',
  },
  title: {
    marginBottom: 12,
    fontWeight: FontWeights.semilight,
  },
  link: {
    display: 'block',
    marginTop: 20,
  },
});

export default GroupToggle;
