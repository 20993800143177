import React, { useState } from "react";
import AuthenticationProvider from "../../Services/AuthenticationService";
import { App } from "../../App";

export const Authenticator: React.FunctionComponent = () => {
  const [login, setLogin] = useState<boolean>(false);

  React.useEffect(() => {
    const login = async () => {
      const ret = await AuthenticationProvider.Default.Initialize();
      if (!ret) {
        AuthenticationProvider.Default.LoginRedirect();
      }
      setLogin(ret);
    };
    login();
  }, []);

  return <App loading={!login} />;
};
