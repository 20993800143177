import { FC, useContext, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { PlaygroundStart } from "./Playground/PlaygroundStart";
import { CatalogStart } from "./Catalog/CatalogStart";
import Role from "../../Components/Role/Role";
import { UserServiceContext } from "../../Services/API/UserService";

interface IAppStartProps {
  loading: boolean;
}

const AppStart: FC<IAppStartProps> = ({ loading }) => {
  const userService = useContext(UserServiceContext);
  const [localLoading, setLocalLoading] = useState(true);

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const init = async () => {
    if (!loading) {
      await userService?.GetUserInformation();
      setLocalLoading(false);
    }
  };

  return (
    <>
      <Routes>
        <Route
          path="/*"
          element={
            <Role
              children={<PlaygroundStart loading={loading || localLoading} />}
            />
          }
        />
        <Route
          path="catalogs/:catalogId/*"
          element={
            <Role
              children={<CatalogStart loading={loading || localLoading} />}
            />
          }
        ></Route>
      </Routes>
    </>
  );
};

export { AppStart };
