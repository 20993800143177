import { Pivot, PivotItem, Spinner, SpinnerSize, Stack } from "@fluentui/react";
import { FC, useState } from "react";
import {
  useLocation,
  Navigate,
  Outlet,
  useOutletContext,
} from "react-router-dom";
import IChatApp from "../../../../../Models/API/IChatApp";

export interface IChatAppProps {}

const ChatApp: FC<IChatAppProps> = () => {
  const location = useLocation();
  const { baseApp: chatApp, setBaseApp: setChatApp } = useOutletContext<{
    baseApp: IChatApp;
    setBaseApp: (value: IChatApp) => void;
  }>();

  const [currentKey, setCurrentKey] = useState<string>(
    location.pathname.split("/")[6]
  );

  return (
    <>
      <Stack
        tokens={{ childrenGap: 20 }}
        grow
        verticalFill
        style={{ minHeight: 0 }}
      >
        {chatApp ? (
          <>
            <Stack.Item>
              <Pivot
                aria-label="Chat App Options"
                selectedKey={currentKey}
                onLinkClick={(item) => setCurrentKey(item?.props.itemKey!)}
              >
                {chatApp.PermissionType !== undefined && (
                  <PivotItem itemKey="test" headerText="Test" itemIcon="Chat">
                    <Navigate to="test" replace />
                  </PivotItem>
                )}
                {chatApp.PermissionType !== undefined && (
                  <PivotItem
                    itemKey="libraries"
                    headerText="Libraries"
                    itemIcon="Folder"
                  >
                    <Navigate to="libraries" replace />
                  </PivotItem>
                )}
                {(chatApp.PermissionType === "Write" ||
                  chatApp.PermissionType === "FullControl" ||
                  chatApp.PermissionType === "Owner") && (
                  <PivotItem
                    itemKey="agents"
                    headerText="Agents"
                    itemIcon="ChatBot"
                  >
                    <Navigate to="agents" replace />
                  </PivotItem>
                )}
                {(chatApp.PermissionType === "FullControl" ||
                  chatApp.PermissionType === "Owner") && (
                  <PivotItem
                    itemKey="permissions"
                    headerText="Permissions"
                    itemIcon="Permissions"
                  >
                    <Navigate to="permissions" replace />
                  </PivotItem>
                )}
                {(chatApp.PermissionType === "Write" ||
                  chatApp.PermissionType === "FullControl" ||
                  chatApp.PermissionType === "Owner") && (
                  <PivotItem
                    itemKey="settings"
                    headerText="Settings"
                    itemIcon="Settings"
                  >
                    <Navigate to="settings" replace />
                  </PivotItem>
                )}
              </Pivot>
            </Stack.Item>
            <Stack.Item grow verticalFill style={{ minHeight: 0 }}>
              <Outlet context={{ chatApp, setChatApp }}></Outlet>
            </Stack.Item>
          </>
        ) : (
          <Spinner size={SpinnerSize.large} />
        )}
      </Stack>
    </>
  );
};

export default ChatApp;
