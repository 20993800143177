import {
  PrimaryButton,
  Slider,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
} from "@fluentui/react";
import { FC, useContext, useEffect, useState } from "react";
import { SettingsServiceContext } from "../../../Services/API/SettingsService";
import ISettings from "../../../Models/API/ISettings";
import { DialogServiceContext } from "../../../Services/Dialogs/DialogService";
import { trackPageView } from "../../../Services/AppInsights";

const ChatSettings: FC = () => {
  const settingsService = useContext(SettingsServiceContext);
  const dialogService = useContext(DialogServiceContext);
  const [settings, setSettings] = useState<ISettings | null>(null);
  const [saving, setSaving] = useState<boolean>(false);
  const [isMounted, setIsMounted] = useState<boolean>(true);

  // Track page view
  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const execute = async () => {
      var reply = await settingsService!.Get();
      if (isMounted) setSettings(reply);
    };
    execute();
    return () => {
      setIsMounted(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveSettings = async () => {
    setSaving(true);
    await settingsService?.Save(settings!);
    dialogService?.showSuccessDialog(
      "Settings saved",
      "All settings have been saved."
    );
    setSaving(false);
  };

  return (
    (settings && (
      <>
        <Stack horizontal wrap tokens={{ childrenGap: 20 }}>
          <Stack.Item style={{ maxWidth: 300, width: 300 }}>
            <Slider
              label="Max Prompt Characters"
              max={4000}
              step={100}
              min={0}
              value={settings!.max_prompt_tokens}
              showValue
              onChange={(val) =>
                setSettings({ ...settings, max_prompt_tokens: val })
              }
            />
            <Text variant={"xSmall"} block>
              Maximum number of characters in a single prompt for any chat
              application
            </Text>
          </Stack.Item>
        </Stack>
        <Stack style={{ marginTop: 20 }}>
          <Stack.Item>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <PrimaryButton
                text={saving ? "Saving..." : "Save"}
                readOnly={saving}
                allowDisabledFocus
                onClick={saveSettings}
              />
            </Stack>
          </Stack.Item>
        </Stack>
      </>
    )) || <Spinner size={SpinnerSize.large} />
  );
};

export default ChatSettings;
