import { FC, createContext, useState } from "react";

export interface ICacheService {
  GetCache<T>(key: string, delegate: () => Promise<T | null>): Promise<T>;
  ClearCache(key: string | null): void;
}

export const CacheServiceContext = createContext<ICacheService | undefined>(
  undefined
);

const CacheService: FC = ({ children }: any) => {
  const [cache, setCache] = useState<{ [key: string]: any }>({});
  const [observableCache, setObservableCache] = useState<{
    [key: string]: any;
  }>({});

  const cacheService: ICacheService = {
    async GetCache<T>(key: string, delegate: () => Promise<T | null>) {
      if (cache[key]) {
        return cache[key];
      } else if (observableCache[key] != null) {
        return observableCache[key];
      } else {
        const promise = new Promise<T | null>((resolve, reject) => {
          delegate().then(
            (data) => {
              setCache({
                ...cache,
                [`${key}`]: data,
              });
              resolve(data);
            },
            (error) => {
              setCache((current) => {
                const copy = { ...current };
                delete copy[key];
                return copy;
              });
              setObservableCache((current) => {
                const copy = { ...current };
                delete copy[key];
                return copy;
              });
              reject(error);
            }
          );
        });
        setObservableCache({
          ...observableCache,
          [`${key}`]: promise,
        });
        return promise;
      }
    },
    ClearCache(key: string | null = null) {
      if (key) {
        setCache((current) => {
          const copy = { ...current };
          delete copy[key];
          return copy;
        });
        setObservableCache((current) => {
          const copy = { ...current };
          delete copy[key];
          return copy;
        });
      } else {
        setCache({});
        setObservableCache({});
      }
    },
  };

  return (
    <CacheServiceContext.Provider value={cacheService}>
      {children}
    </CacheServiceContext.Provider>
  );
};

export default CacheService;
