import { FC, createContext, useContext } from "react";
import { ApiHttpServiceContext } from "./ApiHttpService";
import { CacheServiceContext } from "./CacheService";
import ICatalogPermission from "../../Models/API/ICatalogPermission";
import IChatApp from "../../Models/API/IChatApp";
import IAppCatalog from "../../Models/API/IAppCatalog";
import IUserPermission, { PermissionType } from "../../Models/API/IUserPermission";

export interface INewCatalog {
  DisplayName?: string;
  Description?: string;
  BackgroundColor?: string;
  PrimaryColor?: string;
  SecondaryColor?: string;
}

export interface ICatalogService {
  GetAll(): Promise<IAppCatalog[] | null>;
  GetAllVisible(): Promise<IAppCatalog[] | null>;
  Get(catalogId: number): Promise<IAppCatalog | null>;
  GetAllCatalogChats(catalogId: number): Promise<IChatApp[] | null>;
  Delete(catalogId: number): Promise<void | null>;
  Create(catalog: INewCatalog): Promise<IAppCatalog | null>;
  Update(catalogId: number, catalog: INewCatalog): Promise<IAppCatalog | null>;
  GetLogo(appCatalogId: number): Promise<string | null>;
  //UpdateLogo(appCatalogId: number): Promise<void>;
  UpdateLogo(appCatalogId: number, file: File): Promise<void>;
  GetPermissions(catalogId: number): Promise<ICatalogPermission[] | null>;
  CreatePermission(
    catalogId: number,
    principalId: string,
    type: PermissionType
  ): Promise<ICatalogPermission | null>;
  UpdatePermission(
    catalogId: number,
    principalId: string,
    type: PermissionType
  ): Promise<ICatalogPermission | null>;
  CheckUserPermissions(
    principalId: string,
    catalogId: number
  ): Promise<IUserPermission[] | null>;
  DeletePermission(
    catalogId: number,
    principalId: string
  ): Promise<void | null>;
}

export const catalogServiceContext = createContext<ICatalogService | undefined>(
  undefined
);

const CatalogService: FC = ({ children }: any) => {
  const apiHttpService = useContext(ApiHttpServiceContext);
  const cacheService = useContext(CacheServiceContext);

  const controller: string = "catalogs";

  const catalogService: ICatalogService = {
    async GetAll() {
      return await apiHttpService!.Get<IAppCatalog[]>(`${controller}`);
    },
    async GetAllVisible() {
      return await apiHttpService!.Get<IAppCatalog[]>(`${controller}/visible`);
    },
    async Get(catalogId: number) {
      return await apiHttpService!.Get<IAppCatalog>(
        `${controller}/${catalogId}`
      );
    },
    async GetAllCatalogChats(catalogId: number) {
      return await apiHttpService!.Get<IChatApp[]>(
        `${controller}/${catalogId}/apps`
      );
    },
    async Delete(catalogId: number) {
      return await apiHttpService!.Delete<void>(`${controller}/${catalogId}`);
    },
    async Create(catalog: INewCatalog) {
      return await apiHttpService!.Post<IAppCatalog>(`${controller}`, catalog);
    },
    async Update(catalogId: number, catalog: INewCatalog) {
      cacheService!.ClearCache(`${controller}/${catalogId}`);
      return await apiHttpService!.Put<IAppCatalog>(
        `${controller}/${catalogId}`,
        catalog
      );
    },
    async GetPermissions(catalogId: number) {
      return await apiHttpService!.Get<ICatalogPermission[]>(
        `${controller}/${catalogId}/permissions`
      );
    },
    async GetLogo(catalogId: number) {
      return await apiHttpService!.Get<string>(
        `${controller}/${catalogId}/logo`,
        {},
        true,
        true
      );
    },
    async UpdateLogo(catalogId: number, file: File) {
      var formData = new FormData();
      formData.append("Image", file);
      await apiHttpService!.Put<void>(
        `${controller}/${catalogId}/logo`,
        formData
      );
    },
    async CreatePermission(
      catalogId: number,
      principalId: string,
      type: PermissionType
    ) {
      return await apiHttpService!.Post<ICatalogPermission>(
        `${controller}/${catalogId}/permissions`,
        {
          PrincipalId: principalId,
          PermissionType: type,
        }
      );
    },
    async UpdatePermission(
      catalogId: number,
      principalId: string,
      type: PermissionType
    ) {
      return await apiHttpService!.Put<ICatalogPermission>(
        `${controller}/${catalogId}/permissions`,
        {
          PrincipalId: principalId,
          PermissionType: type,
        }
      );
    },
    async DeletePermission(catalogId: number, principalId: string) {
      await apiHttpService!.Delete<ICatalogPermission>(
        `${controller}/${catalogId}/permissions/${principalId}`
      );
    },
    async CheckUserPermissions(principalId: string, catalogId: number) {
      return await apiHttpService!.Get<IUserPermission[]>(
        `${controller}/${catalogId}/permissions/${principalId}`
      );
    },
  };

  return (
    <catalogServiceContext.Provider value={catalogService}>
      {children}
    </catalogServiceContext.Provider>
  );
};

export default CatalogService;
