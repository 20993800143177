import {
  CommandBar,
  DefaultButton,
  DetailsList,
  Dialog,
  DialogFooter,
  DialogType,
  IColumn,
  ICommandBarItemProps,
  Icon,
  Link,
  PrimaryButton,
  SearchBox,
  Selection,
  SelectionMode,
  Spinner,
  SpinnerSize,
  Stack,
  StackItem,
  Text,
  TextField,
} from "@fluentui/react";
import moment from "moment";
import { FC, useContext, useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import ILibrary from "../../../../../../Models/API/ILibrary";
import {
  INewLibrary,
  LibraryServiceContext,
} from "../../../../../../Services/API/LibraryService";
import "./Libraries.scss";
import ConfigurationService from "../../../../../../Services/ConfigurationService";
import { removeDiacritics } from "../../../../../../Utilities/Strings";
import {
  getFileTypeIconProps,
  FileIconType,
} from "@fluentui/react-file-type-icons";
import IChatApp from "../../../../../../Models/API/IChatApp";
import { chatAppServiceContext } from "../../../../../../Services/API/ChatAppService";
import { trackPageView } from "../../../../../../Services/AppInsights";

const shimmerColumns: IColumn[] = [
  {
    key: "Icon",
    name: "",
    minWidth: 20,
    maxWidth: 20,
  },
  {
    key: "Title",
    name: "Title",
    minWidth: 200,
  },
  {
    key: "NumDocuments",
    name: "# of documents",
    minWidth: 200,
  },
  {
    key: "OwnerName",
    name: "Owner",
    minWidth: 100,
  },
  {
    key: "Created",
    name: "Created",
    minWidth: 100,
  },
];

const defaultNewLib: INewLibrary = {
  Title: "",
  Description: "",
  Public: false,
  Visible: false,
  Metadata: {
    Fields: [],
  },
};

const Libraries: FC = () => {
  const navigate = useNavigate();
  const librariesService = useContext(LibraryServiceContext);
  const chatAppService = useContext(chatAppServiceContext);
  const { chatApp } = useOutletContext<{
    chatApp: IChatApp;
  }>();

  const [libraries, setLibraries] = useState<ILibrary[] | null>(null);
  const [filteredLibraries, setFilteredLibraries] = useState<
    ILibrary[] | null | undefined
  >(undefined);
  const [selectedLibraries, setSelectedLibraries] = useState<ILibrary[]>([]);
  const [commandBarButtons, setCommandBarButtons] = useState<
    ICommandBarItemProps[]
  >([]);
  const [hideDeleteDialog, setHideDeleteDialog] = useState<boolean>(true);
  const [hideNewDialog, setHideNewDialog] = useState<boolean>(true);
  const [filterKeyword, setFilterKeyword] = useState<string | undefined>(
    undefined
  );
  const [newLibrary, setNewLibrary] = useState<INewLibrary>(defaultNewLib);
  const [isMounted, setIsMounted] = useState<boolean>(true);
  const [creatingLibrary, setCreatingLibrary] = useState<boolean>(false);

  // Track page view
  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRenderItemColumn = (
    item: ILibrary,
    index?: number,
    column?: IColumn
  ): any => {
    if (column?.key === "Icon") {
      return (
        <Icon
          {...getFileTypeIconProps({
            type: FileIconType.folder,
            size: 20,
            imageFileType: "svg",
          })}
        />
      );
    } else if (column?.key === "Created") {
      return moment(item.Created).format("DD MMM yyyy");
    } else if (column?.key === "Title") {
      return (
        <Link onClick={() => navigateSomehwere(item.Id)}>{item.Title}</Link>
      );
    } else {
      return item![column?.key as keyof ILibrary];
    }
  };

  const navigateSomehwere = (libraryId: number) => {
    navigate(libraryId.toString(), {
      relative: "route",
    });
  };

  const reloadLibraries = async () => {
    setLibraries([]);
    setFilteredLibraries(undefined);
    let libs = await chatAppService!.GetChatAppLibraries(chatApp.Id);
    if (libs) {
      libs = libs!.sort((libA, libB) => libA.Title.localeCompare(libB.Title));
      setFilteredLibraries(libs);
      setLibraries(libs);
    }
  };

  useEffect(() => {
    document.title = `${ConfigurationService.Default.Configuration.PageTitle} - ${chatApp.DisplayName} Libraries`;
    if (isMounted && chatApp) reloadLibraries();
    return () => {
      setIsMounted(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatApp]);

  // Runs when the selected libraries change
  useEffect(() => {
    let barButtons: ICommandBarItemProps[] = [];

    if (chatApp.PermissionType && chatApp.PermissionType !== "Read") {
      barButtons = [
        ...barButtons,
        {
          key: "new",
          text: "New",
          iconProps: { iconName: "Add" },
          subMenuProps: {
            items: [
              {
                key: "library",
                text: "Library",
                iconProps: { iconName: "Folder" },
                onClick: (event, item) => {
                  setHideNewDialog(false);
                },
              },
            ],
          },
        },
      ];
    }
    barButtons = [
      ...barButtons,
      {
        key: "delete",
        text: "Delete",
        iconProps: { iconName: "Delete" },
        onClick: (event, item) => {
          setHideDeleteDialog(false);
        },
        disabled: selectedLibraries.length === 0,
      },
      {
        key: "refresh",
        text: "Refresh",
        iconProps: { iconName: "Refresh" },
        onClick: (event, item) => {
          resetState();
          reloadLibraries();
        },
      },
    ];
    setCommandBarButtons(barButtons);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLibraries]);

  const canSelectLibrary = (item: any, index?: number): boolean => {
    return (item as ILibrary).PermissionType === "Owner";
  };

  const selection = new Selection({
    canSelectItem: canSelectLibrary,
    onSelectionChanged: () => {
      setSelectedLibraries(selection.getSelection() as ILibrary[]);
    },
  });

  const resetState = (): void => {
    setHideDeleteDialog(true);
    setHideNewDialog(true);
    setLibraries([]);
    setFilteredLibraries(undefined);
    setSelectedLibraries([]);
    setFilterKeyword(undefined);
    setNewLibrary(defaultNewLib);
  };

  const deleteLibraries = async (): Promise<void> => {
    const promises: (Promise<void | null> | undefined)[] = [];
    const selectedLibs = [...selectedLibraries];
    resetState();
    for (let i = 0; i < selectedLibs.length; i++) {
      const lib = selectedLibs[i];
      promises.push(librariesService?.Delete(lib.Id));
    }
    await Promise.allSettled(promises);
    reloadLibraries();
  };

  const createLibrary = async (): Promise<void> => {
    if (!creatingLibrary) {
      setCreatingLibrary(true);
      const newLib = await chatAppService?.CreateChatAppLibrary(
        chatApp.Id,
        newLibrary
      );
      if (newLib != null) {
        navigate(`${newLib.Id}`);
      } else {
        setCreatingLibrary(false);
        reloadLibraries();
        resetState();
      }
    }
  };

  // Runs when the filter keyword changes
  useEffect(() => {
    if (libraries && filterKeyword !== undefined) {
      setFilteredLibraries(
        filterKeyword
          ? libraries?.filter(
              (lib) =>
                removeDiacritics(lib.Title.toLowerCase()).indexOf(
                  removeDiacritics(filterKeyword.toLowerCase())
                ) !== -1
            )
          : libraries
      );
    }
  }, [filterKeyword, libraries]);

  return (
    <>
      <Stack verticalFill style={{ minHeight: 0 }}>
        <Stack.Item>
          <CommandBar items={commandBarButtons} ariaLabel="Library actions" />
          <SearchBox
            value={filterKeyword}
            onChange={(evt, newValue) => setFilterKeyword(newValue)}
            placeholder="Filter by title"
            iconProps={{ iconName: "Filter" }}
            underlined={true}
          />
        </Stack.Item>
        <Stack.Item verticalFill style={{ minHeight: 0, overflowY: "auto" }}>
          <DetailsList
            className="libraries-list"
            setKey="items"
            items={filteredLibraries || []}
            columns={shimmerColumns}
            selection={selection}
            selectionMode={SelectionMode.multiple}
            onRenderItemColumn={onRenderItemColumn}
            ariaLabelForGrid="Item details"
            listProps={{ renderedWindowsAhead: 0, renderedWindowsBehind: 0 }}
            styles={{ root: { verticalAlign: "middle" } }}
          />
          {(filteredLibraries === undefined ||
            filteredLibraries?.length === 0) && (
            <>
              {filteredLibraries?.length === 0 && (
                <Text
                  variant="large"
                  block
                  style={{
                    textAlign: "center",
                    color: "rgba(255,255,255,0.3)",
                  }}
                >
                  No libraries found
                </Text>
              )}
              {filteredLibraries === undefined && (
                <Spinner size={SpinnerSize.large} />
              )}
            </>
          )}
        </Stack.Item>
      </Stack>
      <Dialog
        hidden={hideDeleteDialog}
        onDismiss={() => setHideDeleteDialog(true)}
        modalProps={{ isBlocking: true, styles: { main: { maxWidth: 450 } } }}
        dialogContentProps={{
          type: DialogType.normal,
          title: "Delete Libraries",
          subText: "Are you sure you want to delete the following libraries?",
        }}
      >
        {selectedLibraries.map((lib) => {
          return (
            <Text key={lib.Id} block>
              {lib.Title}
            </Text>
          );
        })}
        <DialogFooter>
          <PrimaryButton onClick={deleteLibraries} text="Delete" />
          <DefaultButton
            onClick={() => setHideDeleteDialog(true)}
            text="Cancel"
          />
        </DialogFooter>
      </Dialog>
      <Dialog
        hidden={hideNewDialog}
        onDismiss={() => setHideNewDialog(true)}
        dialogContentProps={{
          title: "New Library",
          subText: "Create a new library for your documents",
        }}
        modalProps={{
          isBlocking: true,
        }}
      >
        <Stack tokens={{ childrenGap: 20 }}>
          <Stack.Item>
            <TextField
              required={true}
              label="Title"
              value={newLibrary.Title}
              onChange={(event, newValue) =>
                setNewLibrary({
                  ...newLibrary,
                  Title: newValue,
                })
              }
            />
            <Text variant="xSmall">Minimum 3 characters</Text>
          </Stack.Item>
          <StackItem>
            <TextField
              label="Description"
              multiline
              rows={3}
              resizable={false}
              value={newLibrary.Description}
              onChange={(event, newValue) =>
                setNewLibrary({
                  ...newLibrary,
                  Description: newValue,
                })
              }
            />
          </StackItem>
          <Stack.Item>
            <DefaultButton
              onClick={() => navigate("new", { relative: "route" })}
            >
              Advanced Settings
            </DefaultButton>
            <Text style={{ marginTop: 5 }} variant={"xSmall"} block>
              If you need to extend the metadata associated with your library
              (e.g. adding additional fields to documents).
            </Text>
          </Stack.Item>
        </Stack>
        <DialogFooter>
          <PrimaryButton
            style={{ minWidth: 80 }}
            onClick={createLibrary}
            disabled={!newLibrary.Title || newLibrary.Title.length < 3}
            text={creatingLibrary ? "" : "Create"}
          >
            {creatingLibrary && <Spinner size={SpinnerSize.small} />}
          </PrimaryButton>
          <DefaultButton onClick={() => setHideNewDialog(true)} text="Cancel" />
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default Libraries;
