import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Versions from "./Pages/Versions/Versions";
import Role from "./Components/Role/Role";
import Chat from "./Pages/Chat/Chat";
import ChatSettings from "./Pages/Settings/Chat/Chat";
import PromptSettings from "./Pages/Settings/Prompts/Prompts";
import TrainingSettings from "./Pages/Settings/Training/Training";
import Library from "./Pages/CatalogsEditor/Catalog/Applications/ChatApps/Libraries/Library/Library";
import LibrarySettings from "./Pages/CatalogsEditor/Catalog/Applications/ChatApps/Libraries/Library/Settings/Settings";
import Settings from "./Pages/Settings/Settings";
import LibraryDocuments from "./Pages/CatalogsEditor/Catalog/Applications/ChatApps/Libraries/Library/Documents/Documents";
import Libraries from "./Pages/CatalogsEditor/Catalog/Applications/ChatApps/Libraries/Libraries";
import LibraryPermissions from "./Pages/CatalogsEditor/Catalog/Applications/ChatApps/Libraries/Library/Permissions/Permissions";
import NewLibrary from "./Pages/CatalogsEditor/Catalog/Applications/ChatApps/Libraries/New Library/New Library";
import LibraryMetadata from "./Pages/CatalogsEditor/Catalog/Applications/ChatApps/Libraries/Library/Metadata/Metadata";
import Catalogs from "./Pages/CatalogsEditor/Catalogs";
import NewCatalog from "./Pages/CatalogsEditor/NewCatalog/NewCatalog";
import Catalog from "./Pages/CatalogsEditor/Catalog/Catalog";
import CatalogSettings from "./Pages/CatalogsEditor/Catalog/Settings/Settings";
import NewChatApp from "./Pages/CatalogsEditor/Catalog/Applications/ChatApps/NewChatApp";
import CatalogOptions from "./Pages/CatalogsEditor/Catalog/CatalogOptions";
import ChatApp from "./Pages/CatalogsEditor/Catalog/Applications/ChatApps/ChatApps";
import CatalogPermissions from "./Pages/CatalogsEditor/Catalog/Permissions/Permissions";
import ChatAppSettings from "./Pages/CatalogsEditor/Catalog/Applications/ChatApps/Settings/Settings";
import ChatAppPermissions from "./Pages/CatalogsEditor/Catalog/Applications/ChatApps/Permissions/Permissions";
import ChatAppTest from "./Pages/CatalogsEditor/Catalog/Applications/ChatApps/Test/Test";
import CatalogsView from "./Pages/Catalogs/CatalogsView";
import CatalogApplications from "./Pages/CatalogsEditor/Catalog/Applications/CatalogApplications";
import NewAgentApp from "./Pages/CatalogsEditor/Catalog/Applications/AgentApps/NewAgentApp";
import AgentApps from "./Pages/CatalogsEditor/Catalog/Applications/AgentApps/AgentApps";
import AgentAppSettings from "./Pages/CatalogsEditor/Catalog/Applications/AgentApps/Settings/Settings";
import AgentAppPermissions from "./Pages/CatalogsEditor/Catalog/Applications/AgentApps/Permissions/Permissions";
import AgentFunctions from "./Pages/CatalogsEditor/Catalog/Applications/AgentApps/Functions/Functions";
import ChatJtiSettings from "./Pages/Settings/ChatJti/ChatJti";
import ChatAppsAgents from "./Pages/CatalogsEditor/Catalog/Applications/ChatApps/Agents/Agents";
import AutomateOutputParams from "./Pages/CatalogsEditor/Catalog/Applications/Automate/OutputParams/OutputParams";
import AutomateInputParams from "./Pages/CatalogsEditor/Catalog/Applications/Automate/InputParams/InputParams";
import AutomateTest from "./Pages/CatalogsEditor/Catalog/Applications/Automate/Test/AutomateTest";
import AutomateIntegration from "./Pages/CatalogsEditor/Catalog/Applications/Automate/Integration/Integration";
import NewAutomateApp from "./Pages/CatalogsEditor/Catalog/Applications/Automate/NewAutomateApp";
import AutomateAppSettings from "./Pages/CatalogsEditor/Catalog/Applications/Automate/Settings/Settings";
import AutomateApps from "./Pages/CatalogsEditor/Catalog/Applications/Automate/AutomateApps";
import AutomateAppPermissions from "./Pages/CatalogsEditor/Catalog/Applications/Automate/Permissions/Permissions";
import { AccessDenied } from "./Pages/AccessDenied/AccessDenied";
import ApiReference from "./Pages/ApiReference/ApiReference";
import Method from "./Pages/ApiReference/Method/Method";
import Default from "./Pages/ApiReference/Default/Default";

export default class Router extends React.Component {
  render() {
    return (
      <Routes>
        <Route
          index
          element={<Role children={<Navigate to="chat" replace />} />}
        ></Route>
        <Route
          path="accessdenied"
          element={<Role children={<AccessDenied />} />}
        ></Route>
        <Route path="chat" element={<Role children={<Chat />} />}></Route>
        <Route
          path="catalogs"
          element={<Role children={<CatalogsView />} />}
        ></Route>
        <Route path="catalogseditor">
          <Route index element={<Role children={<Catalogs />} />} />
          <Route
            path="new"
            element={
              <Role allowedRoles={["admin"]} children={<NewCatalog />} />
            }
          ></Route>
          <Route path=":catalogId" element={<Role children={<Catalog />} />}>
            <Route
              index
              element={
                <Role>
                  <Navigate to="apps" replace />
                </Role>
              }
            ></Route>

            <Route
              path="apps/chat/:appId/libraries/new"
              element={<Role children={<NewLibrary />} />}
            ></Route>

            <Route
              path="apps/chat/:appId/libraries/:libraryId"
              element={<Role children={<Library />} />}
            >
              <Route
                index
                element={
                  <Role>
                    <Navigate to="documents" replace />
                  </Role>
                }
              ></Route>
              <Route
                path="documents"
                element={<Role children={<LibraryDocuments />} />}
              ></Route>
              <Route
                path="permissions"
                element={<Role children={<LibraryPermissions />} />}
              ></Route>
              <Route
                path="metadata"
                element={<Role children={<LibraryMetadata />} />}
              ></Route>
              <Route
                path="settings"
                element={<Role children={<LibrarySettings />} />}
              ></Route>
            </Route>

            <Route path="apps/chat">
              <Route
                index
                element={
                  <Role>
                    <Navigate to="../" replace />
                  </Role>
                }
              ></Route>
              <Route
                path="new"
                element={<Role children={<NewChatApp />} />}
              ></Route>
              <Route path=":appId" element={<Role children={<ChatApp />} />}>
                <Route
                  path="settings"
                  element={<Role children={<ChatAppSettings />} />}
                ></Route>
                <Route
                  path="permissions"
                  element={<Role children={<ChatAppPermissions />} />}
                ></Route>
                <Route
                  path="test"
                  element={<Role children={<ChatAppTest />} />}
                ></Route>
                <Route
                  path="agents"
                  element={<Role children={<ChatAppsAgents />} />}
                ></Route>
                <Route
                  path="libraries"
                  element={<Role children={<Libraries />} />}
                />
              </Route>
            </Route>
            <Route path="apps/agent">
              <Route
                path="new"
                element={<Role children={<NewAgentApp />} />}
              ></Route>
              <Route path=":appId" element={<Role children={<AgentApps />} />}>
                <Route
                  path="settings"
                  element={<Role children={<AgentAppSettings />} />}
                ></Route>
                <Route
                  path="permissions"
                  element={<Role children={<AgentAppPermissions />} />}
                ></Route>
                <Route
                  path="functions"
                  element={<Role children={<AgentFunctions />} />}
                ></Route>
              </Route>
            </Route>
            <Route path="apps/automate">
              <Route
                path="new"
                element={<Role children={<NewAutomateApp />} />}
              ></Route>
              <Route
                path=":appId"
                element={<Role children={<AutomateApps />} />}
              >
                <Route
                  path="settings"
                  element={<Role children={<AutomateAppSettings />} />}
                ></Route>
                <Route
                  path="permissions"
                  element={<Role children={<AutomateAppPermissions />} />}
                ></Route>
                <Route
                  path="input"
                  element={<Role children={<AutomateInputParams />} />}
                ></Route>
                <Route
                  path="output"
                  element={<Role children={<AutomateOutputParams />} />}
                ></Route>
                <Route
                  path="integration"
                  element={<Role children={<AutomateIntegration />} />}
                ></Route>
                <Route
                  path="test"
                  element={<Role children={<AutomateTest />} />}
                ></Route>
              </Route>
            </Route>

            <Route path="" element={<Role children={<CatalogOptions />} />}>
              <Route
                path="apps"
                element={<Role children={<CatalogApplications />} />}
              ></Route>
              <Route
                path="permissions"
                element={<Role children={<CatalogPermissions />} />}
              ></Route>
              <Route
                path="settings"
                element={<Role children={<CatalogSettings />} />}
              ></Route>
            </Route>
          </Route>
        </Route>
        <Route
          path="settings"
          element={<Role children={<Settings />} allowedRoles={["admin"]} />}
        >
          <Route
            index
            element={
              <Role
                allowedRoles={["admin"]}
                children={<Navigate to="chat" replace />}
              />
            }
          ></Route>
          <Route
            path="chat"
            element={
              <Role allowedRoles={["admin"]} children={<ChatSettings />} />
            }
          ></Route>
          <Route
            path="chatjti"
            element={
              <Role allowedRoles={["admin"]} children={<ChatJtiSettings />} />
            }
          ></Route>
          <Route
            path="prompts"
            element={
              <Role allowedRoles={["admin"]} children={<PromptSettings />} />
            }
          ></Route>
          <Route
            path="training"
            element={
              <Role allowedRoles={["admin"]} children={<TrainingSettings />} />
            }
          ></Route>
        </Route>
        <Route
          path="versions"
          element={<Role children={<Versions />} />}
        ></Route>
        <Route
          path="api-reference"
          element={<Role children={<ApiReference />} />}
        >
          <Route index element={<Role children={<Default />} />}></Route>
          <Route path=":path" element={<Role children={<Method />} />}></Route>
        </Route>
      </Routes>
    );
  }
}
