import { FC, createContext, useContext } from "react";
import { ApiHttpServiceContext } from "./ApiHttpService";
import IUserInformation from "../../Models/API/IUserInformation";
import IUserPermission, {
  ResourceType,
} from "../../Models/API/IUserPermission";
import IUserDocument from "../../Models/API/IUserDocument";
import { AxiosProgressEvent, CancelToken } from "axios";

export interface IUserService {
  GetUserInformation(): Promise<IUserInformation | null>;
  GetUserPermissions(
    principalId: string,
    resourceType: ResourceType,
    resourceId: number
  ): Promise<IUserPermission[] | null>;
  GetUserDocuments(): Promise<IUserDocument[] | null>;
  UploadUserDocument(
    file: File,
    cancelToken?: CancelToken | undefined,
    onUploadProgress?: ((progressEvent: AxiosProgressEvent) => void) | undefined
  ): Promise<IUserDocument | null>;
  DeleteUserDocument(documentId: number): Promise<void | null>;
}

export const UserServiceContext = createContext<IUserService | undefined>(
  undefined
);

const UserService: FC = ({ children }: any) => {
  const apiHttpService = useContext(ApiHttpServiceContext);

  const userService: IUserService = {
    async GetUserInformation() {
      return await apiHttpService!.Get<IUserInformation>(
        `users/me`,
        {},
        true,
        false
      );
    },
    async GetUserPermissions(
      principalId: string,
      resourceType: ResourceType,
      resourceId: number
    ) {
      return await apiHttpService!.Post<IUserPermission[]>(
        `users/permissions`,
        {
          PrincipalId: principalId,
          ResourceType: resourceType,
          ResourceId: resourceId,
        }
      );
    },
    async GetUserDocuments() {
      return await apiHttpService!.Get<IUserDocument[]>(`users/documents`);
    },
    async UploadUserDocument(
      file: File,
      cancelToken: CancelToken | undefined = undefined,
      onUploadProgress:
        | ((progressEvent: AxiosProgressEvent) => void)
        | undefined = undefined
    ) {
      var formData = new FormData();
      formData.append("file", file);
      return await apiHttpService!.Post<IUserDocument>(
        `users/documents`,
        formData,
        {},
        cancelToken,
        onUploadProgress,
        true
      );
    },
    async DeleteUserDocument(documentId: number) {
      await apiHttpService!.Delete<void>(`users/documents/${documentId}`);
    },
  };

  return (
    <UserServiceContext.Provider value={userService}>
      {children}
    </UserServiceContext.Provider>
  );
};

export default UserService;
