import { FC, useContext, useEffect, useState } from "react";
import {
  Stack,
  Nav,
  INavLink,
  INavLinkGroup,
  INavStyles,
  Spinner,
  SpinnerSize,
  Panel,
} from "@fluentui/react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { catalogServiceContext } from "../../Services/API/CatalogService";
import IAppCatalog from "../../Models/API/IAppCatalog";
import { AppCatalogServiceContext } from "../../Services/AppCatalogService";
import { AppType } from "../../Models/API/AppType";

const navStyles: Partial<INavStyles> = {
  root: {
    width: 208,
    boxSizing: "border-box",
    overflowY: "auto",
  },
};

export interface ICatalogViewProps {
  isOpen: boolean;
  dismissPanel(): void;
}

const Catalog: FC<ICatalogViewProps> = ({ isOpen, dismissPanel }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const catalogService = useContext(catalogServiceContext);
  const appCatalogService = useContext(AppCatalogServiceContext);

  let { catalogId, appId } = useParams();

  const route = `${location.pathname.split("/")[3]}/${
    location.pathname.split("/")[4]
  }`;

  const [navApplications, setNavApplications] = useState<INavLinkGroup[]>([]);
  const [appCatalog, setAppCatalog] = useState<
    IAppCatalog | null | undefined
  >();
  const [loading, setLoading] = useState<boolean>();

  const _onLinkClick = (
    ev?: React.MouseEvent<HTMLElement>,
    item?: INavLink
  ) => {
    ev?.preventDefault();

    navigate(item?.url!, {
      relative: "route",
    });
  };

  const getAppCatalog = async () => {
    if (appCatalog) {
      return appCatalog;
    }

    const appCat = await catalogService?.Get(Number(catalogId));

    if (appCat) {
      setAppCatalog(appCat);
      let appLogo = undefined;
      if (appCat.LogoUrl) {
        appLogo = await catalogService?.GetLogo(Number(catalogId));
      }

      await appCatalogService?.ChangeTheme({
        title: appCat.DisplayName,
        logoBase64: appLogo,
        backgroundColor: appCat.BackgroundColor,
        primaryColor: appCat.PrimaryColor,
        secondaryColor: appCat.SecondaryColor,
        subtitle: "",
      });
    }

    return appCat;
  };

  const getAppCatalogApplications = async (catalogId: number) => {
    const catApps = await catalogService?.GetAllCatalogChats(catalogId);
    if (catApps) {
      var filteredApps = catApps.filter((x) => x.Type !== AppType.AgentApp);
      filteredApps.sort((a, b) => a.DisplayName.localeCompare(b.DisplayName));

      const truncateDescription = (description: string) => {
        if (description.length > 100) {
          return `${description.substring(0, 100)}...`;
        }
        return description;
      };

      setNavApplications([
        {
          links: filteredApps.map((x) => {
            return {
              name: x.DisplayName,
              url: `${x.Type.toLowerCase()}/${x.Id.toString()}`,
              key: `${x.Type.toLowerCase()}/${x.Id.toString()}`,
              title: truncateDescription(x.Description),
            };
          }),
        },
      ]);
      return filteredApps;
    }
    return null;
  };

  useEffect(() => {
    const execute = async () => {
      setLoading(true);
      const appCat = await getAppCatalog();
      let appCatApps = await getAppCatalogApplications(appCat!.Id);
      if (appCatApps) {
        appCatApps = appCatApps.filter((app) => app.Type !== AppType.AgentApp);
        if (appCatApps && appCatApps.length > 0) {
          var foundApp = appId
            ? appCatApps.filter((app) => app.Id === Number(appId))[0]
            : appCatApps[0];
          if (foundApp) {
            navigate(`${foundApp.Type.toLowerCase()}/${foundApp.Id}`, {
              relative: "route",
            });
          }
        }
      }
      setLoading(false);
    };
    if (catalogId) {
      execute();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogId]);

  const getNavigation = () => {
    return (
      <Nav
        onLinkClick={_onLinkClick}
        selectedKey={route}
        ariaLabel="Nav basic example"
        styles={navStyles}
        groups={navApplications}
      />
    );
  };

  useEffect(() => {
    return () => {
      appCatalogService?.SetDefaultTheme();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!loading ? (
        <Stack
          grow
          verticalFill
          style={{ minHeight: 0 }}
          horizontalAlign="center"
        >
          <Stack
            verticalFill
            grow
            style={{ minHeight: 0, width: "100%" }}
            tokens={{
              childrenGap: 20,
            }}
          >
            <Stack horizontal verticalFill>
              <Stack.Item shrink className="hideInMobile">
                {getNavigation()}
              </Stack.Item>
              <Stack.Item grow style={{ padding: 10, overflowY: "scroll" }}>
                <Outlet context={{ appCatalog }} />
              </Stack.Item>
            </Stack>
          </Stack>
        </Stack>
      ) : (
        <Spinner size={SpinnerSize.large} />
      )}
      <Panel
        isLightDismiss
        isOpen={isOpen}
        onDismiss={dismissPanel}
        closeButtonAriaLabel="Close"
      >
        <Stack>
          <Stack.Item style={{ marginTop: 20 }}>{getNavigation()}</Stack.Item>
        </Stack>
      </Panel>
    </>
  );
};

export { Catalog };
