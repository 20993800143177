import { FC, useEffect, useContext, useState } from 'react';
import ConfigurationService from '../../Services/ConfigurationService';
import { Spinner, SpinnerSize, Stack } from '@fluentui/react';
import './AccessDenied.scss';
import { SettingsServiceContext } from '../../Services/API/SettingsService';
import ReactMarkdown from 'react-markdown';

const AccessDenied: FC = () => {
  const settingsProvider = useContext(SettingsServiceContext);

  const [text, setText] = useState<string>();

  useEffect(() => {
    document.title = `${ConfigurationService.Default.Configuration.PageTitle} - Access Denied`;
    const execute = async () => {
      var settings = await settingsProvider!.Get();
      setText(settings!.no_access_message);
    };
    execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack
      className='gpt-versions'
      verticalAlign='center'
      grow
      style={{ minHeight: 0 }}
      tokens={{ childrenGap: 20, padding: 20 }}
    >
      <Stack.Item style={{ overflowY: 'scroll' }}>
        {(text && <ReactMarkdown linkTarget={'_blank'}>{text!}</ReactMarkdown>) || (
          <Spinner size={SpinnerSize.large} />
        )}
      </Stack.Item>
    </Stack>
  );
};

export { AccessDenied };
