import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import ConfigurationService from "./ConfigurationService";

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: `InstrumentationKey=${ConfigurationService.Default.Configuration.InstrumentationKey}`,
    extensions: [reactPlugin],
  },
});

appInsights.loadAppInsights();

const trackPageView = () => {
  appInsights.trackPageView({
    startTime: new Date(),
    name: window.location.pathname,
  });
};

export { reactPlugin, appInsights, trackPageView };
