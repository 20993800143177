import {
  Stack,
  TextField,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Text,
  ActionButton,
  IIconProps,
} from "@fluentui/react";
import { FC, useContext, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import IAutomateApp from "../../../../../../Models/API/IAutomateApp";
import { automateAppServiceContext } from "../../../../../../Services/API/AutomateAppService";
import styles from "./Integration.module.scss";
import { trackPageView } from "../../../../../../Services/AppInsights";
import ConfigurationService from "../../../../../../Services/ConfigurationService";

const copyClipboardIcon: IIconProps = { iconName: "ClipboardSolid" };

export interface IAutomateIntegrationProps {}

const AutomateIntegration: FC<IAutomateIntegrationProps> = () => {
  var automateAppService = useContext(automateAppServiceContext);

  const { automateApp, setAutomateApp } = useOutletContext<{
    automateApp: IAutomateApp;
    setAutomateApp: (value: IAutomateApp) => void;
  }>();

  const [tempAutomateApp, setTempAutomateApp] =
    useState<IAutomateApp>(automateApp);
  const [saving, setIsSaving] = useState<boolean>(false);

  // Track page view
  useEffect(() => {
    document.title = `${ConfigurationService.Default.Configuration.PageTitle} - ${automateApp.DisplayName} Integration`;
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const copyToClipboardAction = (value: string) => {
    navigator.clipboard.writeText(value);
  };

  const updateAutomateApp = async () => {
    if (tempAutomateApp && !saving) {
      setIsSaving(true);
      var refreshedAutomateApp = await automateAppService?.Update(
        tempAutomateApp!.Id,
        {
          ...tempAutomateApp,
          ActionUrl: tempAutomateApp.ActionUrl,
        }
      );
      if (refreshedAutomateApp !== null) {
        setAutomateApp(refreshedAutomateApp!);
        setTempAutomateApp(refreshedAutomateApp!);
      }
      setIsSaving(false);
    }
  };

  return (
    (tempAutomateApp && (
      <>
        <Stack
          verticalFill
          style={{ minHeight: 0, overflowY: "auto" }}
          tokens={{ childrenGap: 20 }}
        >
          <Stack.Item>
            <TextField
              multiline
              rows={2}
              label="Action Url"
              resizable={false}
              value={tempAutomateApp.ActionUrl}
              onChange={(event, newValue) =>
                setTempAutomateApp({
                  ...tempAutomateApp,
                  ActionUrl: newValue!,
                })
              }
            />
          </Stack.Item>
          <Stack.Item>
            <div className={styles.copyClipboardSection}>
              <Text variant={"large"}>Input parameters</Text>
              <ActionButton
                iconProps={copyClipboardIcon}
                allowDisabledFocus
                onClick={() =>
                  copyToClipboardAction(tempAutomateApp.RequestJsonSchema)
                }
              >
                Copy to clipboard
              </ActionButton>
            </div>

            <TextField
              multiline
              rows={6}
              resizable={false}
              value={tempAutomateApp.RequestJsonSchema}
            />
          </Stack.Item>
          <Stack.Item>
            <div className={styles.copyClipboardSection}>
              <Text variant={"large"}>Output parameters</Text>
              <ActionButton
                iconProps={copyClipboardIcon}
                allowDisabledFocus
                onClick={() =>
                  copyToClipboardAction(tempAutomateApp.ResponseJsonExample)
                }
              >
                Copy to clipboard
              </ActionButton>
            </div>

            <TextField
              multiline
              rows={6}
              resizable={false}
              value={tempAutomateApp.ResponseJsonExample}
            />
          </Stack.Item>

          <Stack.Item>
            <section className={styles.buttonSection}>
              <PrimaryButton
                style={{ minWidth: 80 }}
                onClick={updateAutomateApp}
                disabled={!tempAutomateApp.ActionUrl}
                text={saving ? "" : "Save"}
              >
                {saving && (
                  <Spinner hidden={!saving} size={SpinnerSize.small} />
                )}
              </PrimaryButton>
            </section>
          </Stack.Item>
        </Stack>
      </>
    )) || <Spinner size={SpinnerSize.large} />
  );
};

export default AutomateIntegration;
