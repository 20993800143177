import { FC, useContext, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import {
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
  TextField,
} from "@fluentui/react";
import IAutomateApp from "../../../../../../Models/API/IAutomateApp";
import styles from "./Settings.module.scss";
import { automateAppServiceContext } from "../../../../../../Services/API/AutomateAppService";
import { trackPageView } from "../../../../../../Services/AppInsights";
import { DialogServiceContext } from "../../../../../../Services/Dialogs/DialogService";
import ConfigurationService from "../../../../../../Services/ConfigurationService";

const AutomateAppSettings: FC = () => {
  const dialogService = useContext(DialogServiceContext);
  var automateAppService = useContext(automateAppServiceContext);

  const { automateApp, setAutomateApp } = useOutletContext<{
    automateApp: IAutomateApp;
    setAutomateApp: (value: IAutomateApp) => void;
  }>();

  const [tempAutomateApp, setTempAutomateApp] =
    useState<IAutomateApp>(automateApp);
  const [saving, setIsSaving] = useState<boolean>(false);

  // Track page view
  useEffect(() => {
    document.title = `${ConfigurationService.Default.Configuration.PageTitle} - ${automateApp.DisplayName} Settings`;
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateAutomateApp = async () => {
    if (tempAutomateApp && !saving) {
      setIsSaving(true);
      var refreshedChatApp = await automateAppService?.Update(
        tempAutomateApp!.Id,
        {
          ...automateApp,
          DisplayName: tempAutomateApp.DisplayName,
          Description: tempAutomateApp.Description,
        }
      );
      if (refreshedChatApp !== null) {
        setAutomateApp(refreshedChatApp!);
        setTempAutomateApp(refreshedChatApp!);
        dialogService?.showSuccessDialog(
          refreshedChatApp!.DisplayName,
          "All settings have been saved."
        );
      }
      setIsSaving(false);
    }
  };

  return (
    (tempAutomateApp && (
      <>
        <Stack
          verticalFill
          style={{ minHeight: 0, overflowY: "auto" }}
          tokens={{ childrenGap: 20 }}
        >
          <Stack.Item>
            <TextField
              required={true}
              label="Display Name"
              resizable={false}
              value={tempAutomateApp.DisplayName}
              onChange={(event, newValue) =>
                setTempAutomateApp({
                  ...tempAutomateApp,
                  DisplayName: newValue!,
                })
              }
            />
            <Text variant="xSmall">Minimum 3 characters</Text>
          </Stack.Item>
          <Stack.Item>
            <TextField
              required={true}
              label="Description"
              multiline
              rows={3}
              resizable={false}
              value={tempAutomateApp.Description}
              onChange={(event, newValue) =>
                setTempAutomateApp({
                  ...tempAutomateApp,
                  Description: newValue!,
                })
              }
            />
            <Text variant="xSmall">
              Minimum 20 characters and maximum 1000 characters
            </Text>
          </Stack.Item>
          <Stack.Item>
            <section className={styles.buttonSection}>
              <PrimaryButton
                style={{ minWidth: 80 }}
                onClick={updateAutomateApp}
                disabled={
                  !tempAutomateApp.DisplayName ||
                  tempAutomateApp.DisplayName.length < 3 ||
                  !tempAutomateApp.Description ||
                  tempAutomateApp.Description.length < 20 ||
                  tempAutomateApp.Description.length > 1000
                }
                text={saving ? "" : "Save"}
              >
                {saving && (
                  <Spinner hidden={!saving} size={SpinnerSize.small} />
                )}
              </PrimaryButton>
            </section>
          </Stack.Item>
        </Stack>
      </>
    )) || <Spinner size={SpinnerSize.large} />
  );
};

export default AutomateAppSettings;
