import {
  Label,
  Text,
  PrimaryButton,
  Stack,
  IStackProps,
  ActionButton,
  IIconProps,
} from '@fluentui/react';
import { ChangeEvent, FC, useRef, useState } from 'react';
import { IAutomateAppParameter } from '../../../../Models/API/IAutomateApp';
import { useId } from '@fluentui/react-hooks';

const columnProps: Partial<IStackProps> = {
  tokens: { childrenGap: 5 },
  styles: { root: { width: 700 } },
};

const deleteIcon: IIconProps = { iconName: 'Delete' };

export interface IFileTypeProps {
  param: IAutomateAppParameter;
  setData: (param: IAutomateAppParameter, value: File | undefined) => void;
}

const FileType: FC<IFileTypeProps> = ({ param, setData }) => {
  const type = useId('type');
  const inputRef = useRef<HTMLInputElement>(null);

  const [file, setFile] = useState<File | undefined>(undefined);

  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files) {
      const file = files[0];
      if (file) {
        setFile(file);
        setData(param, file);
      }
    }
  };

  const deleteFile = () => {
    setFile(undefined);
    setData(param, undefined);
  };

  return (
    <Stack {...columnProps}>
      <Label required={param.Required} htmlFor={type}>
        {param.DisplayName}
      </Label>

      <Stack horizontal verticalAlign='center'>
        <Text variant={'small'} block>
          {file ? file.name : 'No uploaded file'}
        </Text>

        {file ? (
          <ActionButton
            iconProps={deleteIcon}
            allowDisabledFocus
            title='Delete property'
            ariaLabel='Delete property'
            onClick={() => deleteFile()}
          >
            Delete File
          </ActionButton>
        ) : (
          <></>
        )}
      </Stack>
      <Stack.Item>
        <PrimaryButton
          style={{ width: 150 }}
          onClick={() => inputRef.current?.click()}
          text={'Upload file'}
        ></PrimaryButton>
        <input
          ref={inputRef}
          type='file'
          hidden={true}
          onChange={onFileChange}
          // To reselect the same file
          onClick={(ev: any) => (ev.target.value = null)}
        />
      </Stack.Item>
      <Text variant={'xSmall'} block>
        {param.Description}
      </Text>
    </Stack>
  );
};

export default FileType;
