import { FC, createContext } from "react";
import CacheService from "../CacheService";
import ConfigurationService from "../ConfigurationService";

export interface IConversationSettings {
  temperature?: number;
  threshold?: number;
  selectedLibariesIds: string[];
}

export interface IConversationSettingsService {
  GetSettings(): IConversationSettings | null;
  SaveSettings(settings: IConversationSettings): void;
  ResetSettings(): void;
}

export const ConversationSettingsServiceContext = createContext<
  IConversationSettingsService | undefined
>(undefined);

const ConversationSettingsService: FC = ({ children }: any) => {
  const cacheKey: string = `conversationSettings_${ConfigurationService.Default.Configuration.AppVersion}`;

  const conversationSettingsService: IConversationSettingsService = {
    GetSettings(): IConversationSettings | null {
      return CacheService.Default.GetFromCache<IConversationSettings | null>(
        cacheKey,
        null
      );
    },
    SaveSettings(settings: IConversationSettings) {
      CacheService.Default.SaveToCache(cacheKey, settings);
    },
    ResetSettings() {
      CacheService.Default.RemoveFromCache(cacheKey);
    },
  };

  return (
    <ConversationSettingsServiceContext.Provider
      value={conversationSettingsService}
    >
      {children}
    </ConversationSettingsServiceContext.Provider>
  );
};

export default ConversationSettingsService;
