export default class CacheService {
  private static _default: CacheService;

  public static get Default(): CacheService {
    if (this._default == null) {
      this._default = new CacheService();
    }
    return this._default;
  }

  SaveToCache<T>(key: string, object: T): void {
    localStorage.setItem(key, JSON.stringify(object));
  }

  GetFromCache<T>(key: string, defaultValue: T): T {
    let obj = localStorage.getItem(key);
    if (obj != null) {
      return JSON.parse(obj) as T;
    }
    return defaultValue;
  }

  RemoveFromCache(key: string): void {
    localStorage.removeItem(key);
  }
}
