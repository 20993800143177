import { FC } from "react";
import { IAttachment } from "../../Models/API/IConversation";
import "./UserMessageAttachments.scss";
import { Icon, Stack, StackItem } from "@fluentui/react";
import { getFileTypeIconProps } from "@fluentui/react-file-type-icons";
import ConfigurationService from "../../Services/ConfigurationService";

export interface IUserMessageAttachmentsProps {
  attachments: IAttachment[];
}

const fileName = (fileName: string) => {
  const nameLength = 15;
  return fileName.length > nameLength
    ? `${fileName.substring(0, nameLength)}...`
    : fileName;
};

const attachmentLink = (attachment: IAttachment) => {
  if (attachment.Type === "Library") {
    return `${ConfigurationService.Default.Configuration.API?.BaseUri}/documents/${attachment.Id}/open`;
  } else if (attachment.Type === "User") {
    return `${ConfigurationService.Default.Configuration.API?.BaseUri}/users/documents/${attachment.Id}/open`;
  }
};

const UserMessageAttachments: FC<IUserMessageAttachmentsProps> = ({
  attachments,
}) => {
  return (
    <Stack horizontalAlign="end" horizontal tokens={{ childrenGap: 10 }}>
      {attachments &&
        attachments.map((attachment) => {
          return (
            <StackItem>
              <a
                className="msg-attachment"
                key={`${attachment.Type}_${attachment.Id}`}
                title={attachment.FileName}
                href={attachmentLink(attachment)}
                target="_blank"
                rel="noreferrer"
              >
                <span className="icon">
                  <Icon
                    {...getFileTypeIconProps({
                      extension:
                        attachment.FileName.split(".")[
                          attachment.FileName.split(".").length - 1
                        ],
                      size: 16,
                      imageFileType: "svg",
                    })}
                  />
                </span>
                <span className="name">{fileName(attachment.FileName)}</span>
              </a>
            </StackItem>
          );
        })}
    </Stack>
  );
};

export default UserMessageAttachments;
