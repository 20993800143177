import { Spinner, SpinnerSize, Stack, StackItem } from "@fluentui/react";
import { FC, useContext, useEffect, useState } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import ConfigurationService from "../../../Services/ConfigurationService";
import { catalogServiceContext } from "../../../Services/API/CatalogService";
import IAppCatalog from "../../../Models/API/IAppCatalog";
import { chatAppServiceContext } from "../../../Services/API/ChatAppService";
import IBaseApp from "../../../Models/API/IBaseApp";
import { agentAppServiceContext } from "../../../Services/API/AgentAppService";
import { automateAppServiceContext } from "../../../Services/API/AutomateAppService";
import CatalogEditorBreadcrumb from "../../../Components/Breadcrumb/CatalogEditorBreadcrumb";
import ILibrary from "../../../Models/API/ILibrary";

const Catalog: FC = () => {
  const catalogService = useContext(catalogServiceContext);
  const chatAppService = useContext(chatAppServiceContext);
  const agentAppService = useContext(agentAppServiceContext);
  const automateAppService = useContext(automateAppServiceContext);

  let { catalogId } = useParams();
  let { appId } = useParams();

  const location = useLocation();
  const [catalog, setCatalog] = useState<IAppCatalog | null>(null);
  const [baseApp, setBaseApp] = useState<IBaseApp | null>(null);
  const [selectedLibrary, setSelectedLibrary] = useState<ILibrary | null>(null);

  const [isMounted, setIsMounted] = useState<boolean>(true);

  useEffect(() => {
    const execute = async () => {
      let cat: IAppCatalog | null = null;
      let app: IBaseApp | null = null;

      if (isMounted) {
        if (catalogId) {
          if (!catalog) {
            cat = await catalogService!.Get(parseInt(catalogId));
            setCatalog(cat);
          } else {
            cat = catalog;
          }
        }
      }

      if (appId) {
        const paths = location.pathname.split("/");
        if (paths.includes("chat")) {
          app = await chatAppService!.Get(parseInt(appId!));
        } else if (paths.includes("agent")) {
          app = await agentAppService!.Get(parseInt(appId!));
        } else if (paths.includes("automate")) {
          app = await automateAppService!.Get(parseInt(appId!));
        }

        setBaseApp(app);
      } else {
        setBaseApp(null);
      }

      document.title = `${
        ConfigurationService.Default.Configuration.PageTitle
      } - ${`${cat?.DisplayName}${app ? ` - ${app?.DisplayName}` : ""}`}`;
    };
    execute();
    return () => {
      setIsMounted(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogId, appId]);

  return (
    (catalog && (
      <Stack
        verticalFill
        style={{ minHeight: 0, height: "100%" }}
      >
        <StackItem>
          <CatalogEditorBreadcrumb
            catalog={catalog}
            baseApp={baseApp}
            library={selectedLibrary}
          />
        </StackItem>
        <StackItem grow verticalFill style={{ minHeight: 0, height: "100%" }}>
          <Outlet
            context={{
              catalog,
              setCatalog,
              baseApp,
              setBaseApp,
              setSelectedLibrary,
            }}
          ></Outlet>
        </StackItem>
      </Stack>
    )) || <Spinner size={SpinnerSize.large} />
  );
};

export default Catalog;
