import {
  CommandBar,
  DefaultButton,
  DetailsList,
  Dialog,
  DialogFooter,
  DialogType,
  IColumn,
  ICommandBarItemProps,
  Panel,
  PanelType,
  PrimaryButton,
  Selection,
  SelectionMode,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
} from "@fluentui/react";
import React, { FC, useState, useEffect, useContext } from "react";
import { useOutletContext } from "react-router-dom";

import moment from "moment";
import IAgentApp from "../../../../../../Models/API/IAgentApp";
import {
  INewAgentAppFunction,
  agentAppServiceContext,
} from "../../../../../../Services/API/AgentAppService";
import { IAgentAppFunction } from "../../../../../../Models/API/IAgentAppFunction";
import FunctionForm from "./FunctionsForm";
import { trackPageView } from "../../../../../../Services/AppInsights";
import ConfigurationService from "../../../../../../Services/ConfigurationService";

const columns: IColumn[] = [
  {
    key: "DisplayName",
    name: "Display Name",
    minWidth: 200,
    isResizable: true,
  },
  {
    key: "ReturnValue",
    name: "Return Value",
    minWidth: 200,
    isResizable: true,
  },
  {
    key: "Parameters",
    name: "# Parameters",
    minWidth: 200,
    isResizable: true,
  },
  {
    key: "Created",
    name: "Created",
    minWidth: 100,
    isResizable: true,
  },
];

const defaultNewFunction: INewAgentAppFunction = {
  DisplayName: "",
  Description: "",
  ReturnValue: "",
  ActionUrl: "",
  SystemPrompt: "",
};

const AgentFunctions: FC = () => {
  const { agentApp } = useOutletContext<{
    agentApp: IAgentApp;
    setAgentApp: (value: IAgentApp) => void;
  }>();
  const agentAppsService = useContext(agentAppServiceContext);
  const [commandBarButtons, setCommandBarButtons] = useState<
    ICommandBarItemProps[]
  >([]);
  const [functions, setFunctions] = useState<IAgentAppFunction[] | null>();
  const [selectedFunctions, setSelectedFunctions] = useState<
    IAgentAppFunction[]
  >([]);
  const [currentFunction, setCurrentFunction] = useState<
    IAgentAppFunction | INewAgentAppFunction
  >();
  const [savingFunction, setSavingFunction] = useState<boolean>(false);
  const [hideRemoveFunctionDialog, setHideRemoveFunctionDialog] =
    useState<boolean>(true);

  // Track page view
  useEffect(() => {
    document.title = `${ConfigurationService.Default.Configuration.PageTitle} - ${agentApp.DisplayName} Functions`;
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAgentAppFunction = async () => {
    setFunctions(undefined);
    const functions = await agentAppsService?.GetAgentFunctions(agentApp.Id);
    if (functions) {
      setFunctions(functions);
    }
  };

  useEffect(() => {
    const execute = async () => {
      await getAgentAppFunction();
    };
    execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentApp]);

  // Runs when the selected functions change
  useEffect(() => {
    setCommandBarButtons([
      {
        key: "add",
        text: "Add",
        iconProps: { iconName: "Add" },
        subMenuProps: {
          items: [
            {
              key: "function",
              text: "Function",
              iconProps: { iconName: "LightningBolt" },
              onClick: (event, item) => {
                setCurrentFunction(defaultNewFunction);
              },
            },
          ],
        },
      },
      {
        key: "edit",
        text: "Edit",
        iconProps: { iconName: "Edit" },
        onClick: (event, item) => {
          setCurrentFunction(selectedFunctions[0]);
        },
        disabled: selectedFunctions.length !== 1,
      },
      {
        key: "delete",
        text: "Delete",
        iconProps: { iconName: "Delete" },
        onClick: async (event, item) => {
          setHideRemoveFunctionDialog(false);
        },
        disabled: selectedFunctions.length === 0,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFunctions]);

  const onRenderItemColumn = (
    item: IAgentAppFunction,
    index?: number,
    column?: IColumn
  ): any => {
    if (column?.key === "Required") {
      return item.DisplayName ? "Yes" : "No";
    } else if (column?.key === "Parameters") {
      return item.Parameters.Properties.size;
    } else if (column?.key === "Created") {
      return item.Created
        ? moment(item.Created as string).format("DD MMM yyyy")
        : "";
    } else {
      return item![column?.key as keyof IAgentAppFunction];
    }
  };

  const selection = new Selection({
    onSelectionChanged: () => {
      setSelectedFunctions(selection.getSelection() as IAgentAppFunction[]);
    },
  });

  const removeFunctions = async () => {
    setHideRemoveFunctionDialog(true);
    if (selectedFunctions && selectedFunctions.length) {
      setFunctions(undefined);
      for (var i = 0; i < selectedFunctions.length; i++) {
        await agentAppsService?.DeleteFunction(
          agentApp.Id,
          selectedFunctions[i].Id
        );
      }
      getAgentAppFunction();
    }
  };

  const upsertFunction = async () => {
    if (!savingFunction) {
      setSavingFunction(true);
      if (currentFunction) {
        if (Object.hasOwn(currentFunction, "Id")) {
          await agentAppsService?.UpdateFunction(
            agentApp.Id,
            currentFunction as IAgentAppFunction
          );
        } else {
          await agentAppsService?.CreateFunction(agentApp.Id, currentFunction);
        }
      }
      await getAgentAppFunction();
      setCurrentFunction(undefined);
      setSavingFunction(false);
    }
  };

  const onRenderFooterContent = React.useCallback(
    () => (
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <DefaultButton onClick={() => setCurrentFunction(undefined)}>
          Cancel
        </DefaultButton>
        <PrimaryButton
          style={{ minWidth: 80 }}
          onClick={upsertFunction}
          text={savingFunction ? "" : "Save"}
          disabled={
            !currentFunction?.DisplayName ||
            currentFunction.DisplayName.length < 5 ||
            !/^[ a-zA-Z0-9]*$/.test(currentFunction.DisplayName) ||
            !currentFunction.Description ||
            currentFunction.Description.length < 20
          }
        >
          {savingFunction && <Spinner size={SpinnerSize.small} />}
        </PrimaryButton>
      </Stack>
    ),
    // eslint-disable-next-line
    [currentFunction, savingFunction]
  );

  return (
    <>
      <Stack verticalFill style={{ minHeight: 0 }}>
        <Stack.Item>
          <CommandBar items={commandBarButtons} ariaLabel="Fields actions" />
        </Stack.Item>
        <Stack.Item
          verticalFill
          style={{ minHeight: 0, overflowY: "auto", position: "relative" }}
        >
          <DetailsList
            className="functions-list"
            setKey="items"
            items={functions || []}
            columns={columns}
            selection={selection}
            selectionMode={SelectionMode.multiple}
            onRenderItemColumn={onRenderItemColumn}
            ariaLabelForGrid="Item details"
            listProps={{ renderedWindowsAhead: 0, renderedWindowsBehind: 0 }}
            styles={{ root: { verticalAlign: "middle" } }}
          />
          {(functions === undefined ||
            functions === null ||
            functions.length === 0) && (
            <>
              {functions !== undefined &&
                (functions === null || functions.length === 0) && (
                  <Text
                    variant="large"
                    block
                    style={{
                      textAlign: "center",
                      color: "rgba(255,255,255,0.3)",
                    }}
                  >
                    No functions found
                  </Text>
                )}
              {functions === undefined && <Spinner size={SpinnerSize.large} />}
            </>
          )}
        </Stack.Item>
      </Stack>
      <Dialog
        hidden={hideRemoveFunctionDialog}
        onDismiss={() => setHideRemoveFunctionDialog(true)}
        modalProps={{ isBlocking: true, styles: { main: { maxWidth: 450 } } }}
        dialogContentProps={{
          type: DialogType.normal,
          title: "Remove Permissions",
          subText: "Are you sure you want to remove the following functions?",
        }}
      >
        <Stack tokens={{ childrenGap: 5 }}>
          {selectedFunctions.map((perm) => {
            return (
              <Text key={perm.Id} block>
                {perm.DisplayName}
              </Text>
            );
          })}
        </Stack>
        <DialogFooter>
          <PrimaryButton onClick={removeFunctions} text="Remove" />
          <DefaultButton
            onClick={() => setHideRemoveFunctionDialog(true)}
            text="Cancel"
          />
        </DialogFooter>
      </Dialog>
      <Panel
        isOpen={currentFunction !== undefined}
        onDismiss={() => setCurrentFunction(undefined)}
        headerText={
          currentFunction && currentFunction.DisplayName
            ? "Update Function"
            : "Add Function"
        }
        closeButtonAriaLabel="Close"
        onRenderFooterContent={onRenderFooterContent}
        isFooterAtBottom={true}
        type={PanelType.medium}
      >
        {currentFunction ? (
          <FunctionForm
            agentFunction={currentFunction}
            setAgentFunction={setCurrentFunction}
          />
        ) : (
          <></>
        )}
      </Panel>
    </>
  );
};

export default AgentFunctions;
