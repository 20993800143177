import { Label, TextField, Text, Stack, IStackProps } from '@fluentui/react';
import { FC } from 'react';
import { IAutomateAppParameter } from '../../../../Models/API/IAutomateApp';
import { useId } from '@fluentui/react-hooks';

const columnProps: Partial<IStackProps> = {
  tokens: { childrenGap: 5 },
};

const numberProps: Partial<IStackProps> = {
  styles: { root: { width: 200 } },
};

export interface INumberTypeProps {
  param: IAutomateAppParameter;
  setData: (param: IAutomateAppParameter, value: number) => void;
}

const NumberType: FC<INumberTypeProps> = ({ param, setData }) => {
  const type = useId('type');
  return (
    <>
      <Stack {...columnProps}>
        <Label required={param.Required} htmlFor={type}>
          {param.DisplayName}
        </Label>
        <Stack.Item {...numberProps}>
          <TextField
            type='number'
            onChange={(evt, value) => setData(param, Number(value))}
          ></TextField>
        </Stack.Item>

        <Text variant={'xSmall'} block>
          {param.Description}
        </Text>
      </Stack>
    </>
  );
};

export default NumberType;
