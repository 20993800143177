import {
  CommandBar,
  DefaultButton,
  DetailsList,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  IColumn,
  ICommandBarItemProps,
  IDropdownOption,
  IPersonaProps,
  Icon,
  Label,
  Link,
  PrimaryButton,
  SearchBox,
  Selection,
  SelectionMode,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
} from "@fluentui/react";
import React, { FC, useContext, useEffect, useState } from "react";
import IPermission from "../../Models/API/IPermission";
import { removeDiacritics } from "../../Utilities/Strings";
import AuthenticationService from "../../Services/AuthenticationService";
import PeoplePicker from "../PeoplePicker/PeoplePicker";
import IUserPermission, {
  PermissionType,
  ResourceType,
} from "../../Models/API/IUserPermission";
import { useNavigate, useParams } from "react-router-dom";
import { LibraryServiceContext } from "../../Services/API/LibraryService";

interface INewPermissionAssignment {
  Principals: IPersonaProps[] | undefined;
  Type: PermissionType;
}

interface IUpdatePermissionAssignment {
  Permission: IPermission;
  Type: PermissionType;
}

type PermissionManagementProps = {
  resourceType: ResourceType;
  resourceId: number;
  permissions: IPermissions;
  isOwner: boolean;
  reloadPermissions: () => Promise<IPermission[]>;
  deletePermission: (objectId: string) => Promise<void>;
  addPermission: (
    objectId: string,
    permissionType: string
  ) => Promise<IPermission | null>;
  checkPermissions: (objectId: string) => Promise<IUserPermission[]>;
};

export interface IPermissions {
  Read: IPermissionDescription;
  Write: IPermissionDescription;
  FullControl: IPermissionDescription;
  Owner: IPermissionDescription;
}

export interface IPermissionDescription {
  DisplayName: string;
  Description: string;
}

const shimmerColumns: IColumn[] = [
  {
    key: "Icon",
    name: "",
    minWidth: 20,
    maxWidth: 20,
    isResizable: true,
  },
  {
    key: "DisplayName",
    name: "DisplayName",
    minWidth: 100,
    isResizable: true,
  },
  {
    key: "Email",
    name: "Email",
    minWidth: 200,
    isResizable: true,
  },
  {
    key: "ObjectId",
    name: "Id",
    minWidth: 250,
    isResizable: true,
  },
  {
    key: "Type",
    name: "Level",
    minWidth: 100,
    isResizable: true,
  },
  {
    key: "Scope",
    name: "Scope",
    minWidth: 100,
    isResizable: true,
  },
];

const PermissionManagement: FC<PermissionManagementProps> = (props) => {
  const libraryService = useContext(LibraryServiceContext);
  const navigate = useNavigate();
  const params = useParams();
  const [isMounted, setIsMounted] = useState<boolean>(true);
  const [permissions, setPermissions] = useState<IPermission[] | null>(null);
  const [filteredPermissions, setFilteredPermissions] = useState<
    IPermission[] | null | undefined
  >(undefined);
  const [selectedPermissions, setSelectedPermissions] = useState<IPermission[]>(
    []
  );
  const [commandBarButtons, setCommandBarButtons] = useState<
    ICommandBarItemProps[]
  >([]);
  const [filterKeyword, setFilterKeyword] = useState<string | undefined>(
    undefined
  );
  const [hideRemovePermissionsDialog, setHideRemovePermissionsDialog] =
    useState<boolean>(true);
  const [hideAddPermissionsDialog, setHideAddPermissionsDialog] =
    useState<boolean>(true);
  const [hideCheckPermissionsDialog, setHideCheckPermissionsDialog] =
    useState<boolean>(true);
  const [permissionAssignment, setPermissionAssignment] =
    useState<INewPermissionAssignment>({
      Principals: [],
      Type: "Read",
    });
  const [checkPermissionUser, setCheckPermissionUser] =
    useState<IPersonaProps>();
  const [addingPermissions, setAddingPermissions] = useState<boolean>(false);
  const [checkingPermissions, setCheckingPermissions] =
    useState<boolean>(false);
  const [updatePermission, setUpdatePermission] = useState<
    IUpdatePermissionAssignment | undefined
  >(undefined);
  const [updatingPermissions, setUpdatingPermissions] =
    useState<boolean>(false);
  const [userPermissions, setUserPermissions] = useState<IUserPermission[]>();

  let dropdownControlledExampleOptions = [
    { key: "Read", text: "Read" },
    { key: "Write", text: "Write" },
    { key: "FullControl", text: "Full Control" },
  ];

  if (props.isOwner) {
    dropdownControlledExampleOptions = [
      ...dropdownControlledExampleOptions,
      {
        key: "Owner",
        text: "Owner",
      },
    ];
  }

  useEffect(() => {
    reloadPermissions();
    return () => {
      setIsMounted(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetState = (): void => {
    setHideRemovePermissionsDialog(true);
    setPermissions([]);
    setFilteredPermissions(undefined);
    setSelectedPermissions([]);
    setFilterKeyword(undefined);
  };

  // Runs when the filter keyword changes
  useEffect(() => {
    if (permissions && filterKeyword !== undefined) {
      setFilteredPermissions(
        filterKeyword
          ? permissions?.filter((perm) => {
              return (
                removeDiacritics(
                  perm.Principal.DisplayName.toLowerCase()
                ).indexOf(removeDiacritics(filterKeyword.toLowerCase())) !== -1
              );
            })
          : permissions
      );
    }
  }, [filterKeyword, permissions]);

  const reloadPermissions = async () => {
    if (isMounted) setPermissions([]);
    if (isMounted) setFilteredPermissions(undefined);
    let perms = await props.reloadPermissions();
    perms = perms!.sort((permA, permB) =>
      permA.Principal.DisplayName.localeCompare(permB.Principal.DisplayName)
    );
    if (isMounted) setFilteredPermissions(perms);
    if (isMounted) setPermissions(perms);
  };

  const selection = new Selection({
    canSelectItem(item, index) {
      return (
        ((item as IPermission).Type !== "Owner" || props.isOwner) &&
        (item as IPermission).Principal.ObjectId !==
          AuthenticationService.Default.Account?.localAccountId &&
        (item as IPermission).Scope === "Assigned"
      );
    },
    onSelectionChanged: () => {
      setSelectedPermissions(selection.getSelection() as IPermission[]);
    },
  });

  const onRenderItemColumn = (
    item: IPermission,
    index?: number,
    column?: IColumn
  ): any => {
    if (column?.key === "Icon") {
      return (
        <Icon
          style={{ fontSize: 16 }}
          iconName={
            item.Principal.Type === "User"
              ? "Contact"
              : item.Principal.Type === "Application"
              ? "CubeShape"
              : "Group"
          }
        />
      );
    } else if (column?.key === "DisplayName") {
      return item.Principal.DisplayName;
    } else if (column?.key === "Email") {
      return item.Principal.Email ?? "N/A";
    } else if (column?.key === "ObjectId") {
      return item.Principal.ObjectId;
    } else if (column?.key === "Type") {
      return props.permissions[item.Type!].DisplayName;
    } else {
      return item![column?.key as keyof IPermission];
    }
  };

  // Runs when the selected documents change
  useEffect(() => {
    setCommandBarButtons([
      {
        key: "add",
        text: "Add Permissions",
        iconProps: { iconName: "Permissions" },
        onClick: (event, item) => {
          setHideAddPermissionsDialog(false);
        },
      },
      {
        key: "edit",
        text: "Edit Permission",
        iconProps: { iconName: "EditContact" },
        onClick: (event, item) => {
          setUpdatePermission({
            Permission: selectedPermissions[0],
            Type: selectedPermissions[0].Type as any,
          });
        },
        disabled: selectedPermissions.length !== 1,
      },
      {
        key: "remove",
        text: "Remove Permissions",
        iconProps: { iconName: "Delete" },
        onClick: (event, item) => {
          setHideRemovePermissionsDialog(false);
        },
        disabled: selectedPermissions.length === 0,
      },
      {
        key: "check",
        text: "Check Permissions",
        iconProps: { iconName: "FabricUserFolder" },
        onClick: (event, item) => {
          setHideCheckPermissionsDialog(false);
        },
      },
      {
        key: "refresh",
        text: "Refresh",
        iconProps: { iconName: "Refresh" },
        onClick: (event, item) => {
          reloadPermissions();
        },
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPermissions]);

  const removePermissions = async (): Promise<void> => {
    const promises: (Promise<void | null> | undefined)[] = [];
    const selectedPerms = [...selectedPermissions];
    resetState();
    for (let i = 0; i < selectedPerms.length; i++) {
      const perm = selectedPerms[i];
      promises.push(props.deletePermission(perm.Principal.ObjectId));
    }
    await Promise.allSettled(promises);
    reloadPermissions();
  };

  const onPermissionLevelChange = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
    index?: number
  ): void => {
    setPermissionAssignment({
      ...permissionAssignment,
      Type: option!.key as any,
    });
  };

  const addPermissions = async () => {
    if (
      permissionAssignment.Principals &&
      permissionAssignment.Principals.length > 0 &&
      !addingPermissions
    ) {
      setAddingPermissions(true);
      const promises: (Promise<IPermission | null> | undefined)[] = [];
      for (let i = 0; i < permissionAssignment.Principals!.length; i++) {
        const user = permissionAssignment.Principals[i];
        promises.push(
          props.addPermission(user.id!, permissionAssignment.Type!)
        );
      }
      await Promise.allSettled(promises);
      reloadPermissions();
      setAddingPermissions(false);
      setHideAddPermissionsDialog(true);
    }
  };

  const checkPermissions = async () => {
    if (checkPermissionUser !== undefined && !checkingPermissions) {
      setCheckingPermissions(true);
      var permissions = await props.checkPermissions(checkPermissionUser.id!);
      if (permissions) setUserPermissions(permissions);
      setCheckingPermissions(false);
    }
  };

  const _updatePermission = async () => {
    if (!updatingPermissions && updatePermission !== undefined) {
      setUpdatingPermissions(true);
      await props.addPermission(
        updatePermission.Permission.Principal.ObjectId,
        updatePermission.Type!
      );
      reloadPermissions();
      setUpdatingPermissions(false);
      setUpdatePermission(undefined);
    }
  };

  const permissionsBody = () => {
    const navigateTo = async (userPerm: IUserPermission) => {
      switch (userPerm.InheritedFrom!.Type) {
        case "AppCatalog":
          navigate(`/catalogseditor/${userPerm.InheritedFrom!.Id}/permissions`);
          return;
        case "ChatApp":
          navigate(
            `/catalogseditor/${params.catalogId}/apps/chat/${
              userPerm.InheritedFrom!.Id
            }/permissions`
          );
          return;
        case "AgentApp":
          navigate(
            `/catalogseditor/${params.catalogId}/apps/agent/${
              userPerm.InheritedFrom!.Id
            }/permissions`
          );
          return;
        case "AutomateApp":
          navigate(
            `/catalogseditor/${params.catalogId}/apps/automate/${
              userPerm.InheritedFrom!.Id
            }/permissions`
          );
          return;
        case "Library":
          var library = await libraryService?.Get(userPerm.InheritedFrom!.Id);
          navigate(
            `/catalogseditor/${params.catalogId}/apps/chat/${
              library?.ChatAppId
            }/libraries/${userPerm.InheritedFrom!.Id}/permissions`
          );
          return;
      }
    };

    return (
      <tbody>
        {userPermissions?.map((perm, index) => {
          return (
            <tr key={index}>
              <td
                style={{
                  verticalAlign: "top",
                  paddingRight: 20,
                  paddingBottom: 5,
                  height: 26,
                }}
              >
                {perm.PermissionType}
              </td>
              {(perm.GroupName && (
                <td
                  style={{ paddingBottom: 5, height: 26, verticalAlign: "top" }}
                >
                  Assigned through the "{perm.GroupName}" group in{" "}
                  {(perm.InheritedFrom && (
                    <>
                      <Link onClick={(_) => navigateTo(perm)}>
                        {perm.InheritedFrom.DisplayName}
                      </Link>
                      {` (${perm.InheritedFrom.Type}) `}
                      as {perm.InheritedFrom?.PermissionType}
                    </>
                  )) || <>this resource</>}
                </td>
              )) || (
                <td
                  style={{ paddingBottom: 5, height: 26, verticalAlign: "top" }}
                >
                  Assigned in{" "}
                  {(perm.InheritedFrom && (
                    <>
                      <Link onClick={(_) => navigateTo(perm)}>
                        {perm.InheritedFrom.DisplayName}
                      </Link>
                      {` (${perm.InheritedFrom.Type}) `}
                      as {perm.InheritedFrom?.PermissionType}
                    </>
                  )) || <>this resource</>}
                </td>
              )}
            </tr>
          );
        })}
      </tbody>
    );
  };

  useEffect(() => {
    if (hideCheckPermissionsDialog) {
      setUserPermissions(undefined);
    }
  }, [hideCheckPermissionsDialog]);

  return (
    <>
      <Stack verticalFill style={{ minHeight: 0 }}>
        <Stack.Item>
          <CommandBar
            items={commandBarButtons}
            ariaLabel="Permissions actions"
          />
          <SearchBox
            value={filterKeyword}
            onChange={(evt, newValue) => {
              setFilterKeyword(newValue);
            }}
            placeholder="Filter by principal name"
            iconProps={{ iconName: "Filter" }}
            underlined={true}
          />
        </Stack.Item>
        <Stack.Item
          verticalFill
          style={{ position: "relative", minHeight: 0, overflowY: "auto" }}
        >
          <DetailsList
            setKey="items"
            items={filteredPermissions || []}
            columns={shimmerColumns}
            selection={selection}
            selectionMode={SelectionMode.multiple}
            onRenderItemColumn={onRenderItemColumn}
            ariaLabelForGrid="Item details"
            listProps={{ renderedWindowsAhead: 0, renderedWindowsBehind: 0 }}
            className="documents-list"
          />
          {(filteredPermissions === undefined ||
            filteredPermissions?.length === 0) && (
            <>
              {filteredPermissions?.length === 0 && (
                <Text
                  variant="large"
                  block
                  style={{
                    textAlign: "center",
                    color: "rgba(255,255,255,0.3)",
                  }}
                >
                  No permissions found
                </Text>
              )}
              {filteredPermissions === undefined && (
                <Spinner size={SpinnerSize.large} />
              )}
            </>
          )}
        </Stack.Item>
      </Stack>
      <Dialog
        hidden={hideRemovePermissionsDialog}
        onDismiss={() => setHideRemovePermissionsDialog(true)}
        modalProps={{ isBlocking: true, styles: { main: { maxWidth: 450 } } }}
        dialogContentProps={{
          type: DialogType.normal,
          title: "Remove Permissions",
          subText:
            "Are you sure you want to remove the permissions of the following entities?",
        }}
      >
        <Stack tokens={{ childrenGap: 5 }}>
          {selectedPermissions.map((perm) => {
            return (
              <Text key={perm.Principal.ObjectId} block>
                {perm.Principal.DisplayName}
              </Text>
            );
          })}
        </Stack>
        <DialogFooter>
          <PrimaryButton onClick={removePermissions} text="Remove" />
          <DefaultButton
            onClick={() => setHideRemovePermissionsDialog(true)}
            text="Cancel"
          />
        </DialogFooter>
      </Dialog>
      <Dialog
        hidden={hideAddPermissionsDialog}
        onDismiss={() => setHideAddPermissionsDialog(true)}
        modalProps={{
          isBlocking: true,
          styles: {
            main: { minWidth: "450px !important", maxWidth: 450 },
          },
        }}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: "Add Permissions",
        }}
      >
        <Stack tokens={{ childrenGap: 10 }}>
          <Stack.Item>
            <Label>Users, Groups and Applications</Label>
            <PeoplePicker
              excludeIds={
                permissions
                  ?.filter((p) => p.Scope === "Assigned")
                  .map((p) => p.Principal.ObjectId) ?? []
              }
              onChange={(props) =>
                setPermissionAssignment({
                  ...permissionAssignment,
                  Principals: props,
                })
              }
            ></PeoplePicker>
          </Stack.Item>
          <Stack.Item>
            <Dropdown
              label="Permission Level"
              selectedKey={
                permissionAssignment.Type
                  ? permissionAssignment.Type
                  : undefined
              }
              // eslint-disable-next-line react/jsx-no-bind
              onChange={onPermissionLevelChange}
              placeholder="Select an option"
              options={dropdownControlledExampleOptions}
            />
            <Text style={{ marginTop: 5 }} variant={"small"} block>
              {props.permissions[permissionAssignment.Type!].Description}
            </Text>
          </Stack.Item>
        </Stack>
        <DialogFooter>
          <PrimaryButton
            style={{ minWidth: 150 }}
            disabled={permissionAssignment.Principals?.length === 0}
            onClick={addPermissions}
            text={addingPermissions ? "" : "Add Permissions"}
          >
            {addingPermissions && <Spinner size={SpinnerSize.small} />}
          </PrimaryButton>
          <DefaultButton
            onClick={() => setHideAddPermissionsDialog(true)}
            text="Cancel"
          />
        </DialogFooter>
      </Dialog>
      {updatePermission !== undefined && (
        <Dialog
          hidden={updatePermission === undefined}
          onDismiss={() => setUpdatePermission(undefined)}
          modalProps={{
            isBlocking: true,
            styles: {
              main: { minWidth: "450px !important", maxWidth: 450 },
            },
          }}
          dialogContentProps={{
            type: DialogType.largeHeader,
            title: "Edit Permission",
          }}
        >
          <Stack tokens={{ childrenGap: 10 }}>
            <Stack.Item>
              <Label>Users, Group or Application</Label>
              <Text>{updatePermission.Permission.Principal.DisplayName}</Text>
            </Stack.Item>
            <Stack.Item>
              <Dropdown
                label="Permission Level"
                selectedKey={
                  updatePermission.Type ? updatePermission.Type : undefined
                }
                // eslint-disable-next-line react/jsx-no-bind
                onChange={(evt, option, index) => {
                  setUpdatePermission({
                    ...updatePermission,
                    Type: option!.key as any,
                  });
                }}
                placeholder="Select an option"
                options={dropdownControlledExampleOptions}
              />
              <Text style={{ marginTop: 5 }} variant={"small"} block>
                {props.permissions[updatePermission.Type!].Description}
              </Text>
            </Stack.Item>
          </Stack>
          <DialogFooter>
            <PrimaryButton
              style={{ minWidth: 150 }}
              onClick={_updatePermission}
              text={updatingPermissions ? "" : "Update Permission"}
            >
              {updatingPermissions && <Spinner size={SpinnerSize.small} />}
            </PrimaryButton>
            <DefaultButton
              onClick={() => setUpdatePermission(undefined)}
              text="Cancel"
            />
          </DialogFooter>
        </Dialog>
      )}
      <Dialog
        hidden={hideCheckPermissionsDialog}
        onDismiss={() => setHideCheckPermissionsDialog(true)}
        modalProps={{
          isBlocking: true,
          styles: {
            main: { minWidth: "450px !important", maxWidth: 450 },
          },
        }}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: "Check Permissions",
        }}
      >
        <Stack tokens={{ childrenGap: 10 }}>
          <Stack.Item>
            <Label>User, Group or Application</Label>
            <PeoplePicker
              singleChoice
              onChange={(props) => setCheckPermissionUser(props && props[0])}
            ></PeoplePicker>
          </Stack.Item>
          {userPermissions && userPermissions.length > 0 && (
            <Stack grow>
              <table>{permissionsBody()}</table>
            </Stack>
          )}
          {userPermissions && userPermissions.length === 0 && (
            <Text>No permissions found</Text>
          )}
        </Stack>
        <DialogFooter>
          <PrimaryButton
            style={{ minWidth: 150 }}
            disabled={checkPermissionUser === undefined}
            onClick={checkPermissions}
            text={checkingPermissions ? "" : "Check Permissions"}
          >
            {checkingPermissions && <Spinner size={SpinnerSize.small} />}
          </PrimaryButton>
          <DefaultButton
            onClick={() => setHideCheckPermissionsDialog(true)}
            text="Close"
          />
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default PermissionManagement;
