import * as msal from "@azure/msal-browser";
import { AccountInfo } from "@azure/msal-browser";
import CacheService from "./CacheService";
import moment from "moment";
import ConfigurationService from "./ConfigurationService";

export default class AuthenticationService {
  private static _default: AuthenticationService;

  public static get Default(): AuthenticationService {
    if (this._default == null) {
      this._default = new AuthenticationService();
    }
    return this._default;
  }

  public msalInstance: msal.PublicClientApplication;

  public get AuthenticationToken(): msal.AuthenticationResult | null {
    return CacheService.Default.GetFromCache<msal.AuthenticationResult | null>(
      "authentication",
      null
    );
  }

  public set AuthenticationToken(value: msal.AuthenticationResult | null) {
    CacheService.Default.SaveToCache("authentication", value);
  }

  public get APIAuthenticationToken(): msal.AuthenticationResult | null {
    return CacheService.Default.GetFromCache<msal.AuthenticationResult | null>(
      "authentication_api",
      null
    );
  }

  public set APIAuthenticationToken(value: msal.AuthenticationResult | null) {
    CacheService.Default.SaveToCache("authentication_api", value);
  }

  public set FlowAuthenticationToken(value: msal.AuthenticationResult | null) {
    CacheService.Default.SaveToCache("authentication_flow", value);
  }

  public get FlowAuthenticationToken(): msal.AuthenticationResult | null {
    return CacheService.Default.GetFromCache<msal.AuthenticationResult | null>(
      "authentication_flow",
      null
    );
  }

  public get Account(): AccountInfo | null {
    if (this.AuthenticationToken) {
      return this.AuthenticationToken?.account!;
    }
    return null;
  }

  public async AccessToken(): Promise<string | null> {
    if (
      this.AuthenticationToken &&
      moment(this.AuthenticationToken.expiresOn).isAfter(moment())
    ) {
      // Exists and is still valid
      return this.AuthenticationToken?.accessToken!;
    } else {
      try {
        // Needs to be renewed
        await this.msalInstance.initialize();
        var tokenResponse = await this.msalInstance.acquireTokenSilent({
          scopes: ConfigurationService.Default.Configuration.AzureAd?.Scopes!,
        });
        this.AuthenticationToken = tokenResponse;
        return this.AuthenticationToken?.accessToken!;
      } catch {
        window.location.reload();
        return null;
      }
    }
  }

  public async APIAccessToken(): Promise<string | null> {
    if (
      this.APIAuthenticationToken &&
      moment(this.APIAuthenticationToken.expiresOn).isAfter(moment())
    ) {
      // Exists and is still valid
      return this.APIAuthenticationToken?.accessToken!;
    } else {
      try {
        // Needs to be renewed
        await this.msalInstance.initialize();
        var apiTokenResponse = await this.msalInstance.acquireTokenSilent({
          scopes: ConfigurationService.Default.Configuration.API?.Scopes!,
          account: this.Account!,
        });
        this.APIAuthenticationToken = apiTokenResponse;
        return this.APIAuthenticationToken?.accessToken!;
      } catch {
        window.location.reload();
        return null;
      }
    }
  }

  public async FlowAccessToken(
    throwError: boolean = false
  ): Promise<string | null> {
    if (
      this.FlowAuthenticationToken &&
      moment(this.FlowAuthenticationToken.expiresOn).isAfter(moment())
    ) {
      // Exists and is still valid
      return this.FlowAuthenticationToken?.accessToken!;
    } else {
      try {
        // Needs to be renewed
        await this.msalInstance.initialize();
        var flowTokenResponse = await this.msalInstance.acquireTokenSilent({
          scopes:
            ConfigurationService.Default.Configuration.ServiceFlow?.Scopes!,
          account: this.Account!,
        });
        this.FlowAuthenticationToken = flowTokenResponse;
        return this.FlowAuthenticationToken?.accessToken!;
      } catch (error) {
        if (throwError) throw error;
        window.location.reload();
        return null;
      }
    }
  }

  public get IdToken(): string | null {
    if (this.AuthenticationToken) {
      return this.AuthenticationToken?.idToken!;
    }
    return null;
  }

  constructor() {
    this.msalInstance = new msal.PublicClientApplication({
      auth: {
        authority:
          ConfigurationService.Default.Configuration.AzureAd?.Authority,
        clientId: ConfigurationService.Default.Configuration.AzureAd?.ClientId!,
        redirectUri:
          ConfigurationService.Default.Configuration.AzureAd?.RedirectUri,
      },
    });
  }

  public get Roles(): Array<string> {
    var arrayToken = this.APIAuthenticationToken?.accessToken.split(".");
    const tokenPayload = JSON.parse(atob(arrayToken![1]));
    if (tokenPayload["roles"] === undefined) return ["default"];
    return tokenPayload["roles"] as Array<string>;
  }

  public async Initialize(): Promise<boolean> {
    await this.msalInstance.initialize();
    const tokenResponse = await this.msalInstance.handleRedirectPromise();
    if (this.Account === null && tokenResponse === null) {
      return false;
    } else if (tokenResponse !== null) {
      this.AuthenticationToken = tokenResponse;
      this.msalInstance.setActiveAccount(tokenResponse.account);
      await this.APIAccessToken();
      return true;
    } else if (this.Account !== null) {
      return moment(this.AuthenticationToken?.expiresOn).isAfter(moment());
    }

    return false;
  }

  public async LoginRedirect(): Promise<void> {
    await this.msalInstance.loginRedirect({
      scopes: ConfigurationService.Default.Configuration.AzureAd?.Scopes!,
    });
  }

  public async Logout(): Promise<void> {
    CacheService.Default.RemoveFromCache("authentication");
    CacheService.Default.RemoveFromCache("authentication_api");
    CacheService.Default.RemoveFromCache("authentication_flow");
    await this.msalInstance.logoutRedirect({
      account: this.Account,
    });
  }

  public HasRole(roleName: string): boolean {
    return this.Roles.indexOf(roleName) !== -1;
  }

  public HasAnyRole(roles: Array<string>): boolean {
    return this.Roles.filter((r) => roles.indexOf(r) !== -1).length > 0;
  }
}
