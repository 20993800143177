import { FC, createContext, useContext } from "react";
import { ApiHttpServiceContext } from "./ApiHttpService";
import { IConversation, MessageFeedback } from "../../Models/API/IConversation";
import ITraceMessage from "../../Models/API/ITraceMessage";

export interface IChatService {
  GetConversation(channelId: string): Promise<IConversation | null>;
  DeleteConversation(channelId: string): Promise<void | null>;
  GetTraceMessage(messageId: string): Promise<ITraceMessage | null>;
  GiveMessageFeedback(
    messageId: string,
    feedback: MessageFeedback
  ): Promise<void | null>;
}

export const ChatServiceContext = createContext<IChatService | undefined>(
  undefined
);

const ChatService: FC = ({ children }: any) => {
  const apiHttpService = useContext(ApiHttpServiceContext);

  const chatService: IChatService = {
    async GetConversation(channelId: string) {
      return await apiHttpService!.Get<IConversation>(
        `conversations/${channelId}`,
        {},
        false,
        true
      );
    },
    async DeleteConversation(channelId: string) {
      await apiHttpService!.Delete(`conversations/${channelId}`, {}, true);
    },
    async GetTraceMessage(messageId: string) {
      return await apiHttpService!.Get<ITraceMessage>(
        `messages/${messageId}/trace`,
        {},
        false
      );
    },
    async GiveMessageFeedback(
      messageId: string,
      feedback: MessageFeedback
    ) {
      await apiHttpService!.Post(`messages/${messageId}`, { feedback });
    },
  };

  return (
    <ChatServiceContext.Provider value={chatService}>
      {children}
    </ChatServiceContext.Provider>
  );
};

export default ChatService;
