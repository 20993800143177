import { FC, useContext, useEffect, useState } from "react";
import { SettingsServiceContext } from "../../Services/API/SettingsService";
import "./Chat.scss";
import ISettings from "../../Models/API/ISettings";
import {
  FontWeights,
  IButtonStyles,
  IIconProps,
  IconButton,
  Link,
  Modal,
  Spinner,
  SpinnerSize,
  Stack,
  StackItem,
  Text,
  mergeStyleSets,
} from "@fluentui/react";
import { chatAppServiceContext } from "../../Services/API/ChatAppService";
import IChatApp from "../../Models/API/IChatApp";
import ApplicationChat from "../../Components/ApplicationChat/ApplicationChat";
import GroupToggle, {
  IGroupToggleOption,
} from "../../Components/GroupToggle/GroupToggle";
import ReactMarkdown from "react-markdown";
import { AppCatalogServiceContext } from "../../Services/AppCatalogService";
import { trackPageView } from "../../Services/AppInsights";
import ConfigurationService from "../../Services/ConfigurationService";

const Chat: FC = () => {
  const appCatalogService = useContext(AppCatalogServiceContext);
  const settingsService = useContext(SettingsServiceContext);
  const chatAppService = useContext(chatAppServiceContext);
  const [settings, setSettings] = useState<ISettings | null>();
  const [chatJtiApp, setChatJtiApp] = useState<IChatApp | null>();
  const [chatJtiAgentApp, setChatJtiAgentApp] = useState<IChatApp | null>();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [currentKey, setCurrentKey] = useState<string>("chatJti");
  const [showDisclaimer, setShowDisclaimer] = useState<boolean>(false);

  // Track page view
  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.title = `${ConfigurationService.Default.Configuration.PageTitle}`;
    const execute = async () => {
      const settings = await settingsService!.Get();
      setSettings(settings);
    };
    execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const execute = async () => {
      if (settings) {
        if (settings.chat_jti_app_id && settings.chat_jti_app_id !== 0) {
          const app = await chatAppService!.Get(settings.chat_jti_app_id);
          setChatJtiApp(app);
        }
      }
    };
    execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  useEffect(() => {
    const execute = async () => {
      if (settings) {
        if (
          settings.chat_jti_agent_app_id &&
          settings.chat_jti_agent_app_id !== 0
        ) {
          try {
            const app = await chatAppService!.Get(
              settings.chat_jti_agent_app_id,
              true
            );
            setChatJtiAgentApp(app);
          } catch (error) {
            // Do nothing for now
          }
          setLoaded(true);
        }
      }
    };
    execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  const footerDisclaimer = () => {
    const regex = /.*(\{(.*)\}).*/gm;
    let m;
    let replaceKeyword;
    let originalText;
    let str = settings!.chat_jti_footer!;

    while ((m = regex.exec(str)) !== null) {
      if (m.index === regex.lastIndex) {
        regex.lastIndex++;
      }
      replaceKeyword = m[1];
      originalText = m[2];
    }

    if (replaceKeyword && originalText) {
      var leftPart = str.substring(0, str.indexOf(replaceKeyword));
      var rightPart = str.substring(
        str.indexOf(replaceKeyword) + replaceKeyword.length
      );
      return (
        <>
          <Text>{leftPart}</Text>
          <Link onClick={() => setShowDisclaimer(true)}>{originalText}</Link>
          <Text>{rightPart}</Text>
        </>
      );
    } else {
      return (
        <>
          <Text>{settings!.chat_jti_footer}</Text>
        </>
      );
    }
  };

  const options = (): IGroupToggleOption[] => {
    return [
      {
        key: "chatJti",
        iconName: "LightningBolt",
        title: settings?.chat_jti_app_title ?? "",
        description: settings?.chat_jti_app_description ?? "",
      },
      {
        key: "chatAgentJti",
        iconName: "FavoriteStar",
        title: settings?.chat_jti_agent_app_title ?? "",
        description: settings?.chat_jti_agent_app_description ?? "",
        locked:
          !settings!.chat_jti_agent_app_id ||
          settings!.chat_jti_agent_app_id === 0 ||
          (loaded && chatJtiAgentApp === undefined),
      },
    ];
  };

  const theme = appCatalogService!.GetCurrentTheme();
  const contentStyles = mergeStyleSets({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      width: "60%",
    },
    header: [
      theme.fonts.xLargePlus,
      {
        flex: "1 1 auto",
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.themePrimary,
        display: "flex",
        alignItems: "center",
        fontWeight: FontWeights.semibold,
        padding: "12px 12px 14px 24px",
      },
    ],
    heading: {
      color: theme.palette.themePrimary,
      fontWeight: FontWeights.semibold,
      fontSize: "inherit",
      margin: "0",
    },
    body: {
      flex: "4 4 auto",
      padding: "0 24px 24px 24px",
      overflowY: "hidden",
      selectors: {
        p: { margin: "14px 0" },
        "p:first-child": { marginTop: 0 },
        "p:last-child": { marginBottom: 0 },
      },
    },
  });

  const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
      color: theme.palette.neutralPrimary,
      marginLeft: "auto",
      marginTop: "4px",
      marginRight: "2px",
    },
    rootHovered: {
      color: theme.palette.neutralDark,
    },
  };

  return (
    <>
      <Stack
        verticalFill
        grow
        style={{ minHeight: 0 }}
        tokens={{ childrenGap: 10 }}
      >
        {settings && (
          <StackItem align="center">
            <GroupToggle
              onChange={(key) => setCurrentKey(key)}
              activeKey={currentKey}
              options={options()}
            ></GroupToggle>
          </StackItem>
        )}
        {currentKey === "chatJti" && (
          <StackItem grow verticalFill style={{ minHeight: 0 }}>
            {(chatJtiApp && (
              <ApplicationChat
                maxTokens={settings?.max_prompt_tokens!}
                chatApp={chatJtiApp}
              ></ApplicationChat>
            )) || <Spinner size={SpinnerSize.large} />}
          </StackItem>
        )}
        {currentKey === "chatAgentJti" && (
          <StackItem grow verticalFill style={{ minHeight: 0 }}>
            {(chatJtiAgentApp && (
              <ApplicationChat
                maxTokens={settings?.max_prompt_tokens!}
                chatApp={chatJtiAgentApp}
              ></ApplicationChat>
            )) || <Spinner size={SpinnerSize.large} />}
          </StackItem>
        )}
        {settings && <StackItem align="center">{footerDisclaimer()}</StackItem>}
      </Stack>
      {settings && (
        <Modal
          isOpen={showDisclaimer}
          onDismiss={() => setShowDisclaimer(false)}
          isBlocking={true}
          containerClassName={contentStyles.container}
        >
          <div className={contentStyles.header}>
            <h2 className={contentStyles.heading}>Chat JTI Disclaimer</h2>
            <IconButton
              styles={iconButtonStyles}
              iconProps={cancelIcon}
              ariaLabel="Close popup modal"
              onClick={() => setShowDisclaimer(false)}
            />
          </div>
          <div className={contentStyles.body}>
            <ReactMarkdown linkTarget={"_blank"}>
              {settings!.chat_jti_disclaimer!}
            </ReactMarkdown>
          </div>
        </Modal>
      )}
    </>
  );
};

const cancelIcon: IIconProps = { iconName: "Cancel" };

export default Chat;
