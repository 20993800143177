import {
  Label,
  TextField,
  Text,
  CommandBarButton,
  IIconProps,
  Checkbox,
  Stack,
  PrimaryButton,
  IStackProps,
  ActionButton,
} from '@fluentui/react';
import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { IAutomateAppParameter } from '../../../../Models/API/IAutomateApp';

const columnProps: Partial<IStackProps> = {
  tokens: { childrenGap: 5 },
};

const addIcon: IIconProps = { iconName: 'CircleAddition' };
const deleteIcon: IIconProps = { iconName: 'Delete' };
const deleteText = 'Delete';

export interface IArrayTypeProps {
  param: IAutomateAppParameter;
  setData: (param: IAutomateAppParameter, value: any[]) => void;
}

interface IValue {
  internalId: number;
  value?: any;
}

const ArrayType: FC<IArrayTypeProps> = ({ param, setData }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [values, setValues] = useState<IValue[]>([]);

  useEffect(() => {
    setValues([
      ...values,
      {
        internalId: 1,
        value: param.ArrayType && param.ArrayType === 'Boolean' ? false : undefined,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setData(
      param,
      values
        .filter(x => x.value !== undefined)
        .sort(x => x.internalId)
        .map(x => x.value)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const onFileChange = async (event: ChangeEvent<HTMLInputElement>, id: number) => {
    const files = event.target.files;
    if (files) {
      const file = files[0];
      if (file) {
        setInnerData(id, file);
      }
    }
  };

  const addProperty = () => {
    setValues([
      ...values,
      {
        internalId: values.length + 1,
        value: param.ArrayType && param.ArrayType === 'Boolean' ? false : undefined,
      },
    ]);
  };

  const deleteProperty = (id: number) => {
    const filtered = values.filter(x => x.internalId !== id);
    setValues(filtered);
  };

  const setInnerData = (id: number, value: any) => {
    const filtered = values.filter(x => x.internalId !== id);
    setValues([
      ...filtered,
      {
        internalId: id,
        value: value,
      },
    ]);
  };

  const getFormField = (x: IValue) => {
    switch (param.ArrayType) {
      case 'Boolean':
        return (
          <Stack.Item>
            <Stack horizontal verticalAlign='center'>
              <Checkbox
                label={`Item ${x.internalId}`}
                id={x.internalId.toString()}
                onChange={(evnt, checked) => setInnerData(x.internalId, checked!)}
              />
              <ActionButton
                iconProps={deleteIcon}
                allowDisabledFocus
                title={deleteText}
                ariaLabel='Delete parameter'
                onClick={() => deleteProperty(x.internalId)}
              >
                {deleteText}
              </ActionButton>
            </Stack>
          </Stack.Item>
        );
      case 'String':
        return (
          <Stack.Item>
            <Stack horizontal verticalAlign='center'>
              <Text>{`Item ${x.internalId}`}</Text>
              <ActionButton
                iconProps={deleteIcon}
                allowDisabledFocus
                title={deleteText}
                ariaLabel='Delete parameter'
                onClick={() => deleteProperty(x.internalId)}
              >
                {deleteText}
              </ActionButton>
            </Stack>
            <TextField
              multiline
              autoAdjustHeight
              rows={2}
              resizable
              onChange={(evt, value) => setInnerData(x.internalId, value!)}
            ></TextField>
          </Stack.Item>
        );
      case 'Number':
        return (
          <Stack.Item>
            <Stack tokens={{ childrenGap: 10, maxWidth: 500 }}>
              <Text>{`Item ${x.internalId}`}</Text>
              <Stack horizontal verticalAlign='center'>
                <TextField
                  type='number'
                  onChange={(evt, value) => setInnerData(x.internalId, Number(value!))}
                ></TextField>
                <ActionButton
                  iconProps={deleteIcon}
                  allowDisabledFocus
                  title={deleteText}
                  ariaLabel='Delete parameter'
                  onClick={() => deleteProperty(x.internalId)}
                >
                  {deleteText}
                </ActionButton>
              </Stack>
            </Stack>
          </Stack.Item>
        );
      case 'File':
        return (
          <>
            {x.value ? (
              <Stack horizontal verticalAlign='center'>
                <ActionButton
                  iconProps={deleteIcon}
                  allowDisabledFocus
                  title='Delete property'
                  ariaLabel='Delete property'
                  onClick={() => deleteProperty(x.internalId)}
                >
                  {deleteText}:
                </ActionButton>
                <Text>{x.value.name}</Text>
              </Stack>
            ) : (
              <>
                <PrimaryButton
                  style={{ minWidth: 80 }}
                  onClick={() => inputRef.current?.click()}
                  text={'Upload file'}
                ></PrimaryButton>
                <input
                  ref={inputRef}
                  type='file'
                  hidden={true}
                  onChange={ev => onFileChange(ev, x.internalId)}
                />
              </>
            )}
          </>
        );
    }
    return <></>;
  };

  return (
    <Stack {...columnProps}>
      <Stack.Item>
        <Label required={param.Required}>{param.DisplayName}</Label>
      </Stack.Item>
      <Stack.Item>
        <Text variant={'small'} block>
          {param.Description}
        </Text>
      </Stack.Item>
      <Stack.Item>
        <Stack tokens={{ childrenGap: 10 }}>
          {values ? (
            values
              .sort((a, b) => a.internalId - b.internalId)
              .map((x, index) => <Stack.Item key={index}>{getFormField(x)}</Stack.Item>)
          ) : (
            <></>
          )}
          <Stack.Item>
            <CommandBarButton
              style={{ marginTop: 10 }}
              iconProps={addIcon}
              text='Add Value'
              onClick={addProperty}
            />
          </Stack.Item>
        </Stack>
      </Stack.Item>
    </Stack>
  );
};

export default ArrayType;
