import {
  Breadcrumb,
  DefaultButton,
  IBreadcrumbItem,
  Label,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
  StackItem,
  Text,
  TextField,
} from '@fluentui/react';
import { FC, useState, useContext, useRef, ChangeEvent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { INewCatalog, catalogServiceContext } from '../../../Services/API/CatalogService';
import { DialogServiceContext } from '../../../Services/Dialogs/DialogService';
import { AxiosError } from 'axios';
import styles from './NewCatalog.module.scss';
import AppColorPicker from '../../../Components/ColorPicker/AppColorPicker';
import { trackPageView } from '../../../Services/AppInsights';

const VALID_UPLOAD_FILE_EXTENSIONS = ['jpg', 'jpeg', 'png', 'gif'];

const defaultNewCatalog: INewCatalog = {
  DisplayName: '',
  Description: '',
  BackgroundColor: '#122632',
  PrimaryColor: '#4ab8a5',
  SecondaryColor: '#ffffff',
};

const NewCatalog: FC = () => {
  const catalogsService = useContext(catalogServiceContext);
  const dialogService = useContext(DialogServiceContext);
  const navigate = useNavigate();
  const [newCatalog, setNewCatalog] = useState<INewCatalog>(defaultNewCatalog);

  const [creatingCatalog, setCreatingCatalog] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const [files, setFiles] = useState<FileList | null>();
  const [imageData, setImageData] = useState<string | null>(null);

  const breadcrumbItems: IBreadcrumbItem[] = [
    {
      text: 'Catalogs',
      key: 'catalogs',
      onClick: () => {
        navigate('/catalogseditor');
      },
    },
    {
      text: 'New Catalog',
      key: 'new-cat',
      isCurrentItem: true,
      disabled: true,
    },
  ];

  // Track page view
  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createCatalog = async (): Promise<void> => {
    if (!creatingCatalog) {
      setCreatingCatalog(true);
      const newCat = await catalogsService?.Create(newCatalog);
      if (newCat != null) {
        if (files) {
          await uploadLogo(newCat.Id);
        }

        navigate(`../${newCat!.Id.toString()}`);
      } else {
        setCreatingCatalog(false);
      }
    }
  };

  const onFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      var file = files[0];

      if (file) {
        const extension = file.name.split('.')[file.name.split('.').length - 1];
        if (VALID_UPLOAD_FILE_EXTENSIONS.indexOf(extension) === -1) {
          dialogService?.showWarningDialog('Logo Upload', 'File extension not supported.');
        } else {
          setFiles(files);
          setImageData(URL.createObjectURL(file));
        }
      }
    }
  };

  const uploadLogo = async (catalogId: number) => {
    if (files) {
      var file = files[0];
      try {
        await catalogsService!.UpdateLogo(catalogId, file);
      } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response?.status === 409 || axiosError.response?.status === 422) {
          dialogService?.showWarningDialog(
            'Logo Upload',
            (axiosError.response.data as any).details
          );
        } else if (axiosError.response?.status === 400) {
          const errorMsg = (axiosError.response.data as any).errors[''][0];
          if (errorMsg.toLowerCase().indexOf('request body too large') !== -1) {
            dialogService?.showWarningDialog(
              'Logo Upload',
              'The provided file exceeds the allowable size limit. Please ensure that the file size is below 100 megabytes (MB) and try again.'
            );
          } else {
            dialogService?.showWarningDialog('Document Upload', errorMsg);
          }
        }
      }
    }
  };

  return (
    <>
      <Breadcrumb
        items={breadcrumbItems}
        maxDisplayedItems={5}
        ariaLabel='Breadcrumb'
        overflowAriaLabel='More links'
      />
      <section className={styles.gridContainer}>
        <Stack tokens={{ childrenGap: 20 }}>
          <Stack.Item>
            <TextField
              required={true}
              label='Display Name'
              value={newCatalog.DisplayName}
              onChange={(event, newValue) =>
                setNewCatalog({
                  ...newCatalog,
                  DisplayName: newValue,
                })
              }
            ></TextField>
            <Text variant='xSmall'>Minimum 3 characters</Text>
          </Stack.Item>
          <Stack.Item>
            <TextField
              required={true}
              label='Description'
              multiline
              rows={3}
              resizable={false}
              value={newCatalog.Description}
              onChange={(event, newValue) =>
                setNewCatalog({
                  ...newCatalog,
                  Description: newValue,
                })
              }
            />
            <Text variant='xSmall'>Minimum 20 characters and maximum 1000 characters</Text>
          </Stack.Item>
          <Stack.Item>
            <Stack tokens={{ childrenGap: 20 }}>
              <StackItem>
                <Label>Background color</Label>
                <Stack horizontal verticalAlign='end' tokens={{ childrenGap: 10 }}>
                  <TextField
                    style={{ width: 80 }}
                    value={newCatalog.BackgroundColor}
                    onChange={(event, newValue) =>
                      setNewCatalog({
                        ...newCatalog,
                        BackgroundColor: newValue!,
                      })
                    }
                  />
                  <AppColorPicker
                    color={newCatalog.BackgroundColor!}
                    showPreview
                    setColor={newValue =>
                      setNewCatalog({
                        ...newCatalog,
                        BackgroundColor: newValue!,
                      })
                    }
                  />
                </Stack>
              </StackItem>
              <StackItem>
                <Label>Primary color</Label>
                <Stack horizontal verticalAlign='end' tokens={{ childrenGap: 10 }}>
                  <TextField
                    style={{ width: 80 }}
                    value={newCatalog.PrimaryColor}
                    onChange={(event, newValue) =>
                      setNewCatalog({
                        ...newCatalog,
                        PrimaryColor: newValue!,
                      })
                    }
                  />
                  <AppColorPicker
                    color={newCatalog.PrimaryColor!}
                    showPreview
                    setColor={newValue =>
                      setNewCatalog({
                        ...newCatalog,
                        PrimaryColor: newValue!,
                      })
                    }
                  />
                </Stack>
              </StackItem>
              <StackItem>
                <Label>Text color</Label>
                <Stack horizontal verticalAlign='end' tokens={{ childrenGap: 10 }}>
                  <TextField
                    style={{ width: 80 }}
                    value={newCatalog.SecondaryColor}
                    onChange={(event, newValue) =>
                      setNewCatalog({
                        ...newCatalog,
                        SecondaryColor: newValue!,
                      })
                    }
                  />
                  <AppColorPicker
                    color={newCatalog.SecondaryColor!}
                    showPreview
                    setColor={newValue =>
                      setNewCatalog({
                        ...newCatalog,
                        SecondaryColor: newValue!,
                      })
                    }
                  />
                </Stack>
              </StackItem>
            </Stack>
          </Stack.Item>
        </Stack>

        <Stack className={styles.logoSection}>
          <section>{imageData ? <img src={imageData} alt='some logo' /> : <></>}</section>
          <Stack.Item>
            <PrimaryButton
              style={{ minWidth: 80 }}
              onClick={() => inputRef.current?.click()}
              text={'Upload logo'}
            />
            <input
              ref={inputRef}
              type='file'
              accept={VALID_UPLOAD_FILE_EXTENSIONS.map(ext => `.${ext}`).join(',')}
              hidden={true}
              onChange={onFileChange}
            />
          </Stack.Item>
        </Stack>

        <Stack horizontal tokens={{ childrenGap: 10 }}>
          <DefaultButton onClick={() => navigate('/catalogseditor')} text='Cancel' />
          <PrimaryButton
            style={{ minWidth: 80 }}
            onClick={createCatalog}
            disabled={
              !newCatalog.DisplayName ||
              newCatalog.DisplayName.length < 3 ||
              !newCatalog.Description ||
              newCatalog.Description.length < 20 ||
              newCatalog.Description.length > 1000
            }
            text={creatingCatalog ? '' : 'Create'}
          >
            {creatingCatalog && <Spinner size={SpinnerSize.small} />}
          </PrimaryButton>
        </Stack>
      </section>
    </>
  );
};

export default NewCatalog;
