import { FC, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import IAutomateApp from "../../../../../../Models/API/IAutomateApp";
import AutomateRun from "../../../../../../Components/AutomateRun/AutomateRun";
import { trackPageView } from "../../../../../../Services/AppInsights";
import ConfigurationService from "../../../../../../Services/ConfigurationService";

export interface IAutomateTestProps {}

const AutomateTest: FC<IAutomateTestProps> = () => {
  const { automateApp } = useOutletContext<{
    automateApp: IAutomateApp;
  }>();

  // Track page view
  useEffect(() => {
    document.title = `${ConfigurationService.Default.Configuration.PageTitle} - ${automateApp.DisplayName}`;
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <AutomateRun automateApp={automateApp} />;
};

export default AutomateTest;
