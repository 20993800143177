import ReactDOM from "react-dom";
import { mergeStyles } from "@fluentui/react";
import reportWebVitals from "./reportWebVitals";
import "./index.scss";
import { Authenticator } from "./Components/Authenticator/Authenticator";

// Inject some global styles
mergeStyles({
  ":global(body,html,#root)": {
    margin: 0,
    padding: 0,
    height: "100vh",
  },
});

ReactDOM.render(<Authenticator />, document.getElementById("root"));

reportWebVitals();